export const filterOptionListTemplate = `
<li class='boost-sd__filter-option-item boost-sd__filter-option-list-option-item'>
  <button
    id='{{ actionId }}'
    data-action='{{ dataAction }}'
    role='checkbox'
    aria-checked='false'
    aria-label='Find product with {{ label | escape }} is {{ value.label | escape | strip_html }}'
    class='{{className}}'
  >
    <span
      data-action='{{ dataAction }}'
      class='boost-sd__filter-option-item-label'
    >
      {% if displayAllValuesInUppercaseForm %}
        {{ value.label | escape | upcase }}
      {% else %}
        {{ value.label | escape }}
      {% endif %}
    </span>
    {% if showDocCount %}
      <span
        data-action='{{ dataAction }}'
        aria-label='Number of products: ({{ value.doc_count }})'
        class='boost-sd__filter-option-item-amount'
      >
        ({{ value.doc_count }})</span
      >
    {% endif %}
  </button>
</li>`;
