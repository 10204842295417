import { getProductDetail } from '../api/quick-view.js';
import { buildProductDetailUrlWithVariant, formatCurrency } from '../utils/format.js';
import { loadResource, safeParseJSON, setWindowLocation } from '../utils';
import { openCart } from '../utils/event.js';
import { addToCart } from '../cart/index.js';
import { handleTooltip } from '../tooltip/index.js';
import { trapFocus, focusFirstElement } from '../utils/wcag-modal';
import { KEYS } from '../constants/keys';
import { isKeyboardActivated } from '../utils/keyboardActivated.js';

let sliderRef = null;
let initialized = false;
let loading = false;

// Create a global variable to track the last focused button (support WCAG : when close quick view)
window.buttonQuickviewFocus = null;

export const quickView = async (context, action, target) => {
  if (loading) return;

  loading = true;
  await initQuickViewSlider();

  const { productId, fromSelectOptionBtn = false } = action;
  const params = {
    shop: context?.defaultParams?.shop || context?.app?.shop?.domain,
    product_id: productId,
    quickview_widget_id: context.app.templateMetadata.quickView,
    locale: context?.app?.generalSettings?.current_locale || 'en',
  };

  let html = await getProductDetail(context, params);

  if (fromSelectOptionBtn) {
    // removed quick view image === selection options - popup
    html = html.replace(
      'class="boost-sd__quick-view-product-image"',
      'class="boost-sd__quick-view-product-image" style="display:none"'
    );
  }

  document.body.insertAdjacentHTML('beforeend', html);
  document.body.classList.add('boost-sd__modal-open-quickview');

  // Track the button that opened the modal
  window.buttonQuickviewFocus = target;

  // dispatch event for analytic
  window.dispatchEvent(
    new CustomEvent('boost-sd-open-quick-view', {
      detail: {
        pid: productId,
      },
    })
  );

  handleQuickViewSlider();
  handleQuickViewModal(context);
  handleTooltip(context);

  //Generate newId for customize
  const newId = window.boostWidgetIntegration.generateUuid();
  context.state.latestQuickViewRequest = newId;
};

export const initQuickViewSlider = async () => {
  if (initialized) return;

  const jquery = await import('jquery');
  window.$ = jquery.default;

  await import('slick-carousel');

  initialized = true;
};

const handleQuickViewSlider = () => {
  const isRtl = document.documentElement.getAttribute('dir') === 'rtl';

  const slider = $('.boost-sd__quick-view .slick-slider').slick({
    prevArrow: false,
    nextArrow: false,
    useTransform: false,
    rtl: isRtl, // Add this property to enable RTL mode
  });

  if (isRtl) {
    $('.boost-sd__quick-view .slick-slider').css({
      direction: 'rtl',
    });

    $('.boost-sd__quick-view .slick-slider .slick-slide').css({
      float: 'right',
    });
  }

  sliderRef = slider;

  const currentSlide = sliderRef.slick('slickCurrentSlide');

  selectThumbnail(typeof currentSlide === 'number' ? currentSlide : 0);

  sliderRef.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    selectThumbnail(nextSlide);
  });

  document
    .querySelectorAll('.boost-sd__thumbs-container-thumbs-item')
    .forEach((thumbnail, index) => {
      thumbnail.addEventListener('click', () => {
        slider.slick('slickGoTo', index);
      });

      // Support WCAG : selected thumbnail when use keyboard
      thumbnail.addEventListener('keydown', (e) => {
        if (KEYS.includes(e.key)) {
          slider.slick('slickGoTo', index);
        }
      });
    });

  loading = false;
};

const handleQuickViewModal = (context) => {
  const modal = document.getElementById('boost-sd__modal-quickview');
  const closeButton = modal.querySelector('.boost-sd__modal-close-btn');
  const inputNumber = modal.querySelector('.boost-sd__input-number-input');
  const decreaseButton = modal.querySelector('.boost-sd__input-number-btn-wrapper:first-child');
  const increaseButton = modal.querySelector('.boost-sd__input-number-btn-wrapper:last-child');
  const errorQuantity = modal.querySelector('.boost-sd__quick-view-quantity-error');
  const atcButton = modal.querySelector('.boost-sd__btn-add-to-cart');
  const atcButtonText = modal.querySelector(
    '.boost-sd__btn-add-to-cart .boost-sd__button-text, .boost-sd__btn-add-to-cart span'
  );
  const buyItNowButton = modal.querySelector('.boost-sd__btn-buy-now');
  const detailButton = modal.querySelector('.boost-sd__quick-view-details-link');
  const quickViewPrice = modal.querySelector('.boost-sd__quick-view-price');
  const quickViewPriceSpan = modal.querySelector('.boost-sd__quick-view-price span');
  const productSwatches = modal.querySelectorAll('.boost-sd__product-swatch');
  const saleLabels = modal.querySelectorAll('.boost-sd__product-label--sale');
  const soldOutLabels = modal.querySelectorAll('.boost-sd__product-label--soldout');
  const customLabels = modal.querySelectorAll('.boost-sd__product-label--customLabelByTag');
  const thumbItems = modal.querySelectorAll(
    '.boost-sd__thumbs-container-thumbs .boost-sd__thumbs-container-thumbs-item'
  );
  const thumbNextBtn = modal.querySelector('.boost-sd__thumbs-container-next-button button');
  const thumbPrevBtn = modal.querySelector('.boost-sd__thumbs-container-prev-button button');

  const {
    handle,
    splitProduct,
    variantId,
    variants,
    tags,
    images,
    selectedVariantImageByFilterOption,
  } = safeParseJSON(modal.getAttribute('data-product')) || {};

  const [getImageIndex, setImageIndex] = context.useContextState('quick-view-image', 0);

  const selectedVariant = variants.find((variant) => variant.available) || variants[0];
  const [getVariant, setVariant] = context.useContextState('quick-view-swatch', {
    selectedVariant,
  });

  setVariant({ selectedVariant });

  // Replace the first image with the selected variant image by filter option
  if (selectedVariantImageByFilterOption && images?.length > 0) {
    images[0].src = selectedVariantImageByFilterOption;
  }

  const closeModal = () => {
    modal.style.display = 'none';
    modal.remove();
    document.body.classList.remove('boost-sd__modal-open-quickview');

    // Return focus to the button that opened the modal
    if (isKeyboardActivated() && window.buttonQuickviewFocus) {
      window.buttonQuickviewFocus.focus();
    }
  };

  closeButton?.addEventListener('click', closeModal);
  /**
   * Quick View - Support WCAG
   */
  focusFirstElement(modal);

  const handleModalKeydown = (e) => {
    trapFocus(e, modal);
  };

  modal.addEventListener('keydown', handleModalKeydown);
  /**
   * Quick View - Input Number Quantity
   */
  decreaseButton?.addEventListener('click', (e) => {
    e.preventDefault();
    inputNumber.value = Math.max(1, Number(inputNumber.value) - 1);

    errorQuantity.style.display = 'none';
  });

  increaseButton?.addEventListener('click', (e) => {
    e.preventDefault();
    inputNumber.value = Math.max(1, Number(inputNumber.value) + 1);

    errorQuantity.style.display = 'none';
  });

  function onlyNumbers(evt) {
    // Prevent non-numeric input
    const charCode = evt?.which ? evt.which : evt?.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }

  inputNumber.addEventListener('keypress', (e) => {
    onlyNumbers(e);
    errorQuantity.style.display = 'none';
  });

  modal?.addEventListener('click', (e) => {
    if (!e.target.closest('.boost-sd__modal-wrapper')) {
      closeModal();
    }
  });

  /**
   * Quick View - Swatches
   */
  const initialSelectedOption = (option) => {
    const label = option.querySelector('.boost-sd__radio-label');
    const selectedVariantTitle = getVariant().selectedVariant.title;
    const optionTitle = label.textContent.trim();
    const selectedVariantOptions = selectedVariantTitle.split(' / ');

    if (selectedVariantOptions.includes(optionTitle)) {
      label.classList.add('boost-sd__radio-label--selected');
    }
  };

  const initialSoldOutOption = (option) => {
    const allVariantsSoldOut = variants.every((variant) => !variant.available);

    if (allVariantsSoldOut) {
      modal.querySelectorAll('.boost-sd__radio-label').forEach((label) => {
        label.classList.add('boost-sd__radio-label--sold-out');
      });

      return;
    }

    const label = option.querySelector('.boost-sd__radio-label');
    const optionTitle = label.textContent.trim();
    const selectedVariantTitle = getVariant().selectedVariant.title;
    const selectedVariantOptions = selectedVariantTitle.split(' / ');

    if (!selectedVariantOptions.includes(optionTitle)) {
      const matchingVariant = variants.find((variant) => {
        const variantOptions = variant.title.split(' / ');
        return variantOptions.includes(optionTitle);
      });

      if (matchingVariant && !matchingVariant.available) {
        label.classList.add('boost-sd__radio-label--sold-out');
      }
    }
  };

  const handleSelect = (option) => {
    const swatchOptions = option.parentNode.parentNode.querySelectorAll(
      '.boost-sd__product-swatch-option'
    );
    swatchOptions?.forEach((option) => {
      const label = option.querySelector('.boost-sd__radio-label');

      label?.classList.remove('boost-sd__radio-label--selected');
    });

    const label = option.querySelector('.boost-sd__radio-label');
    label?.classList.add('boost-sd__radio-label--selected');

    const selectedSwatchOptions = modal.querySelectorAll('.boost-sd__radio-label--selected');

    let title = '';
    selectedSwatchOptions?.forEach((option, i) => {
      title += option.textContent.trim();

      if (i < selectedSwatchOptions.length - 1) {
        title += ' / ';
      }
    });

    const selectedVariant = variants?.find((variant) => {
      return variant.title === title;
    }) || {
      available: false,
      id: '',
      price: null,
    };

    setVariant({
      selectedVariant,
    });
  };

  const updateSoldOutOption = (option) => {
    const allVariantsSoldOut = variants.every((variant) => !variant.available);

    if (allVariantsSoldOut) {
      return;
    }

    const selectedVariant = getVariant().selectedVariant;

    if (selectedVariant.available) {
      modal.querySelectorAll('.boost-sd__radio-label--selected').forEach((label) => {
        label.classList.remove('boost-sd__radio-label--sold-out');
      });
    } else {
      const selectedLabels = modal.querySelectorAll('.boost-sd__radio-label--selected');
      const noneSoldOut = Array.from(selectedLabels).every(
        (label) => !label.classList.contains('boost-sd__radio-label--sold-out')
      );

      if (noneSoldOut) {
        const label = option.querySelector('.boost-sd__radio-label');
        label.classList.add('boost-sd__radio-label--sold-out');
      }
    }

    const selectedOptionTitles = Array.from(
      modal.querySelectorAll('.boost-sd__radio-label--selected')
    ).map((label) => label.textContent.trim());

    const matchingVariants = variants.filter((variant) => {
      const variantOptions = variant.title.split(' / ');
      return selectedOptionTitles.some((optionTitle) => variantOptions.includes(optionTitle));
    });

    modal.querySelectorAll('.boost-sd__radio-label').forEach((label) => {
      const labelText = label.textContent.trim();

      if (!label.classList.contains('boost-sd__radio-label--selected')) {
        const hasAvailableVariant = variants.some((variant) => {
          const variantOptions = variant.title.split(' / ');
          return variantOptions.includes(labelText) && variant.available;
        });

        if (hasAvailableVariant) {
          label.classList.remove('boost-sd__radio-label--sold-out');
        } else {
          const matchingSoldOutVariant = matchingVariants.some((variant) => {
            const variantOptions = variant.title.split(' / ');
            return variantOptions.includes(labelText) && !variant.available;
          });

          if (matchingSoldOutVariant) {
            label.classList.add('boost-sd__radio-label--sold-out');
          }
        }
      }
    });
  };

  const handleOptionKeyup = (option) => {
    return (e) => {
      if (KEYS.includes(e.key)) {
        handleSelect(option);
        updateSoldOutOption(option);
      }
    };
  };

  productSwatches?.forEach((swatch) => {
    const swatchOptions = swatch.querySelectorAll('.boost-sd__product-swatch-option');
    swatchOptions?.forEach((option) => {
      initialSelectedOption(option);
      initialSoldOutOption(option);

      option.addEventListener('click', (e) => {
        handleSelect(option);
        updateSoldOutOption(option);
      });

      option.addEventListener('keyup', handleOptionKeyup(option));
    });
  });

  /**
   * Quick View - Add to Cart/Buy it now
   */
  atcButton?.addEventListener('click', async () => {
    if (Number(inputNumber.value) <= 0) {
      errorQuantity.style.display = 'block';
      return;
    }

    // Add to cart logic here
    context.handler(addToCart, 'addToCart')(
      context,
      {
        productId: getVariant().selectedVariant.id,
        quantity: Number(inputNumber.value),
      },
      atcButton,
      false,
      () => {
        // Shift focus to the first element in the cart modal
        setTimeout(() => {
          closeModal();
          const cartModal = document.querySelector('#boost-sd__modal-card');
          if (cartModal) {
            focusFirstElement(cartModal);
          }
        }, 300);
      }
    );
  });

  buyItNowButton?.addEventListener('click', () => {
    if (Number(inputNumber.value) === 0) return;

    const { value } = inputNumber;
    setWindowLocation(`/cart/${getVariant().selectedVariant.id}:${value}`);
  });

  /**
   * Quick View - Prev/Next
   */
  thumbPrevBtn?.addEventListener('click', () => {
    const index = getImageIndex();
    const currentImgIndex = index - 1;

    handleChangeSliderImagesPortion(
      context,
      currentImgIndex,
      images[currentImgIndex]?.src,
      images,
      thumbItems,
      modal,
      setImageIndex
    );
  });

  thumbNextBtn?.addEventListener('click', () => {
    const index = getImageIndex();
    const currentImgIndex = index + 1;

    handleChangeSliderImagesPortion(
      context,
      currentImgIndex,
      images[currentImgIndex]?.src,
      images,
      thumbItems,
      modal,
      setImageIndex
    );
  });

  thumbItems?.forEach((thumbnail, index) => {
    thumbnail.addEventListener('click', () => {
      handleChangeSliderImagesPortion(
        context,
        index,
        images?.[index]?.src,
        images,
        thumbItems,
        modal,
        setImageIndex
      );
    });
  });

  handleChangeSliderImagesPortion(
    context,
    0,
    images[0]?.src,
    images,
    thumbItems,
    modal,
    setImageIndex
  );

  context.render(() => {
    const { selectedVariant } = getVariant();

    /**
     *  Quick View - Price
     */
    const price = formatCurrency({
      context,
      value: selectedVariant?.price,
    });
    const isSale =
      (Number(selectedVariant?.compare_at_price) || 0) > (Number(selectedVariant?.price) || 0);
    const salePercent =
      isSale && selectedVariant?.compare_at_price
        ? Math.round(
            (((Number(selectedVariant.compare_at_price) || 0) -
              (Number(selectedVariant?.price) || 0)) *
              100) /
              Number(selectedVariant.compare_at_price)
          ) + '%'
        : undefined;

    const { addCollectionToProductUrl, current_tags = [] } = context?.app?.generalSettings || {};

    const saleAmount = isSale
      ? (Number(selectedVariant?.compare_at_price) || 0) - (Number(selectedVariant?.price) || 0)
      : undefined;

    const detailUrl = buildProductDetailUrlWithVariant(
      context,
      {
        variants,
        handle,
        split_product: splitProduct,
        variantId,
      },
      addCollectionToProductUrl,
      current_tags,
      selectedVariant.id
    );

    if (price) {
      if (quickViewPrice) {
        quickViewPrice.style.display = 'block';
      }
      if (quickViewPriceSpan) {
        quickViewPriceSpan.innerHTML = price;
      }
    }

    if (!selectedVariant?.price) {
      if (quickViewPrice) {
        quickViewPrice.style.display = 'none';
      }
    }

    const compareAtPriceSpan = document.createElement('span');
    compareAtPriceSpan.className =
      'boost-sd__format-currency boost-sd__format-currency--price-compare';

    if (quickViewPrice.childElementCount === 2) {
      quickViewPrice.lastChild.remove();
    }

    if (isSale) {
      const compareAtPrice = formatCurrency({ context, value: selectedVariant?.compare_at_price });

      compareAtPriceSpan.innerHTML = compareAtPrice;
      quickViewPrice.appendChild(compareAtPriceSpan);
    }

    /**
     * Quick View - Add to Cart/Buy it now
     */
    if (!atcButton || !selectedVariant) return;

    const isSoldOutVariantSelected = !selectedVariant.available;

    if (isSoldOutVariantSelected) {
      if (atcButton) {
        atcButton.setAttribute('disabled', true);
        if (atcButtonText) {
          atcButtonText.textContent =
            context.app?.translation?.productItem?.soldoutLabel || 'Sold out';
        }
      }

      if (buyItNowButton) {
        buyItNowButton.style.display = 'none';
      }
    } else {
      if (atcButton) {
        atcButton.removeAttribute('disabled');
        if (atcButtonText) {
          atcButtonText.textContent =
            context.app?.translation?.productItem?.atcAvailableLabel || 'Add to cart';
        }
      }

      if (buyItNowButton) {
        buyItNowButton.style.display = 'flex';
      }
    }

    // For analytics
    atcButton?.setAttribute('data-variant-id', selectedVariant.id);
    buyItNowButton?.setAttribute('data-variant-id', selectedVariant.id);

    detailButton.setAttribute('href', detailUrl);

    /**
     * Quick View - Slider
     * Update slider image based on selected variant
     */
    if (selectedVariant?.image) {
      const src = selectedVariant.image;
      const index = images?.findIndex((image) => image.src === src);

      handleChangeSliderImagesPortion(
        context,
        index,
        src,
        images,
        thumbItems,
        modal,
        setImageIndex
      );
    }

    /**
     * Quick View - Labels
     * Update sale and sold out labels based on selected variant
     */
    if (isSoldOutVariantSelected) {
      soldOutLabels.forEach((soldOutLabel) => {
        soldOutLabel.style.display = 'flex';
      });
      saleLabels?.forEach((saleLabel) => {
        saleLabel.style.display = 'none';
      });
      customLabels?.forEach((customLabel) => {
        customLabel.style.display = 'none';
      });
    } else if (isSale && !isSoldOutVariantSelected) {
      soldOutLabels.forEach((soldOutLabel) => {
        soldOutLabel.style.display = 'none';
      });
      saleLabels?.forEach((saleLabel) => {
        saleLabel.style.display = 'flex';
      });
      customLabels?.forEach((customLabel) => {
        customLabel.style.display = 'flex';
      });
    } else {
      soldOutLabels.forEach((soldOutLabel) => {
        soldOutLabel.style.display = 'none';
      });
      saleLabels?.forEach((saleLabel) => {
        saleLabel.style.display = 'none';
      });
      customLabels?.forEach((customLabel) => {
        customLabel.style.display = 'flex';
      });
    }
  }, ['quick-view-swatch']);

  context.render(() => {
    const currentIndex = getImageIndex();

    if (currentIndex === 0) {
      thumbPrevBtn?.setAttribute('disabled', true);
      thumbNextBtn?.removeAttribute('disabled');
    } else if (currentIndex === images.length - 1) {
      thumbNextBtn?.setAttribute('disabled', true);
      thumbPrevBtn?.removeAttribute('disabled');
    } else {
      thumbPrevBtn?.removeAttribute('disabled');
      thumbNextBtn?.removeAttribute('disabled');
    }
  }, ['quick-view-image']);
};

const selectThumbnail = (currentSlide) => {
  // remove selected class from all thumbnails
  const SELECTED_THUMBNAIL = 'boost-sd__thumbs-container-thumbs-item--selected';
  const currentSelectedThumbnail = document.querySelector(`.${SELECTED_THUMBNAIL}`);
  currentSelectedThumbnail?.classList.remove(SELECTED_THUMBNAIL);

  const selectedThumbnail = document.querySelector(
    `.boost-sd__thumbs-container-thumbs-item:nth-child(${currentSlide + 1})`
  );

  selectedThumbnail?.classList.add(SELECTED_THUMBNAIL);
};

const handleChangeSliderImagesPortion = (
  context,
  index,
  src,
  images,
  thumbItems,
  modal,
  setImageIndex
) => {
  if (!images || !thumbItems || !modal) return;

  const lastPortion = Math.floor((images?.length || 0) / 4) + 1;
  const portion = Math.floor(index / 4) + 1;
  const startHiding = (portion - 1) * 4;
  const endHiding = startHiding + 3;

  thumbItems.forEach((thumbnail, i) => {
    const img = thumbnail.querySelector('img');

    if (i < startHiding || i > endHiding) {
      thumbnail.style.display = 'none';
    } else {
      thumbnail.style.display = 'block';
    }

    if (img.src === src) {
      setImageIndex(index);
      sliderRef.slick('slickGoTo', i);
    }
  });

  /**
   * Borrow from previous portion
   * For example: if the last portion (portion 4) has 2 images
   * then the last 2 images of the previous portion (portion 3) + 2 images of the last  portion (portion 4) should be displayed
   */
  if (portion === lastPortion && lastPortion > 1) {
    const lastPortionLength = images.length % 4;
    const borrowFromPreviousPortion = 4 - lastPortionLength;

    for (let i = 0; i < borrowFromPreviousPortion; i++) {
      thumbItems[images.length - lastPortionLength - 1 - i].style.display = 'block';
    }
  }
};
