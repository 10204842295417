import { INITIAL_PAGE, NEXT_PAGE, PREV_PAGE } from '../constants/pagination';

export const getWindowLocation = () => {
  // Escape window.location.href
  const href = window.location.href;
  const escapedHref = href.replace(/%3C/g, '&lt;').replace(/%3E/g, '&gt;');

  // Rebuild window.location.href
  const rebuildHrefArr = [];
  for (let i = 0; i < escapedHref.length; i++) {
    rebuildHrefArr.push(escapedHref.charAt(i));
  }
  const rebuildHref = rebuildHrefArr.join('').split('&lt;').join('%3C').split('&gt;').join('%3E');

  // Rebuild window.location.search
  let rebuildSearch = '';
  const hrefWithoutHash = rebuildHref.replace(/#.*$/, '');
  if (hrefWithoutHash.split('?').length > 1) {
    rebuildSearch = hrefWithoutHash.split('?')[1];
    if (rebuildSearch.length > 0) {
      rebuildSearch = '?' + rebuildSearch;
    }
  }

  return {
    pathname: window.location.pathname,
    href: rebuildHref,
    search: rebuildSearch,
  };
};

export const setWindowLocation = (url) => {
  window.location.href = url;
};

export const isMobile = (breakpointMobile = 991) => {
  return window.innerWidth <= breakpointMobile;
};

export const getSessionStorage = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

export const setSessionStorage = (key, value) => {
  try {
    if (!value) {
      sessionStorage.removeItem(key);
      return;
    }

    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    sessionStorage.setItem(key, '');
  }
};

export const getLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value) return JSON.parse(value);

    return null;
  } catch (error) {
    return null;
  }
};

export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log('Error setLocalStorage', error);
  }
};

export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log('Error setLocalStorage', error);
  }
};

export const handleClickOutside = (rootElm, callback) => {
  const onClick = (event) => {
    if (!rootElm.contains(event.target)) {
      callback(event.target);
      document.removeEventListener('click', onClick);
    }
  };

  document.addEventListener('click', onClick);

  // Optional: Cleanup function to remove event listener
  return () => document.removeEventListener('click', onClick);
};

export const setPaginationSession = (page) => {
  // Set pages for pagination
  page = Number(page);

  if (!page || Number.isNaN(page)) page = 1;

  setSessionStorage(INITIAL_PAGE, page);
  setSessionStorage(PREV_PAGE, page);
  setSessionStorage(NEXT_PAGE, page);
};

export function inViewPortHandler(elements, callback) {
  const observer = new IntersectionObserver(function intersectionObserverCallback(
    entries,
    observer
  ) {
    entries.forEach(function intersectionEntryCallback(entry) {
      if (entry.isIntersecting) {
        callback(entry.target);
        observer.unobserve(entry.target);
      }
    });
  });
  elements.forEach(function (element) {
    observer.observe(element);
  });
}

export function isMobileWidth() {
  return window.innerWidth < 576;
}

export function isTabletPortraitMaxWidth() {
  return window.innerWidth < 991;
}

export function detectDeviceByWidth() {
  let result = '';
  if (isMobileWidth()) {
    result += 'mobile|';
  } else {
    result = result.replace('mobile|', '');
  }

  if (isTabletPortraitMaxWidth()) {
    result += 'tablet_portrait_max';
  } else {
    result = result.replace('tablet_portrait_max', '');
  }

  return result;
}

export const requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    let start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

export const cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id);
  };
