import { scrollToTop, setLocalStorage } from '../utils';
import { resetFilterRequest } from '../api/filter.js';
import { LIMIT } from '../constants/pagination.js';

export function handleToggleShowLimitList(context, target) {
  const [getLimitList, setLimitList] = context.useContextState('limit-list', {
    isShow: false,
  });

  const { isShow } = getLimitList();

  setLimitList({
    isShow: !isShow,
  });

  context.render(() => {
    const limitList = getLimitList();

    const limitBtn = document.querySelector('.boost-sd__show-limit-list-button');
    const limitListUl = document.querySelector('.boost-sd__show-limit-list-list');

    if (limitList && !!limitList.isShow) {
      limitBtn.classList.add('boost-sd__show-limit-list-button--active');
      limitListUl.classList.add('boost-sd__show-limit-list-list--active');
    } else {
      limitBtn.classList.remove('boost-sd__show-limit-list-button--active');
      limitListUl.classList.remove('boost-sd__show-limit-list-list--active');
      limitBtn.focus();
    }
  }, ['limit-list']);
}

export function handleLimitListOptionClick(context, target) {
  const limit = target.innerHTML;

  target.classList.add('boost-sd__show-limit-list-option--active');

  context.defaultParams.limit = Number(limit);
  scrollToTop();
  resetFilterRequest(context);
  setLocalStorage(LIMIT, Number(limit));

  context.handler(handleToggleShowLimitList, 'handleToggleShowLimitList')(context, target);
}

export const handleClickOutsideLimitList = (context, target) => {
  const limitList = context.$('.boost-sd__show-limit-list');

  if (limitList) {
    if (!target?.classList?.contains('boost-sd__show-limit-list-button')) {
      const [getLimitList, setLimitList] = context.useContextState('limit-list', {
        isShow: false,
      });

      const limitList = getLimitList();

      if (limitList?.isShow) {
        setLimitList({
          isShow: false,
        });
      }
    }
  }
};
