import {
  buildUrlWithLocalization,
  getLocalStorage,
  getSessionStorage,
  getTenantId,
  isCartPage,
  isCollectionPage,
  isHomePage,
  isProductPage,
  isSearchPage,
  removeLocalStorage,
  setLocalStorage,
} from '../utils';
const prefix = 'boost-sd__';
const DEFAULT_SELECTOR = {
  productItem: `.${prefix}product-item, .product-item-list-view-layout, .${prefix}suggestion-queries-item--product`,
  addToCart: `.${prefix}btn-add-to-cart,.${prefix}button--select-option[aria-label="Add to cart"], form[action="/cart/add"] *[type="submit"], form[action="/cart/add"] *[name="add"]`,
  buyNow: `.${prefix}btn-buy-now, .shopify-payment-button__button:not(.product-recommendations .shopify-payment-button__button), .shopify-payment-button, #dynamic-checkout-cart:not(:has(shopify-accelerated-checkout-cart, shop-pay-wallet-button, shopify-google-pay-button))`,
  quickView: `.${prefix}button--quick-view`,
  recommend: `.${prefix}recommendation`,
  suggest: `.${prefix}instant-search-container`,
  inputQuantity: `.quantity__input, .quantity-selector__value, .quantity-selector__input, input#quantity, .select-popout__toggle span, .js-qty__num, .product-form__qty-input input, .product-form__quantity input, .js-qty-input`,
  quickViewQuantityInput: `.${prefix}input-number-input`,
  filterOption: `.${prefix}filter-option-item-button`,
};

window.boostAnalytic = DEFAULT_SELECTOR;

const ANALYTICS_URL =
  boostWidgetIntegration?.env === 'staging'
    ? 'https://lajhlox2el.execute-api.us-west-2.amazonaws.com/staging/e'
    : 'https://lambda.mybcapps.com/e';
const USER_ACTION = {
  VIEW_PRODUCT: 'view_product',
  QUICK_VIEW: 'quick_view',
  ADD_TO_CART: 'add_to_cart',
  BUY_NOW: 'buy_now',
};

const PRE_ACTION = 'boostSdPreAction';
const DATA_NOT_SEND = 'boostSdDataNotSent';
const RECOMMEND_ACTIVE = 'boostSdCurrentRecommendActive';
const LATEST_CART_TOKEN = 'boostSdLatestToken';

import { PRE_REQUEST_IDS, QUERY_STRING_KEY } from '../constants/app';
import { CartAPI } from '../api/cart';
import { KEY_DYNAMIC_BUNDLE_DATA_SESSION } from '../dynamicBundle';

export const ACTION = {
  FILTER: 'filter',
  SEARCH: 'search',
  SUGGEST: 'suggest',
  RECOMMEND: 'recommend',
};

/**
 * Generates a random unique session ID
 * @return {string} random unique ID
 */
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}
var retry = 0;
const maxRetries = 5;
async function refreshCartTokenSendEvent(data) {
  const minLengthOfCartToken = 56;
  try {
    const toJson = await CartAPI.getAll();
    if (toJson?.token?.length < minLengthOfCartToken && retry < maxRetries) {
      retry++;
      return refreshCartTokenSendEvent(data);
    }

    const latestCartToken = getLocalStorage(LATEST_CART_TOKEN);

    if (toJson.token && latestCartToken !== toJson.token) {
      data.ct = toJson.token;

      setLocalStorage(LATEST_CART_TOKEN, toJson.token);

      await postData(ANALYTICS_URL, data);
    }
  } catch (error) {
    console.log('refreshCartTokenSendEvent');
  }
}

async function checkSendEventWhenLoadPage() {
  try {
    const data = getLocalStorage(DATA_NOT_SEND);
    if (data) {
      const response = await fetch('/cart.js');
      const toJson = await response?.json();

      if (toJson.token) {
        data.ct = toJson.token;

        postData(ANALYTICS_URL, data);
        removeLocalStorage(DATA_NOT_SEND);
      }
    }
  } catch (error) {
    console.log('error checkSendEventWhenLoadPage', error);
  }
}

function validateData(data) {
  // check if productId not correct format => re-assign empty string
  if (data.pid && isNaN(data.pid)) {
    data.pid = '';
  }

  return data;
}

function sendEvent(data, activeElement) {
  data = validateData(data);

  // Prevent sent event data when view product have not productId - case bundle as product
  if (!data.pid && data.u === USER_ACTION.VIEW_PRODUCT) {
    return;
  }

  if (data.a === ACTION.RECOMMEND) {
    // not send qs when action recommend
    data.qs = null;
  }
  if (data.a === ACTION.RECOMMEND) {
    const recommendActive = getLocalStorage(RECOMMEND_ACTIVE) || {};
    data = {
      ...data,
      ...recommendActive,
    };
  }

  setLocalStorage(PRE_ACTION, data.a);
  // if cartToken not existed -> get cart -> sendEvent
  const isAddToCart = data.u === USER_ACTION.ADD_TO_CART;
  const isBuyItNow = data.u === USER_ACTION.BUY_NOW;

  if (isAddToCart) {
    const redirect = isATCRedirect(activeElement);
    const variantId = getSelectedVariantId(activeElement);

    redirect ? handleAddToCart(data, variantId) : refreshCartTokenSendEvent(data);
  } else if (isBuyItNow) {
    handleBuyItNow(data, activeElement);
  } else {
    postData(ANALYTICS_URL, data);
  }
}

export function getCustomerId() {
  // https://community.shopify.com/c/shopify-apis-and-sdks/current-best-way-to-get-logged-in-customer-id/td-p/482773
  return (
    window?.__st?.cid ||
    window?.meta?.page?.customerId ||
    window?.ShopifyAnalytics?.meta?.page?.customerId ||
    window?.ShopifyAnalytics?.lib?.user()?.traits()?.uniqToken
  );
}

function getParamsHistory(href) {
  href = href || window.location;
  const url = new URL(href);
  const FILTER_HISTORY_PREFIX = 'pf_';
  const { searchParams } = url;
  const queryParams = new URLSearchParams();

  const sort = searchParams.get('sort');
  const q = searchParams.get('q');
  const collectionId = boostWidgetIntegration?.generalSettings?.collection_id || 0;
  if (sort) queryParams.set('sort', sort);
  if (q) queryParams.set('q', q);
  queryParams.set('collection_scope', collectionId);

  for (let key of searchParams.keys()) {
    if (key.startsWith(FILTER_HISTORY_PREFIX) && !queryParams[key]) {
      queryParams.set(key, searchParams.getAll(key));
    }
  }

  return `${queryParams}`;
}

function getCurrentPage() {
  let currentPage = 'collection_page';

  switch (true) {
    case isCollectionPage():
      currentPage = 'collection_page';
      break;

    case isSearchPage():
      currentPage = 'search_page';
      break;

    case isProductPage():
      currentPage = 'product_page';
      break;

    case isCartPage():
      currentPage = 'cart_page';
      break;

    case isHomePage():
      currentPage = 'home_page';
      break;

    default:
      break;
  }

  return currentPage;
}

export function initAnalytic() {
  if (isSearchPage()) {
    setLocalStorage(QUERY_STRING_KEY, getParamsHistory());
  }

  // For Klaviyo
  identifyUser()
  checkSendEventKlaviyoViewCollection()
  addEventKeyPressForSearching()

  window.addEventListener('debounce-in-collection-search', sendKlaviyoEventInCollectionSearch);
  window.addEventListener('change-range-slider', sendKlaviyoEventChangeRangeSlider);
  window.addEventListener('re-view-collection', checkSendEventKlaviyoViewCollection);
  // End for Klaviyo

  checkSendEventWhenLoadPage();
  console.log('Analytic register events - Version SSR');
  document.addEventListener('click', onClickTrackingEvent, true);
}

function getVariantIdFromUrl() {
  const url = new URL(window.location.href);
  return url.searchParams.get('variant') || '';
}

function validateVariantIdBeforeSent(variantId) {
  if (typeof variantId === 'number') {
    variantId = variantId.toString();
  }
  // check variantId have number
  const isNumber = /^\d+$/.test(variantId);
  const isValidLength = variantId.length > 0 && variantId.length <= 19;

  if (isNumber && isValidLength) {
    return variantId;
  }

  return '';
}

function buildGeneralData(data = {}, option = {}) {
  // getParamsHistory never null
  let query_string = getParamsHistory();
  const requestIds = getLocalStorage(PRE_REQUEST_IDS) || {};
  const productId = data.pid || '';
  const variantId = data.vid && productId !== data.vid ? data.vid : getVariantIdFromUrl();
  const userAction = data.u || USER_ACTION.VIEW_PRODUCT;
  const action = data.a || ACTION.FILTER;
  const requestId = requestIds[action];
  if (action === ACTION.SUGGEST || action === ACTION.SEARCH) {
    query_string += `&${getLocalStorage(QUERY_STRING_KEY)}`;
  } else if (action === ACTION.FILTER) {
    query_string = getParamsHistory(document.referrer);
  }

  return {
    tid: Shopify.shop || getTenantId(), // tenant_id
    qs: query_string,
    eid: generateUUID(), // event_id
    rid: requestId, // request_id
    // ct: null, // cart token only add <=> add_to_cart and buy_now
    pid: productId, // product_id
    t: new Date().toISOString(), // clicked at
    u: userAction, // user action
    a: action || 'other', // action
    r: document.referrer, // referrer
    sid: window.boostWidgetIntegration.getSessionId(), // session_id
    cid: getCustomerId(), // customer_id
    pg: getCurrentPage(), // current page type
    vid: validateVariantIdBeforeSent(variantId), // variant_id
    ...option,
  };
}

function getProductIdFromQuickView(activeElement) {
  if (!activeElement) return null;
  const modalQuickView = activeElement.closest('#boost-sd__modal-quickview');

  if (modalQuickView) {
    if (modalQuickView.dataset.productId) {
      return modalQuickView.dataset.productId;
    } else {
      if (modalQuickView.dataset.product) {
        return JSON.parse(modalQuickView.dataset?.product || '{}')?.id;
      }
    }
  }

  return null;
}

function getModalQuickView() {
  return document.getElementById('boost-sd__modal-quickview');
}

function buildDataDynamicBundle(recommendationElement) {
  const data = {};
  if (recommendationElement?.dataset?.widgetId) {
    data.wid = recommendationElement?.dataset?.widgetId;

    const getSessionDynamicBundleData = getSessionStorage(KEY_DYNAMIC_BUNDLE_DATA_SESSION);
    if (getSessionDynamicBundleData[data.wid]) {
      data.bt = getSessionDynamicBundleData[data.wid]?.bundle_type;

      // data for bundle type AI
      if (data.bt.startsWith('ai')) {
        data.palg = getSessionDynamicBundleData[data.wid]?.primary_algorithm;
        data.salg = getSessionDynamicBundleData[data.wid]?.secondary_algorithm;
      }
    }

    data.rt = getSessionDynamicBundleData[data.wid]?.recommendationType || 'dynamic-bundle';
  }

  return data;
}

function onClickTrackingEvent(event) {
  if (!event || !event.target) return;
  let recommendData = {};

  const activeElement = event.target;
  let action = isSearchPage() ? ACTION.SEARCH : isCollectionPage ? ACTION.FILTER : '';
  let productId = '';
  let actionBundle = false

  if (isProductPage()) {
    action = getLocalStorage(PRE_ACTION) || ACTION.FILTER;
    productId = boostWidgetIntegration?.generalSettings?.product_id || '';
  }

  if (activeElement.closest(boostAnalytic.suggest || DEFAULT_SELECTOR.suggest)) {
    action = ACTION.SUGGEST;
  }

  if (activeElement.closest(boostAnalytic.recommend || DEFAULT_SELECTOR.recommend)) {
    action = ACTION.RECOMMEND;
    const recommendationElement = activeElement.closest(
      boostAnalytic.recommend || DEFAULT_SELECTOR.recommend
    );

    if (recommendationElement && recommendationElement.id) {
      recommendData = getLocalStorage(`boostSdRecommend-${recommendationElement.id}`) || {};

      // data recommendation dynamic bundle
      if (activeElement.closest(`.${prefix}recommendation--dynamic`)) {
        actionBundle = true
        const dataDynamicBundle = buildDataDynamicBundle(recommendationElement);
        recommendData = {
          ...recommendData,
          ...dataDynamicBundle,
        };
      }

      setLocalStorage(RECOMMEND_ACTIVE, recommendData);
    }
  }

  const data = { a: action, pid: productId, actionBundle };

  // listener event from quick view
  window.addEventListener('boost-sd-open-quick-view', function bindDataActionToQuickView(e) {
    const quickViewModal = getModalQuickView();

    quickViewModal?.setAttribute('data-action-analytic', data?.a);
    if (e?.detail?.pid) quickViewModal?.setAttribute('data-product-id', e?.detail?.pid);

    window.removeEventListener('boost-sd-open-quick-view', bindDataActionToQuickView);
  });

  let isSent = false;

  if (
    activeElement.closest(boostAnalytic.addToCart || DEFAULT_SELECTOR.addToCart) ||
    isClickAddToCart(activeElement)
  ) {
    isSent = true;
    if (!data.pid) {
      data.pid = getProductIdFromQuickView(activeElement);
    }

    data.u = USER_ACTION.ADD_TO_CART;
    const actionFromQuickView = getModalQuickView()?.dataset.actionAnalytic;
    if (actionFromQuickView) {
      data.a = actionFromQuickView;
    }
    /**
     * If the add to cart redirects to cart page, need to intercept the event
     */
    if (isATCRedirect(activeElement)) {
      event.preventDefault();
      event.stopPropagation();
    }
  } else if (activeElement.closest(boostAnalytic.buyNow || DEFAULT_SELECTOR.buyNow)) {
    isSent = true;
    if (!data.pid) {
      data.pid = getProductIdFromQuickView(activeElement);
    }

    const actionFromQuickView = getModalQuickView()?.dataset.actionAnalytic;
    if (actionFromQuickView) {
      data.a = actionFromQuickView;
    }

    data.u = USER_ACTION.BUY_NOW;

    /**
     * Buy it now will always redirect to the checkout page, need to intercept the event
     */
    event.preventDefault();
    event.stopPropagation();
  } else if (activeElement.closest(boostAnalytic.quickView || DEFAULT_SELECTOR.quickView)) {
    isSent = true;

    data.u = USER_ACTION.QUICK_VIEW;
  } else if (activeElement.closest(boostAnalytic.recommend || DEFAULT_SELECTOR.recommend)) {
    isSent = true;
  }

  // Place Latest Click into Product Item - assign productId
  if (activeElement.closest(boostAnalytic.productItem || DEFAULT_SELECTOR.productItem)) {
    isSent = true;
    const productItem = activeElement.closest(
      boostAnalytic.productItem || DEFAULT_SELECTOR.productItem
    );
    const productId = productItem?.dataset?.id || productItem?.getAttribute('id');
    const variantId = productItem?.dataset?.productId; // product-id will is variant-id in turbo version

    data.pid = productId;
    data.vid = variantId || ''; // add variant_id for turbo version
  }

  // if product page get productId from general Settings
  if (!data.pid && boostSDData?.product?.id) {
    data.pid = boostSDData?.product?.id;
  }

  // not build data, sent if isSent = false
  if (!isSent) return;

  if (isSent) {
    const buildData = buildGeneralData(data, { ...recommendData, actionBundle });
    sendEvent(buildData, activeElement);

    if (window.klaviyo) {
      const productItem = activeElement.closest('.boost-sd__product-item')
      if (productItem) {
        try {
          const productTitle = productItem.querySelector('.boost-sd__product-title')
          const productLink = productItem.querySelector('.boost-sd__product-link')
          const path = productLink ? productLink.getAttribute('href') : ''
          const dataProduct = productItem.getAttribute('data-product') ? JSON.parse(productItem.getAttribute('data-product')) : null

          buildData.product_name = productTitle?.innerText || ''
          buildData.product_link = `https://${getTenantId()}${path}`
          if (dataProduct) {
            buildData.product_price = Number(dataProduct.priceMin)
            buildData.product_category = dataProduct.category || ''
            buildData.product_tags = dataProduct.tags || []
          }
        }
        catch (e) {
          console.log('error', e)
        }
      }

      sendKlaviyoEvent(buildData);
    }
  }

  if (activeElement.closest(boostAnalytic.filterOption || DEFAULT_SELECTOR.filterOption)) {
    if (!activeElement.classList.contains('boost-sd__filter-option-item-button')) {
      setTimeout(() => {
        const parentNode = activeElement.parentNode
        if (parentNode.classList.contains('boost-sd__filter-option-item-button--selected')) {
          sendKlaviyoEventForClickFilterOption(activeElement)
        }
      }, 0)
    }
    else {
      setTimeout(() => {
        if (activeElement.classList.contains('boost-sd__filter-option-item-button--selected')) {
          sendKlaviyoEventForClickFilterOption(activeElement.querySelector('.boost-sd__filter-option-item-label'))
        }
      }, 0)
    }
  }
}

// Get selected variant id from our template metadata
const getSelectedVariantId = (element) => {
  try {
    let variantId = element?.dataset?.variantId;

    if (!variantId) {
      const metadata = JSON.parse(element?.dataset?.metadata || {});
      variantId = metadata?.action?.addToCart?.productId;
    }

    return variantId;
  } catch (error) {
    return null;
  }
};

const handleAddToCart = async (data, variantIdFromApp) => {
  try {
    const quantityValue =
      document.querySelector(
        boostAnalytic.quickViewQuantityInput || DEFAULT_SELECTOR.quickViewQuantityInput
      )?.value || 1;

    await CartAPI.add(variantIdFromApp, Number(quantityValue));
    await refreshCartTokenSendEvent(data);
  } catch (error) {
    console.log('error addToCart', error);
  }

  window.location.href = buildUrlWithLocalization('cart');
};

const handleBuyItNow = async (data, activeElement) => {
  /**
   * Steps of handling Buy it now:
   * 1. Clear the cart
   * 2. Add the selected variant to the cart
   * 3. Refresh the cart token and send the analytics event
   * 4. Redirect to the checkout page
   */

  /**
   * Buy it now can be added from either of the following places:
   * - Quick View
   * - Product detail page
   */

  try {
    await CartAPI.clearCart();
    // Case buy it now from product detail page
    let quantityValue = 1;
    let variantId = null;

    if (isAddedFromOurApp(activeElement)) {
      // Case buy it now from our app - Quick View
      variantId = getSelectedVariantId(activeElement);
      quantityValue =
        document.querySelector(
          boostAnalytic.quickViewQuantityInput || DEFAULT_SELECTOR.quickViewQuantityInput
        )?.value || 1;
    } else {
      // Case buy it now from product detail page
      const params = new URLSearchParams(window.location.search);
      variantId = params.get('variant');

      // If no variantId, get the first default variant of product
      const product = boostSDData?.product;
      if (!variantId && product?.variants?.length > 0) {
        const firstAvailableProductVariant = product.variants.find((variant) => variant.available);
        variantId = firstAvailableProductVariant?.id;
      }

      quantityValue =
        document.querySelector(boostAnalytic.inputQuantity || DEFAULT_SELECTOR.inputQuantity)
          ?.value || 1;
    }

    await CartAPI.add(variantId, Number(quantityValue));
    await refreshCartTokenSendEvent(data);
  } catch (error) {
    console.log('error buyItNow', error);
  }

  window.location.href = buildUrlWithLocalization('checkout');
};

const isAddedFromOurApp = (activeElement) => {
  return activeElement?.className?.includes?.(prefix);
};

const isCartEnable = () => {
  const env = window.boostWidgetIntegration?.env || 'production';
  const { enableCart } =
    window.boostWidgetIntegration?.app?.[env]?.templateSettings?.themeSettings?.cart || {};
  return enableCart;
};

const isATCRedirect = (activeElement) => {
  /**
   * If the product is added to the cart from our app and the cart feature is disabled
   * then we need to wait for the analytics event to be sent before redirecting to the cart page
   * Otherwise, we can send the event immediately because it won't redirect
   */
  const addedFromOurApp = isAddedFromOurApp(activeElement);
  const enableCart = isCartEnable();

  return addedFromOurApp && !enableCart;
};

function isClickAddToCart(activeElement) {
  if (!activeElement) return false;
  /**
   * if not product page then no detection is needed
   * if add from our app then no detection is needed
   * */
  if (!isProductPage() || isAddedFromOurApp(activeElement)) return false;

  const addToCartKeywords = [
    'add to cart',
    'add-to-cart',
    'add to bag',
    'add_to_cart',
    'addtocart',
    'data-product-form-add',
    'name="add"',
  ];

  const innerText = activeElement?.innerHTML?.toLowerCase() || '';

  return addToCartKeywords.some((keyword) => innerText.includes(keyword));
}

const KLAVIYO_BOOST_EVENT = {
  CLICK_SEARCH_RESULT: '[Boost] Clicked Search Result',
  ADD_TO_CART: '[Boost] Added To Cart',
  VIEW_COLLECTION: "[Boost] Viewed Collection",
  CLICK_PRODUCT: '[Boost] Clicked Product',
  ADD_BUNDLE_TO_CART: "[Boost] Added Bundle To Cart",
  CLICK_RECOMMENDATION: "[Boost] Clicked Recommendation",
  SUBMIT_SEARCH: "[Boost] Submitted Search",
  CLICK_FILTER: "[Boost] Clicked Filter",
};

const MAP_PATH_CLICK = {
  'search': "Search page",
  "suggest": "Instant search"
}

function checkSendEventKlaviyoViewCollection() {
  if (isCollectionPage() && window.klaviyo) {
    window.klaviyo.push(['track', KLAVIYO_BOOST_EVENT.VIEW_COLLECTION, {
      timestamp: (new Date()).valueOf(),
      sessionId: window.boostWidgetIntegration.getSessionId(),
      collectionId: boostWidgetIntegration?.generalSettings?.collection_id,
      collectionName: boostWidgetIntegration?.generalSettings?.collection_handle,
    }]);
  }
}

function sendKlaviyoEvent(event) {
  switch (event.u) {
    case USER_ACTION.VIEW_PRODUCT: {
      if (event.pid) {
        if (event.a === ACTION.RECOMMEND && event.wid) {
          const item = {
            timestamp: (new Date(event.t)).valueOf(),
            sessionId: event.sid,
            productId: event.pid,
            variantId: event.vid,
            widgetType: event.rt,
            widgetContext: event.pg,
            productName: event.product_name,
            productPrice: event.product_price,
            productUrl: event.product_link,
            productCategory: event.product_category,
            productTags: event.product_tags

          };
          klaviyo.push(['track', KLAVIYO_BOOST_EVENT.CLICK_RECOMMENDATION, item]);
        }
        else {
          if (event.pg !== 'collection_page') {
            let searchQuery = ''
            if (event.a === ACTION.SUGGEST) {
              const inputsSearch = document.querySelectorAll('.boost-sd__search-widget-init-input');
              for (let i = 0; i < inputsSearch.length; i++) {
                const input = inputsSearch[i]
                if (input?.value) {
                  searchQuery = input.value
                  break;
                }
              }
            }
            else if (event.a === ACTION.SEARCH) {
              const searchParams = new URLSearchParams(window.location.search);
              searchQuery = searchParams.get('q');
            }
            const item = {
              searchQuery,
              timestamp: (new Date(event.t)).valueOf(),
              sessionId: event.sid,
              productId: event.pid,
              pathClick: MAP_PATH_CLICK[event.a],
              productName: event.product_name,
              productPrice: event.product_price,
              productUrl: event.product_link,
              productCategory: event.product_category,
              productTags: event.product_tags
            };
            klaviyo.push(['track', KLAVIYO_BOOST_EVENT.CLICK_SEARCH_RESULT, item]);
          }
          else {
            const item = {
              timestamp: (new Date(event.t)).valueOf(),
              sessionId: event.sid,
              productId: event.pid,
              variantId: event.vid,
              productName: event.product_name,
              productPrice: event.product_price,
              productUrl: event.product_link,
              productCategory: event.product_category,
              productTags: event.product_tags
            };
            klaviyo.push(['track', KLAVIYO_BOOST_EVENT.CLICK_PRODUCT, item]);
          }
        }
      }
      break;
    }

    case USER_ACTION.ADD_TO_CART: {
      if (event.actionBundle) {
        try {
          const dynamicBundle = JSON.parse(sessionStorage.DynamicBundleDataSession)
          if (dynamicBundle[event.wid]) {
            const item = {
              timestamp: (new Date(event.t)).valueOf(),
              sessionId: event.sid,
              pageType: event.pg,
              bundlePrice: dynamicBundle[event.wid]?.totalBundlePrice,
              bundleDiscount: dynamicBundle[event.wid]?.amountValueDisplay,
              bundleProductIds: dynamicBundle[event.wid]?.bundleProductIds
            };
            klaviyo.push(['track', KLAVIYO_BOOST_EVENT.ADD_BUNDLE_TO_CART, item]);
          }
        }
        catch (e) {
          console.log('Error parsing dynamic bundle')
        }
      }
      else if (event.pid) {
        const item = {
          timestamp: (new Date(event.t)).valueOf(),
          sessionId: event.sid,
          productId: event.pid,
          variantId: event.vid,
          addToCartContext: event.a === ACTION.RECOMMEND ? event.wid : event.pg
        };

        if (event.product_name) {
          item.productName = event.product_name
        }
        if (event.product_price) {
          item.productPrice = event.product_price
        }
        if (event.product_link) {
          item.productUrl = event.product_link
        }
        if (event.product_category) {
          item.productCategory = event.product_category
        }
        if (event.product_tags) {
          item.productTags = event.product_tags
        }
        klaviyo.push(['track', KLAVIYO_BOOST_EVENT.ADD_TO_CART, item]);
      }
      break;
    }
    default:
      break;
  }
}

function addEventKeyPressForSearching() {
  let interval
  let countInterval = 0

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && e.target.value && window.klaviyo) {
      const item = {
        timestamp: (new Date()).valueOf(),
        sessionId: window.boostWidgetIntegration.getSessionId(),
        searchQuery: e.target.value,
      };
      klaviyo.push(['track', KLAVIYO_BOOST_EVENT.SUBMIT_SEARCH, item]);
    }
  }

  if (boostWidgetIntegration?.taeSettings?.instantSearch?.enabled) {
    interval = setInterval(() => {
      // Retry maximum 10 times
      if (countInterval === 10) {
        clearInterval(interval)
        return
      }

      if (document.querySelector('.boost-sd__search-widget-init-enabled')) {
        clearInterval(interval)
        const inputsSearch = document.querySelectorAll('.boost-sd__search-widget-init-input')


        inputsSearch.forEach((input) => {
          input.addEventListener('keyup', handleKeyUp);
        })
      }
      else {
        countInterval += 1
      }
    }, [4000])
  }
}

function sendKlaviyoEventForClickFilterOption(labelElement) {
  if (labelElement && window.klaviyo) {
    const filterOptionList = labelElement.closest('.boost-sd__filter-option')

    if (filterOptionList) {
      const filterOptionTitle = filterOptionList.querySelector('.boost-sd__filter-option-title')
      if (filterOptionTitle) {

        const item = {
          timestamp: (new Date()).valueOf(),
          sessionId: window.boostWidgetIntegration.getSessionId(),
          filterOptionName: filterOptionTitle.innerText?.trim(),
          filterOptionValue: labelElement.innerText?.trim(),
          collectionId: boostWidgetIntegration?.generalSettings?.collection_id
        };

        klaviyo.push(['track', KLAVIYO_BOOST_EVENT.CLICK_FILTER, item]);
      }
    }
  }
}

function sendKlaviyoEventInCollectionSearch(params) {
  if (params?.detail?.searchTerm && window.klaviyo) {
    const item = {
      timestamp: (new Date()).valueOf(),
      sessionId: window.boostWidgetIntegration.getSessionId(),
      searchQuery: params.detail.searchTerm,
    };
    klaviyo.push(['track', KLAVIYO_BOOST_EVENT.SUBMIT_SEARCH, item]);
  }
}

function sendKlaviyoEventChangeRangeSlider(params) {
  if (params?.detail?.min && params?.detail?.max && window.klaviyo) {
    const item = {
      timestamp: (new Date()).valueOf(),
      sessionId: window.boostWidgetIntegration.getSessionId(),
      filterOptionName: params?.detail?.label,
      filterOptionValueMin: params.detail.min,
      filterOptionValueMax: params.detail.max,
      collectionId: boostWidgetIntegration?.generalSettings?.collection_id
    };

    klaviyo.push(['track', KLAVIYO_BOOST_EVENT.CLICK_FILTER, item]);
  }
}

async function identifyUser() {
  if (window.klaviyo) {
    const isIdentified = await klaviyo.isIdentified()
    if (!isIdentified && boostWidgetIntegration?.app?.staging?.user?.email) {
      klaviyo.identify({
        'email': boostWidgetIntegration?.app?.staging?.user?.email,
        '$first_name': boostWidgetIntegration?.app?.staging?.user?.first_name || '',
        '$last_name': boostWidgetIntegration?.app?.staging?.user?.last_name || ''
      });
    }
  }
}

export function dispatchEventDebounceInCollectionSearch(searchTerm) {
  window.dispatchEvent(new CustomEvent('debounce-in-collection-search', {
    detail: {
      searchTerm
    },
  }));
}

export function dispatchEventChangeRangeSlider({ label,
  min,
  max }) {
  window.dispatchEvent(new CustomEvent('change-range-slider', {
    detail: {
      label,
      min,
      max
    },
  }));
}

export function dispatchEventReViewCollection() {
  window.dispatchEvent(new CustomEvent('re-view-collection'));
}
