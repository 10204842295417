import { CartAPI } from '../api/cart';
import { getTemplateByIds } from '../api/template';
import { predictiveBundleByProductId } from '../api/bundle';
import {
  formatCurrency,
  getSessionStorage,
  handleClickTagAItemBuildUrlWithLocale,
  isSearchPage,
  setSessionStorage,
  setWindowLocation,
  splitGoogleFontStyle,
} from '../utils';

import { setUniformProductImageHeight } from '../product-item/index.js';

const DYNAMIC_BUNDLE = 'dynamic-bundle';
const KEY_DYNAMIC_BUNDLE = 'dynamicBundleKey';
const PRE_FIX_CLASS_DYNAMIC_BUNDLE_MODAL = 'boost-sd__dynamic-bundle-modal-';
const MODAL_WRAPPER_SELECTOR = '.boost-sd__modal-wrapper';
const RECOMMENDATION_SELECTOR = '.boost-sd__recommendation';
const DYNAMIC_BUNDLE_ID_CONTAINER_SEARCH_PAGE = 'boost-sd__dynamic-bundle-search-page';
const EMBEDDED_BUNDLE_ID_CONTAINER_PRODUCT = 'boost-sd__embedded-bundle-product';
const CHECK_BOX_SELECTOR = '.boost-sd__recommendation-bundle-checkbox';
const BUNDLE_IMAGE_ITEM_SELECTOR = '.boost-sd__recommendation-bundle-image-item';
const DYNAMIC_BUNDLE_SHOW_POPUP_SELECTOR = '.boost-sd__recommendation-dynamic-show-popup';
const BUTTON_ADD_BUNDLE_TO_CART_SELECTOR = `.boost-sd__btn-add-to-cart`;
const BUNDLE_ACTION = {
  initData: 'init-data',
  changeVariant: 'change-variant',
  clickCheckbox: 'click-checkbox',
};
const DROPDOWN_LIST = 'boost-sd__dropdown-option-list';
const DROPDOWN_LIST_OPEN = 'boost-sd__dropdown-option-list--open';
const DROPDOWN_ITEM = 'boost-sd__dropdown-dynamic-item';
const DROPDOWN_SELECT_OPTION = 'boost-sd__dropdown-selected-option';
const DROPDOWN_CONTAINER = 'boost-sd__dropdown-dynamic';
const DYNAMIC_BUNDLE_DEFAULT_SETTINGS = {
  titleName: 'Buy more and save',
  templateType: 'default',
  titleAlignment: 'center',
  titleTextColor: '#3D4246',
  titleFont: 'Poppins',
  titleTextTransform: 'capitalize',
  titleFontStyle: 'regular',
  titleFontSize: 14,
  buttonBackgroundColor: '#111111',
  buttonText: 'Add bundle to cart',
  buttonTextColor: '#FFFFFF',
  isDefault: false,
};
const PREDICTIVE_BUNDLE_BLOCK_SELECTOR = '.boost-sd-widget-predictive-bundle';
const BUNDLE_NAME_TEMPLATE = {
  dynamic: 'recommendationDynamicBundle',
  embedded: 'recommendationEmbeddedBundle',
};
export const KEY_DYNAMIC_BUNDLE_DATA_SESSION = `DynamicBundleDataSession`;

const formatPrice = (context, price) => {
  const { showCentAsSuperscript, showCurrencyCodes } =
    context.templateSettings?.themeSettings?.productItems?.productInfo?.elements?.price || {};
  return formatCurrency({
    context,
    value: Number(price),
    showCurrencyCodes,
    showCentAsSuperscript,
    removeDecimalPoint: showCentAsSuperscript,
  });
};

const isValidateDynamicBundle = (context) => {
  // If there is no widgetId => do nothing
  if (!context || !context.widgetId || context.widgetId === '') return false;

  // If there is no recommendationWidgets => do nothing
  const recommendationWidgets = context.app?.recommendationWidgets;
  if (!recommendationWidgets || Object.keys(recommendationWidgets).length == 0) return false;

  const dynamicBundleWidgets = recommendationWidgets?.[DYNAMIC_BUNDLE];

  if (!dynamicBundleWidgets || Object.keys(dynamicBundleWidgets).length == 0) return false;

  return true;
};

const getDynamicBundleWidgetTemplate = async (context, name = 'recommendationDynamicBundle') => {
  const { templates = {} } = await getTemplateByIds(
    context,
    context.app.templateMetadata?.themeNameLib,
    [
      {
        name: name,
        id: context.app.templateMetadata?.[name],
      },
    ]
  );

  return templates[context.app.templateMetadata?.[name]] || '';
};

const getDynamicBundleDataByWidgetId = (context, widgetId, isPopupDynamicBundleOpening) => {
  const [getDynamicBundleData] = context.useContextState(`${KEY_DYNAMIC_BUNDLE}-${widgetId}`, {});

  return {
    ...getDynamicBundleData(),
    isPopupDynamicBundleOpening,
  };
};

const renderDynamicBundleWithData = async (
  context,
  widgetId,
  dynamicBundleData,
  wrapper,
  name,
  callBack
) => {
  const templateDynamicBundle = await getDynamicBundleWidgetTemplate(context, name);
  const html = await context.templateRender(templateDynamicBundle, {
    bundle: dynamicBundleData,
    ...getDynamicBundleSettingsByWidgetId(context, widgetId),
  });

  if (wrapper) {
    wrapper.innerHTML = html;
  } else if (dynamicBundleData?.isPopupDynamicBundleOpening) {
    const bundleModal = document
      ?.getElementById(`${PRE_FIX_CLASS_DYNAMIC_BUNDLE_MODAL}${context.blockType}`)
      ?.querySelector(MODAL_WRAPPER_SELECTOR);

    if (bundleModal) {
      bundleModal.innerHTML = html;
      bundleModal.style.display = 'block';
    }
  } else {
    const targetElementByContext = getTargetElementUpdate(context);
    if (targetElementByContext) {
      targetElementByContext.innerHTML = html;
    }
  }

  if (typeof callBack === 'function') {
    callBack();
  }
};

const addBundleToCart = async (context, target) => {
  const widgetId = target.closest(RECOMMENDATION_SELECTOR)?.dataset?.widgetId;

  const dynamicBundleData = getDynamicBundleDataByWidgetId(context, widgetId);

  const data = [];

  const _boostBundleProducts = dynamicBundleData?.products
    ?.map((product) => product.selectedOption.id)
    .join(',');

  for (const product of dynamicBundleData?.products) {
    if (!product.checked) continue;

    const { id, rawPrice } = product.selectedOption;

    data.push({
      id,
      quantity: 1,
      properties: {
        BoostBundle: '',
        _boostBundleId: dynamicBundleData.widgetId,
        _boostBundleType: dynamicBundleData.bundle_type,
        _boostItemPrice: rawPrice,
        _boostBundleProducts: _boostBundleProducts,
      },
    });
  }
  const btnAddToCart = target.closest(BUTTON_ADD_BUNDLE_TO_CART_SELECTOR);
  const ctaBtnText = btnAddToCart?.querySelector('span');
  const textAddBundleAddToCart = ctaBtnText.innerText || 'Add bundle to cart';

  const element = ctaBtnText || target;

  element.textContent =
    context.app?.translation?.productItem?.atcAddingToCartBtnLabel || 'Adding...';

  btnAddToCart.setAttribute('disabled', true);

  try {
    const res = await CartAPI.addMultiProductToCart(data);
    if (res.status === 200) {
      element.textContent =
        context.app?.translation?.productItem?.atcAddedToCartBtnLabel || 'Added!';

      setWindowLocation('/cart');
    } else {
      element.textContent =
        context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';

      btnAddToCart.removeAttribute('disabled');
    }
  } catch {
    element.textContent =
      context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';

    btnAddToCart.removeAttribute('disabled');
  }

  setTimeout(function updateTextAddBundleToCart() {
    element.textContent = textAddBundleAddToCart;
  }, 500);
};

const getTargetElementUpdate = (context) => {
  let targetElement = null;
  if (context.blockType === 'instantSearch') {
    targetElement = document
      ?.getElementById(`${PRE_FIX_CLASS_DYNAMIC_BUNDLE_MODAL}${context.blockType}`)
      ?.querySelector(MODAL_WRAPPER_SELECTOR);
  } else {
    if (isSearchPage(context)) {
      targetElement = document?.getElementById(DYNAMIC_BUNDLE_ID_CONTAINER_SEARCH_PAGE);
    }
  }

  return targetElement;
};

const updateDynamicBundleData = (context, widgetId, data, action, target) => {
  const [getDynamicBundleData] = context.useContextState(`${KEY_DYNAMIC_BUNDLE}-${widgetId}`, {});
  const oldDataDynamicBundle = getDynamicBundleData();

  if (!oldDataDynamicBundle) {
    console.error('Bundle data not found');
    return;
  }

  return dynamicBundleData(context, oldDataDynamicBundle, data, action, target);
};

const openDynamicBundleModal = async (context, widgetId) => {
  const bundleModal = document.getElementById(
    `${PRE_FIX_CLASS_DYNAMIC_BUNDLE_MODAL}${context.blockType}`
  );
  const wrapperModal = bundleModal?.querySelector(MODAL_WRAPPER_SELECTOR);
  const dynamicBundleData = getDynamicBundleDataByWidgetId(context, widgetId, true);

  renderDynamicBundleWithData(
    context,
    widgetId,
    dynamicBundleData,
    wrapperModal,
    BUNDLE_NAME_TEMPLATE.dynamic,
    function showModalDynamicBundle() {
      if (wrapperModal) {
        bundleModal.style.display = 'flex';
      }
    }
  );
};

const closeDynamicBundleModal = (context) => {
  const bundleModal = document.getElementById(
    `${PRE_FIX_CLASS_DYNAMIC_BUNDLE_MODAL}${context.blockType}`
  );

  if (bundleModal) {
    bundleModal.style.display = 'none';
  }
};

const removeClassDropdownListOpening = (dropdown) => {
  return dropdown?.classList?.remove(DROPDOWN_LIST_OPEN);
};

const closeAllDropdown = () => {
  const dropdowns = document.querySelectorAll(`.${DROPDOWN_LIST}.${DROPDOWN_LIST_OPEN}`);

  dropdowns?.forEach(removeClassDropdownListOpening);
};

const toggleDropdown = (target) => {
  const targetDropdown = target.closest(`.${DROPDOWN_CONTAINER}`);
  const isOpen = targetDropdown?.querySelector(`.${DROPDOWN_LIST_OPEN}`);

  closeAllDropdown(); // Close all dropdown before new open other dropdown

  if (!isOpen) {
    targetDropdown?.querySelector(`.${DROPDOWN_LIST}`)?.classList?.add(DROPDOWN_LIST_OPEN);
  }
};

const selectVariantProduct = async (context, target, wrapper) => {
  const selectedVariantItem = target.closest(`.${DROPDOWN_ITEM}`);
  if (selectedVariantItem) {
    const variantId = target.closest(`.${DROPDOWN_ITEM}`)?.dataset?.variantId;
    const productId = target.closest(BUNDLE_IMAGE_ITEM_SELECTOR)?.dataset?.productId;
    const widgetId = target.closest(RECOMMENDATION_SELECTOR)?.dataset?.widgetId;

    const dynamicBundleData = updateDynamicBundleData(
      context,
      widgetId,
      { variantId, productId },
      BUNDLE_ACTION.changeVariant,
      target
    );
    renderDynamicBundleWithData(
      context,
      widgetId,
      dynamicBundleData,
      wrapper,
      BUNDLE_NAME_TEMPLATE.dynamic
    );

    target.closest(`.${DROPDOWN_LIST_OPEN}`)?.classList?.remove(DROPDOWN_LIST_OPEN);
  }
};

const toggleCheckbox = async (context, target, wrapper) => {
  const checkBoxProduct = target.closest(CHECK_BOX_SELECTOR);

  if (checkBoxProduct) {
    // re-render dynamic bundle
    const productId = target.closest(BUNDLE_IMAGE_ITEM_SELECTOR)?.dataset?.productId;
    const widgetId = target.closest(RECOMMENDATION_SELECTOR)?.dataset?.widgetId;

    const newDataDynamicBundle = await updateDynamicBundleData(
      context,
      widgetId,
      { productId },
      BUNDLE_ACTION.clickCheckbox,
      target
    );

    renderDynamicBundleWithData(
      context,
      widgetId,
      newDataDynamicBundle,
      wrapper,
      BUNDLE_NAME_TEMPLATE.dynamic
    );
  }
};

const initBasicEventsForDynamicBundle = (context, target, event, wrapper) => {
  // click change variants in dynamic
  selectVariantProduct(context, target, wrapper);

  // handle click checkbox
  toggleCheckbox(context, target, wrapper);

  // click select option - close other dropdown list and toggle this dropdown list
  if (target.closest(`.${DROPDOWN_SELECT_OPTION}`)) {
    toggleDropdown(target);
  } else {
    // click outside select option - close all dropdown list
    closeAllDropdown();
  }

  // click outside dynamic bundle or click button close dynamic bundle then close
  if (
    (!target.closest(RECOMMENDATION_SELECTOR) && !target?.closest('.boost-sd__product-bundle')) ||
    target.closest('.boost-sd__modal-close-btn--dynamic-bundle')
  ) {
    closeDynamicBundleModal(context);
  }

  // handle click product item with dynamic bundle integrate with locale
  const classProductLink =
    'a.boost-sd__recommendation-bundle-link, a.boost-sd__recommendation-bundle-link-image';
  handleClickTagAItemBuildUrlWithLocale(event, classProductLink);
};

const filterProductAvailable = (product) => {
  return product?.available;
};

const filterProductSelected = (product) => {
  return product?.checked;
};

const findSelectedVariant = (variant, product) => {
  return variant.id == product.selectedOption?.id;
};

const generateUuid = () => {
  return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function (c) {
    var r = (Math.random() * 6) | 0;
    return r.toString(16);
  });
};

const registerEventBundle = (context, targetElement) => {
  targetElement.addEventListener('click', function (event) {
    const target = event.target;
    initBasicEventsForDynamicBundle(context, target, event, targetElement);

    // add to cart Bundle
    if (target.closest(BUTTON_ADD_BUNDLE_TO_CART_SELECTOR)) {
      addBundleToCart(context, target);
    }
  });
};

const registerEventBundleModal = (context, domEvent) => {
  // create div bundle Modal dialog
  createDivBundleModal(context);

  domEvent?.addEventListener('click', function (event) {
    const target = event.target;

    initBasicEventsForDynamicBundle(context, target, event);

    if (target.closest(DYNAMIC_BUNDLE_SHOW_POPUP_SELECTOR)) {
      const widgetId = target.closest(RECOMMENDATION_SELECTOR)?.dataset?.widgetId;
      openDynamicBundleModal(context, widgetId);
    }
  });
};

export const getDynamicBundleSettingsByWidgetId = (context, widgetId) => {
  let widgetDesignSettings =
    context?.app?.recommendationWidgets?.['dynamic-bundle']?.[widgetId]?.widgetDesignSettings || {};

  const dynamicDefaultSettings =
    context?.app?.recommendationWidgets?.dynamicBundleDefaultSettings ||
    DYNAMIC_BUNDLE_DEFAULT_SETTINGS;

  widgetDesignSettings = {
    ...dynamicDefaultSettings,
    ...widgetDesignSettings,
  };

  // Split font style to font style and font weight
  const { titleFontStyle } = widgetDesignSettings;
  const { fontWeight, fontStyle } = splitGoogleFontStyle(titleFontStyle);

  return {
    widgetDesignSettings: {
      ...widgetDesignSettings,
      titleFontStyle: fontStyle,
      titleFontWeight: fontWeight,
    },
  };
};

export const dynamicBundleData = (context, bundle, optionData, action, target) => {

  const [getDynamicBundleData, setDynamicBundleData] = context.useContextState(
    `${KEY_DYNAMIC_BUNDLE}-${bundle.widgetId}`,
    {}
  );
  const recommendationData = getDynamicBundleData();
  let { products = bundle.products } = recommendationData;

  // because bundle_type starts with ai will change product each time
  if (bundle.bundle_type?.startsWith('ai_')) {
    products = bundle.products;
  }

  let totalPrice = 0;
  let totalBundlePrice = 0;

  // set checked product
  for (const product of products) {
    let data = product;
    product.id = Number(product.id);

    if (product.available) {
      if (!action || action === BUNDLE_ACTION.initData) {
        product.checked = true;
      } else if (action === BUNDLE_ACTION.clickCheckbox && product.id == optionData.productId) {
        product.checked = !product.checked;
      }
    } else product.checked = false;

    if (product.variants.length > 0) {
      // only get variants available
      product.variants = product.variants.filter(filterProductAvailable);

      // if have choose then keep before selected
      if (product.selectedOption?.id) {
        data = product.variants.find((variant) => findSelectedVariant(variant, product));
      } else {
        // if have not choose then default select first variant
        data = product.variants[0];
      }
    }

    if (
      action === BUNDLE_ACTION.changeVariant &&
      optionData?.productId &&
      optionData.variantId &&
      product.variants.length > 0 &&
      optionData?.productId == product.id
    ) {
      // change select data variant
      data = product.variants.find((variant) => variant.id == optionData.variantId);
    }

    const { price: originPrice = 0, title, id } = data;

    product.selectedOption = {
      rawPrice: Number(originPrice || 0),
      title,
      price: formatPrice(context, originPrice),
      originPrice: formatPrice(context, originPrice),
      id,
    };

    if (product.checked) {
      totalPrice += +originPrice;
    }
  }
  // Check if all elements in the products array have checked === true
  const isSaleChecked = products.every(filterProductSelected) && bundle.discount_value > 0;

  // calculate discount for product selected
  if (isSaleChecked) {
    const currencyRate = Number(Shopify?.currency?.rate || 1);
    if (bundle.discount_type === 'percent') {
      totalBundlePrice = totalPrice - (totalPrice * bundle.discount_value) / 100;
    } else {
      totalBundlePrice = Math.abs(totalPrice - bundle.discount_value * currencyRate);
    }
  } else {
    totalBundlePrice = totalPrice;
  }

  const hasSelectedProduct =
    products.length > 0 && products.filter(filterProductAvailable).length > 0;

  // sort current product first
  if (bundle.currentProductId) {
    const index = products.findIndex((product) => product.id === bundle.currentProductId);
    if (index !== -1) {
      const [product] = products.splice(index, 1);
      products.unshift(product);
    }
  }

  bundle.products = products;
  bundle.rawTotalPrice = totalPrice;
  bundle.totalPrice = formatPrice(context, totalPrice);
  bundle.totalBundlePrice = formatPrice(context, totalBundlePrice);
  const currencyRate = Number(Shopify?.currency?.rate || 1);
  bundle.amountValueDisplay =
    bundle.discount_type === 'percent'
      ? bundle.discount_value + `%`
      : bundle.discount_value === 0
        ? 0
        : formatPrice(context, Math.abs(bundle.discount_value * currencyRate));

  bundle.hasSelectedProduct = hasSelectedProduct;
  // hardcode style2 - currently only support style
  bundle.bundleStyle = 'style2';

  setDynamicBundleData(bundle);

  if (target?.closest(`#${PRE_FIX_CLASS_DYNAMIC_BUNDLE_MODAL}${context.blockType}`)) {
    bundle.isPopupDynamicBundleOpening = true;
  }

  const oldBundleDataSession = getSessionStorage(KEY_DYNAMIC_BUNDLE_DATA_SESSION) || {};

  const newBundleDataSession = {
    ...oldBundleDataSession,
    [bundle.widgetId]: {
      widgetId: bundle.widgetId,
      bundle_type: bundle.bundle_type,
      recommendationType: bundle.recommendation_type,
      primary_algorithm: bundle.primary_algorithm || '',
      secondary_algorithm: bundle.secondary_algorithm || '',
      amountValueDisplay: bundle.amountValueDisplay || '',
      totalBundlePrice: bundle.totalBundlePrice || 0,
      bundleProductIds: bundle?.products?.map((product) => product.id),
    },
  };

  setSessionStorage(KEY_DYNAMIC_BUNDLE_DATA_SESSION, newBundleDataSession);

  return bundle;
};

export const createDivBundleModal = (context) => {
  const idModalByBlock = `${PRE_FIX_CLASS_DYNAMIC_BUNDLE_MODAL}${context.blockType}`;

  let bundleModal = document.getElementById(idModalByBlock);

  if (!bundleModal) {
    const dynamicModal = document.createElement('div');
    dynamicModal.id = idModalByBlock;
    dynamicModal.className = 'boost-sd__modal';
    dynamicModal.setAttribute('ariaModal', 'true');
    dynamicModal.setAttribute('role', 'dialog');
    dynamicModal.setAttribute('ariaLabel', 'modal');

    const backdrop = document.createElement('div');
    backdrop.className = 'boost-sd__modal-backdrop';

    const modalContainer = document.createElement('div');
    modalContainer.className = 'boost-sd__modal-container';
    modalContainer.style.maxWidth = '1000px';

    const modalWrapper = document.createElement('div');
    modalWrapper.className = 'boost-sd__modal-wrapper';
    modalWrapper.setAttribute('tabIndex', '-1');

    modalContainer.appendChild(modalWrapper);

    dynamicModal.appendChild(backdrop);
    dynamicModal.appendChild(modalContainer);

    dynamicModal.style.display = 'none';

    document.body.appendChild(dynamicModal);

    bundleModal = dynamicModal;
  }

  // add event for bundle
  bundleModal?.addEventListener('click', function (e) {
    const target = e.target;
    initBasicEventsForDynamicBundle(context, target, e);

    // add Bundle to cart
    if (target.closest(BUTTON_ADD_BUNDLE_TO_CART_SELECTOR)) {
      addBundleToCart(context, target, context.document);
    }

    // click outside dynamic bundle or click button close dynamic bundle then close
    if (
      (!target.closest(RECOMMENDATION_SELECTOR) && !target?.closest('.boost-sd__product-bundle')) ||
      target.closest('.boost-sd__modal-close-btn--dynamic-bundle')
    ) {
      closeDynamicBundleModal(context);
    }
  });
};

export const initDynamicBundle = (context) => {
  if (!isValidateDynamicBundle(context)) return;

  const isBlockShowModal = context.blockType === 'instantSearch';

  if (isBlockShowModal) {
    registerEventBundleModal(context, context.document);
  }
};

const renderOneBundleByTargetElement = async (context, targetBundlePage, bundle) => {
  const dataDynamicBundle = dynamicBundleData(
    context,
    bundle ? bundle : context?.dynamicBundles[0],
    {},
    BUNDLE_ACTION.initData
  );
  const templateDynamicBundle = await getDynamicBundleWidgetTemplate(context);

  if (targetBundlePage) {
    targetBundlePage.innerHTML = await context.templateRender(templateDynamicBundle, {
      bundle: dataDynamicBundle,
      ...getDynamicBundleSettingsByWidgetId(context, dataDynamicBundle?.widgetId),
    });

    registerEventBundle(context, targetBundlePage);
  }
};

export const renderRecommendationForSearchPage = async (context) => {
  const domDynamicBundleSearchPage = document?.getElementById(
    DYNAMIC_BUNDLE_ID_CONTAINER_SEARCH_PAGE
  );

  if (domDynamicBundleSearchPage) {
    if (context?.dynamicBundles?.length > 0) {
      // Can use customize settings trigger for multiple bundles
      if (window.boostSdMultipleBundle) {
        // reset element prevent push multiple when filter tree call two 2 times in first load.
        domDynamicBundleSearchPage.innerHTML = '';

        context?.dynamicBundles.forEach((bundle) => {
          if (bundle?.bundle_display_type === 'bottom' || !bundle?.bundle_display_type) {
            const targetBundlePage = document.createElement('div');
            targetBundlePage.id = `boost-sd__bundle-search-page-${bundle.widgetId}-${generateUuid()}`;
            domDynamicBundleSearchPage?.appendChild(targetBundlePage);

            renderOneBundleByTargetElement(context, targetBundlePage, bundle);
          }
        });
      } else {
        const bundleBottom = context?.dynamicBundles?.find(
          (bundle) => bundle?.bundle_display_type === 'bottom' || !bundle?.bundle_display_type
        );

        bundleBottom &&
          renderOneBundleByTargetElement(context, domDynamicBundleSearchPage, bundleBottom);
      }
    }
  }

  const domEmbeddedBundleProductList = document?.getElementById(
    EMBEDDED_BUNDLE_ID_CONTAINER_PRODUCT
  );

  const bundleTypeProduct = context?.dynamicBundles?.find(
    (bundle) => bundle?.bundle_display_type === 'product'
  );

  if (domEmbeddedBundleProductList && bundleTypeProduct) {
    const dataDynamicBundle = dynamicBundleData(
      context,
      bundleTypeProduct,
      {},
      BUNDLE_ACTION.initData
    );

    const templateEmbeddedBundle = await getDynamicBundleWidgetTemplate(
      context,
      BUNDLE_NAME_TEMPLATE.embedded
    );

    domEmbeddedBundleProductList.innerHTML = await context.templateRender(templateEmbeddedBundle, {
      bundle: dataDynamicBundle,
      ...getDynamicBundleSettingsByWidgetId(context, dataDynamicBundle?.widgetId),
    });

    registerEventBundleModal(context, domEmbeddedBundleProductList);
    setUniformProductImageHeight(
      context,
      context.app.templateSettings?.themeSettings?.productList?.productsPerRowOnMobile || 2,
      context.app.templateSettings?.themeSettings?.productList?.productsPerRowOnDesktop || 3,
      context.app.generalSettings?.breakpointmobile || 575,
      context.app.generalSettings?.breakpointtabletportraitmax || 991,
      3
    );
  }
};

export const renderPredictiveBlocksForProductPage = async (context, productId) => {
  context.widgetId = 'bc-predictive-bundle';
  const domPredictiveBundles = document.querySelectorAll(PREDICTIVE_BUNDLE_BLOCK_SELECTOR);

  if (domPredictiveBundles?.length === 0) return;

  const getDynamicBundles = await predictiveBundleByProductId(context, productId);

  if (getDynamicBundles?.length === 0) return;

  if (window.boostSdMultipleBundle === true) {
    domPredictiveBundles.forEach((domPredictiveBundle) => {
      getDynamicBundles.forEach((bundle) => {
        const targetBundle = document.createElement('div');
        targetBundle.id = `boost-sd__bundle-predictive-${bundle.widgetId}-${generateUuid()}`;
        domPredictiveBundle?.appendChild(targetBundle);
        renderOneBundleByTargetElement(context, targetBundle, bundle);
      });
    });
  } else {
    getDynamicBundles[0].currentProductId = productId;

    const dataDynamicBundle = dynamicBundleData(
      context,
      getDynamicBundles[0],
      {},
      BUNDLE_ACTION.initData
    );

    const templateDynamicBundle = await getDynamicBundleWidgetTemplate(context);

    const domBundleInnerHtml = await context.templateRender(templateDynamicBundle, {
      bundle: dataDynamicBundle,
      ...getDynamicBundleSettingsByWidgetId(context, dataDynamicBundle?.widgetId),
    });

    domPredictiveBundles?.forEach((domBundle) => {
      // bind event for each bundle
      domBundle?.addEventListener('click', function (event) {
        const target = event.target;
        initBasicEventsForDynamicBundle(context, target, event, domBundle);

        // add to cart Bundle
        if (target.closest(BUTTON_ADD_BUNDLE_TO_CART_SELECTOR)) {
          addBundleToCart(context, target);
        }
      });

      domBundle.innerHTML = domBundleInnerHtml;
    });
  }
};

export const handleClickEmbeddedBundle = (context, target) => {
  const widgetId = target?.closest('.boost-sd__product-bundle')?.getAttribute('data-widget-id');
  widgetId && openDynamicBundleModal(context, widgetId);
};
