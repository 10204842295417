import { getThemeSettings, handleClickOutside, safeParseJSON } from '../utils';

export const handleProductSwatches = (context) => {
  const swatches = getEnabledSwatches(context);

  // Get all product items of a block
  const productItems = Array.from(
    context.document?.querySelectorAll(
      '.boost-sd__product-item, .boost-sd__product-item-list-view-layout'
    )
  );

  productItems?.forEach((productItem, i) => {
    // Get all product swatches, ignore the swatch from popup selection - quick add to cart
    const productSwatches = Array.from(
      productItem?.querySelectorAll('.boost-sd__product-swatch') || []
    ).filter((swatch) => !swatch.closest('.boost-sd__popup-select-option'));

    const { images, options_with_values } =
      safeParseJSON(productItem.getAttribute('data-product')) || {};

    productSwatches?.forEach((swatch, index) => {
      // hover | click | none
      const changeProductImageOn = swatches[index]?.changeProductImageOn || 'hover';

      if (changeProductImageOn === 'none') return;

      const event = changeProductImageOn === 'hover' ? 'mouseenter' : 'click';

      if (event === 'mouseenter') handleMouseLeave(swatch, productItem, images);

      const swatchOptions = swatch?.querySelectorAll('.boost-sd__product-swatch-option');

      swatchOptions?.forEach((option) =>
        registerSwatchOptionEvent(event, productItem, swatch, option, options_with_values, images)
      );
    });
  });
};

/**
 * Get enabled swatches from themeSettings
 */
const getEnabledSwatches = (context) => {
  const productInfoElements = getThemeSettings(context)?.productItems?.productInfo?.elements || {};

  const swatches = [];

  ['swatches.1', 'swatches.2', 'swatches.3'].forEach((swatchNumber) => {
    if (swatchNumber in productInfoElements && productInfoElements[swatchNumber].isEnable) {
      swatches.push(productInfoElements[swatchNumber]);
    }
  });

  return swatches;
};

/**
 * Set product image to default
 */
const setProductImageToDefault = (productItem, images) => {
  const productImages = productItem.querySelectorAll('.boost-sd__product-image img');
  const [firstImage, secondImage] = images;
  const hasSecondImage = images.length > 1;

  const setProductImage = (index, image) => {
    // Only set product image if the new image is not the same as the current image to avoid flickering
    if (!productImages?.[index]?.src?.includes(image?.src)) {
      productImages[index].src = image.src;
      productImages[index].srcset = image.src;
    }
  };

  setProductImage(0, firstImage);
  setProductImage(1, hasSecondImage ? secondImage : firstImage);
};

/**
 * Handle mouse leave event of swatch option
 */
const handleMouseLeave = (swatch, productItem, images) => {
  swatch?.addEventListener('mouseleave', () => {
    if (window.boostSDKeepVariantOnHoverOutside) return;

    const previousLabel = swatch?.querySelector('.boost-sd__radio-label--selected');

    previousLabel?.classList.remove('boost-sd__radio-label--selected');
    setProductImageToDefault(productItem, images);
  });
};

/**
 * Register click or hover event for swatch option
 */
const registerSwatchOptionEvent = (
  event,
  productItem,
  swatch,
  option,
  options_with_values,
  images
) => {
  let cleanupOnClickOutside;

  option?.addEventListener(event, () => {
    handleSwatchOption({ productItem, swatch, option, options_with_values, images });

    if (event === 'click') {
      if (typeof cleanupOnClickOutside === 'function') cleanupOnClickOutside();

      const callback = (target) => {
        if (window.boostSDKeepVariantOnClickOutside) return;

        const previousLabel = swatch?.querySelector('.boost-sd__radio-label--selected');
        previousLabel?.classList.remove('boost-sd__radio-label--selected');

        // Set product image to default only if clicked target is not a swatch option
        if (!target?.closest('.boost-sd__radio-label')) {
          setProductImageToDefault(productItem, images);
        }
      };

      cleanupOnClickOutside = handleClickOutside(swatch, callback);
    }
  });

  // Add keyboard event listeners
  option?.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleSwatchOption({ productItem, swatch, option, options_with_values, images });
    }
  });
};

/**
 * Handle swatch option including set corresponding product variant image and selected label
 */
const handleSwatchOption = ({ productItem, swatch, option, options_with_values, images }) => {
  const swatchType = option
    .querySelector('.boost-sd__radio-input')
    ?.getAttribute('data-swatch-type');
  const variantOption = options_with_values?.find(
    (option) => option.name === swatchType || option.original_name === swatchType
  );

  const setProductImage = (position) => {
    const image = images?.find((item) => item.position === position)?.src;

    const productImages = productItem.querySelectorAll('.boost-sd__product-image img');
    productImages.forEach((img) => {
      if (img.src !== image) {
        img.src = image;
        img.srcset = image;
      }
    });
  };

  const setSelectedLabel = (label) => {
    // Remove previous selected
    const previousLabel = swatch?.querySelector('.boost-sd__radio-label--selected');
    previousLabel?.classList.remove('boost-sd__radio-label--selected');

    // Mark as selected
    label?.classList.add('boost-sd__radio-label--selected');
  };

  if (variantOption) {
    const radioInput = option.querySelector('.boost-sd__radio-input');
    const radioInputValue = radioInput?.getAttribute('value');
    const variantValue = variantOption?.values?.find((value) => value.title === radioInputValue);

    if (variantValue?.image) {
      setProductImage(variantValue.image);
    } else {
      // Variant option has no image, fall back to first and second of images
      setProductImageToDefault(productItem, images);
    }
  }

  setSelectedLabel(option.querySelector('.boost-sd__radio-label'));
};
