import { callFilterRequest } from '../api/filter.js';
import { detectDeviceByWidth, isMobile } from '../utils';

export const handleViewAsClick = async (context, target) => {
  if (target.classList.contains('boost-sd__view-as-icon')) {
    context.defaultParams.viewAs = target.getAttribute('data-value');
  } else {
    context.defaultParams.viewAs = target
      .closest('.boost-sd__view-as-icon')
      .getAttribute('data-value');
  }

  const isMobileSize = isMobile(context.app.generalSettings.breakpointmobile || 575);
  context.defaultParams.device = isMobileSize ? detectDeviceByWidth() : '';

  const [getActiveViewAsButton, setActiveViewAsButton] = context.useContextState(
    'activeViewAsButton',
    null
  );

  setActiveViewAsButton(context.defaultParams.viewAs);

  context.render(() => {
    const activeButton = document.querySelector(
      `.boost-sd__view-as-icon[data-value="${getActiveViewAsButton()}"]`
    );

    if (activeButton) {
      activeButton.focus();
    }
  });

  return await callFilterRequest(context);
};
