import { debounce, simpleMd5, debug, stripHtml, xssFilterOption } from '../utils'; // simpleMd5
import { getTemplate } from '../template';
import { FILTER_OPTION_SELECTOR } from '../constants/filter-tree';
import { getSelectedKeysFilter } from '.';

export function renderOptionMultiLevelTag(context, dom, option, limit) {
  if (!dom) return;
  const selectedKeys = getSelectedKeysFilter(context);

  const classNameUnselected = `boost-sd__filter-option-item-button boost-sd__filter-option-item-button--as-button ${
    option.selectType === 'multiple' ? 'boost-sd__filter-option-item-button--with-checkbox' : ''
  }`;
  const classNameSelected = classNameUnselected + ' boost-sd__filter-option-item-button--selected';

  let html = '';
  const size = limit && option.values.length > limit ? limit : option.values.length;
  const values = xssFilterOption(option, size);
  const label = option.label;
  for (const value of values) {
    const actionId = simpleMd5(`${option.filterOptionId}-${value.tag}`);
    let className = classNameUnselected;
    if (selectedKeys[`${option.filterOptionId}-${value.tag?.toLowerCase()}`]) {
      className = classNameSelected;
    }

    const metaData = {
      action: {
        optionList: {
          key: option.filterOptionId,
          value: stripHtml(value.tag.replace("'", '&apos;').replace('"', '&quot;')),
          label: option.label,
          filterType: option.filterType,
          selectType: option.selectType,
          valueDisplay: value.displayName || '',
        },
      },
    };

    if (value.subTags?.length > 0) {
      const actionIdArrow = simpleMd5(`${option.filterOptionId}-arrow-${value.tag}`);
      const targetIdList = simpleMd5(`${option.filterOptionId}-target-${value.tag}`);
      const targetIdArrow = simpleMd5(`${option.filterOptionId}-target-arrow-${value.tag}`);

      value.actionIdArrow = actionIdArrow;
      value.targetIdList = targetIdList;
      value.targetIdArrow = targetIdArrow;

      value.metaDataArrow = {
        action: {
          expandCollapseMultiLevel: {
            actionId: actionIdArrow,
            targets: [
              {
                targetId: targetIdList,
                classToggle: 'boost-sd__g-hide',
                shouldAddClass: true,
              },
              {
                targetId: targetIdArrow,
                classToggle: 'boost-sd__arrow-icon--down',
                shouldAddClass: false,
              },
            ],
          },
        },
      };

      value.subTags.forEach((_subTag) => {
        // assign actionId and metadata level2
        _subTag.actionId = simpleMd5(`${option.filterOptionId}-${_subTag.tag}`);
        _subTag.targetId = simpleMd5(`${option.filterOptionId}-target-${_subTag.tag}`);

        _subTag.metaData = {
          action: {
            optionList: {
              key: option.filterOptionId,
              value: stripHtml(_subTag.tag.replace("'", '&apos;').replace('"', '&quot;')),
              label: option.label,
              filterType: option.filterType,
              selectType: option.selectType,
              valueDisplay: _subTag.displayName || '',
            },
          },
        };

        _subTag.className = classNameUnselected;
        if (selectedKeys[`${option.filterOptionId}-${_subTag.tag?.toLowerCase()}`]) {
          _subTag.className = classNameSelected;
        }

        if (_subTag.subTags?.length > 0) {
          const actionIdArrow = simpleMd5(`${option.filterOptionId}-arrow-level2-${_subTag.tag}`);
          const targetIdList = simpleMd5(`${option.filterOptionId}-target-level2-${_subTag.tag}`);
          const targetIdArrow = simpleMd5(
            `${option.filterOptionId}-target-arrow-level2-${_subTag.tag}`
          );

          _subTag.actionIdArrow = actionIdArrow;
          _subTag.targetIdList = targetIdList;
          _subTag.targetIdArrow = targetIdArrow;

          _subTag.metaDataArrow = {
            action: {
              expandCollapseMultiLevel: {
                actionId: actionIdArrow,
                targets: [
                  {
                    targetId: targetIdList,
                    classToggle: 'boost-sd__g-hide',
                    shouldAddClass: true,
                  },
                  {
                    targetId: targetIdArrow,
                    classToggle: 'boost-sd__arrow-icon--down',
                    shouldAddClass: false,
                  },
                ],
              },
            },
          };

          _subTag.subTags.forEach((_subTag2) => {
            // assign actionId and metadata level2
            _subTag2.actionId = simpleMd5(`${option.filterOptionId}-${_subTag2.tag}`);
            _subTag2.targetId = simpleMd5(`${option.filterOptionId}-target-${_subTag2.tag}`);
            _subTag2.metaData = {
              action: {
                optionList: {
                  key: option.filterOptionId,
                  value: stripHtml(_subTag2.tag.replace("'", '&apos;').replace('"', '&quot;')),
                  label: option.label,
                  filterType: option.filterType,
                  selectType: option.selectType,
                  valueDisplay: _subTag2.displayName || '',
                },
              },
            };

            _subTag2.className = classNameUnselected;
            if (selectedKeys[`${option.filterOptionId}-${_subTag2.tag?.toLowerCase()}`]) {
              _subTag2.className = classNameSelected;
            }
          });
        }
      });
    }

    html += context.templateRender(getTemplate(context).filterOptionMultiLevelTagTemplate, {
      option,
      actionId,
      metaData,
      value,
      label,
      className,
      simpleMd5,
    });
  }
  dom.innerHTML = html;

  // create custom events - after render
  const renderFiltered = new CustomEvent('afterRenderHtmlForFilter');
  window.dispatchEvent(renderFiltered);
}

export function lazyLoadOptionMultiLevelTag(context, dom, id, option) {
  const domMouseMove = dom?.closest(FILTER_OPTION_SELECTOR);

  const mousemoveRender = debounce(() => {
    debug(context);
    renderFullOptionMultiLevelTag(context, dom, id, option);

    domMouseMove.removeEventListener('mousemove', mousemoveRender);
    domMouseMove.removeEventListener('touchstart', mousemoveRender);
  }, 200);

  domMouseMove && domMouseMove.addEventListener('mousemove', mousemoveRender);
  domMouseMove && domMouseMove.addEventListener('touchstart', mousemoveRender);
}

/**
 * render full option Multi Level Tag
 * @param context
 * @param dom
 * @param id
 * @param option
 */
export const renderFullOptionMultiLevelTag = (context, dom, id, option) => {
  if (
    context.filterTreeViewPort &&
    context.filterTreeViewPort[id] &&
    context.filterTreeViewPort[id].loaded < context.filterTreeViewPort[id].total &&
    context.filterTreeViewPort[id].displayType === 'list'
  ) {
    renderOptionMultiLevelTag(context, dom, option, context.filterTreeViewPort[id].total);
    context.filterTreeViewPort[id] = {
      loaded: context.filterTreeViewPort[id].total,
      total: option.values.length,
      displayType: 'list',
    };
  }
};
