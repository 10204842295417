import {
  debounce,
  simpleMd5,
  debug,
  scrollToTop,
  appendQueryParamHistory,
  setQueryParamHistory,
  isMobile,
  formatFromToKey,
  formatPriceLabel,
  xssFilterOption,
  formatPercentSaleLabel,
  getLocalStorage,
} from '../utils';
import {
  clearFilter,
  getFilterSettings,
  getFilterTreeParent,
  getSelectedKeysFilter,
  isEnterOrSpaceKeyPressed,
} from './index.js';
import { resetFilterRequest } from '../api/filter.js';
import { getTemplate } from '../template';
import { addFilterBoxSelectedClass, removeFilterBoxSelectedClass } from './option-box.js';
import { addFilterSwatchSelectedClass, removeFilterSwatchSelectedClass } from './option-swatch.js';
import { FILTER_OPTION_SELECTOR, COLLECTION_ALL_STORAGE_KEY } from '../constants/filter-tree.js';
import { optionCollection } from './option-collection.js';

const SELECTED_CLASS = 'boost-sd__filter-option-item-button--selected';

/**
 * Renders the option list for a filter option.
 * Takes in context, dom element to render to, the filter option,
 * and an optional limit on the number of values to render initially.
 * Renders the option values as a list of buttons
 * with metadata for selecting that option.
 */
export function renderOptionList(context, dom, option, limit) {
  if (!dom) return;
  if (option.values.length == 0) return;
  const { precisionFormatLabelPriceList } = getFilterSettings(context);

  const selectedKeys = getSelectedKeysFilter(context);
  if (context.app.generalSettings.collection_id) {
    selectedKeys[`${option.filterOptionId}-${context.app.generalSettings.collection_id}`] = true;
  }

  const classNameUnselected = `boost-sd__filter-option-item-button boost-sd__filter-option-item-button--as-button ${
    option.selectType === 'multiple' ? 'boost-sd__filter-option-item-button--with-checkbox' : ''
  }`;
  const classNameSelected = classNameUnselected + ' boost-sd__filter-option-item-button--selected';

  let html = '';
  const size = limit && option.values.length > limit ? limit : option.values.length;

  const [getActionMapping, setActionMapping] = context.useContextState('actionMapping', {});
  const actionMapping = getActionMapping() || {};
  let values = xssFilterOption(option, size);
  const label = option.label;

  // export customize boostCustomSortValues
  if (typeof window.boostCustomSortOptionValues === 'function') {
    values = window.boostCustomSortOptionValues(option, size);
  }

  const { showFilterOptionCount } = getFilterSettings(context);
  let showDocCount = showFilterOptionCount;

  if (option.filterType === 'collection') {
    // if enable settings show All in collection option
    if (option.activeCollectionAll) {
      const getIdCollectionAll = getLocalStorage(COLLECTION_ALL_STORAGE_KEY) || 0;
      const valueCollectionAll = window.boostSdCustomCollectionALl || {
        key: getIdCollectionAll,
        label: 'All',
        displayName: 'All',
        handle: 'all',
      };

      values?.unshift(valueCollectionAll);
    }

    // Keep filter option unchanged
    if (option.keepValuesStatic) {
      showDocCount = false;
    }
  }

  for (const value of values) {
    let className = classNameUnselected;

    if (['percent_sale', 'price', 'variants_price'].includes(option.filterType)) {
      if (!value.from) value.from = 0;

      value.key = formatFromToKey(value.from, value.to);
    }

    const itemKey = `${option.filterOptionId}-${value.key?.toString()}`?.toLowerCase();

    if (selectedKeys[itemKey]) {
      className = classNameSelected;
    }

    if (!value.doc_count) {
      value.doc_count = 0;
    }

    value.label = value.label || value.key;

    if (['price', 'variants_price'].includes(option.filterType)) {
      value.label = formatPriceLabel(context, value.to, value.from, precisionFormatLabelPriceList);
    }

    if (option.filterType === 'percent_sale') {
      value.label = formatPercentSaleLabel(context, value.from, value.to);
    }

    const actionId = simpleMd5(`${option.filterOptionId}-${value.key}`);
    const dataAction = `${option.filterOptionId}.${actionId}`;

    const action = {
      optionList: {
        key: option.filterOptionId,
        value: value.key,
        label: option.label,
        filterType: option.filterType,
        selectType: option.selectType,
        displayType: option.displayType,
        valueDisplay: value.label || '',
        handle: value.handle || '',
      },
    };

    // assign mapping event
    actionMapping[option.filterOptionId] = actionMapping[option.filterOptionId] || {};
    actionMapping[option.filterOptionId][actionId] = action;

    html += context.templateRender(getTemplate(context).filterOptionListTemplate, {
      actionId,
      dataAction,
      value,
      label,
      className,
      showDocCount: value.handle === 'all' ? false : showDocCount,
      displayAllValuesInUppercaseForm: option.displayAllValuesInUppercaseForm,
    });
  }

  setActionMapping(actionMapping);
  dom.innerHTML = html;

  // create custom events - after render
  const renderFiltered = new CustomEvent('afterRenderHtmlForFilter');
  window.dispatchEvent(renderFiltered);
}

export function optionList(context, action) {
  const { filterLayout, requestInstantly } = getFilterSettings(context);
  let isCalled = true;

  // option list but filterType = collection => use optionCollection
  if (action?.filterType === 'collection') {
    return optionCollection(context, action);
  }

  const horizontalHasApplyButton =
    filterLayout === 'horizontal' &&
    !requestInstantly &&
    !isMobile(context.app.generalSettings.isTabletPortraitMax);

  if (horizontalHasApplyButton) {
    const [getItemSelecting, setItemSelecting] = context.useContextState(
      'filterOptionItemSelecting',
      {}
    );

    const _getItemSelecting = getItemSelecting();

    if (!_getItemSelecting[`${action.key}-${action.value}`]) {
      _getItemSelecting[`${action.key}-${action.value}`] = true;
    } else {
      delete _getItemSelecting[`${action.key}-${action.value}`];
    }

    setItemSelecting(_getItemSelecting);
    isCalled = false;
  }

  !isEnterOrSpaceKeyPressed(context) && isCalled && scrollToTop({ isFilter: true });

  const { key, value, selectType } = action;
  const [getFilter] = context.useContextState('filter', []);
  const filterTree = getFilter();

  if (
    filterTree
      .filter((filter) => filter && filter.data)
      .some((filter) => filter.data.key === key && filter.data.value === value)
  ) {
    clearFilter(context, action);
    return;
  }

  if (selectType === 'multiple') {
    addOptionList(context, action);
  } else {
    setOptionList(context, action);
  }

  isCalled && resetFilterRequest(context);

  renderSelectedOption(context);
}

/**
 * add more option selected to filter
 * @param context
 * @param action
 */
const addOptionList = (context, action) => {
  const { key, value, displayType } = action;
  const [getFilter, setFilter] = context.useContextState('filter', []);
  const filterTree = getFilter();

  addFilterSelectedClass(context, key, value, displayType);
  const newFilterTree = [...filterTree, generateFilterDataForList(context, key, value, action)];

  setFilter(newFilterTree);
  appendQueryParamHistory(key, value);
};

/**
 * replace option selected in filter, use for single selection filter
 * @param context
 * @param action
 */
const setOptionList = (context, action) => {
  const { key, value, displayType } = action;
  const [getFilter, setFilter] = context.useContextState('filter', []);
  const filterTree = getFilter();
  filterTree
    .filter((filter) => filter.data.key === key)
    .forEach((filter) => {
      removeFilterSelectedClass(context, filter.data.key, filter.data.value, displayType);
    });
  const newFilterTree = filterTree.filter((filter) => filter.data.key !== key);
  addFilterSelectedClass(context, key, value, displayType);
  newFilterTree.push(generateFilterDataForList(context, key, value, action));

  setFilter(newFilterTree);
  setQueryParamHistory(key, value);
};

/**
 * Lazily loads more option values as the user scrolls.
 * Uses the filterTreeViewPort context to track loaded vs total options.
 * Adds mousemove listener to load more when scrolled into view.
 */
export function lazyLoadOptionList(context, dom, id, option) {
  const domMouseMove = dom?.closest(FILTER_OPTION_SELECTOR);

  const mousemoveRender = debounce(() => {
    debug(context);
    renderFullOptionList(context, dom, id, option);

    domMouseMove.removeEventListener('mousemove', mousemoveRender);
    domMouseMove.removeEventListener('touchstart', mousemoveRender);
  }, 200);

  domMouseMove && domMouseMove.addEventListener('mousemove', mousemoveRender);
  domMouseMove && domMouseMove.addEventListener('touchstart', mousemoveRender);
}

/**
 * render full option list
 * @param context
 * @param dom
 * @param id
 * @param option
 */
export const renderFullOptionList = (context, dom, id, option) => {
  if (
    context.filterTreeViewPort &&
    context.filterTreeViewPort[id] &&
    context.filterTreeViewPort[id].loaded < context.filterTreeViewPort[id].total &&
    context.filterTreeViewPort[id].displayType === 'list'
  ) {
    renderOptionList(context, dom, option, context.filterTreeViewPort[id].total);
    context.filterTreeViewPort[id] = {
      loaded: context.filterTreeViewPort[id].total,
      total: option.values.length,
      displayType: 'list',
    };
  }
};

/**
 * Renders the selected options in the filter tree UI.
 * finds options with "selected" status, and adds a selected class to their DOM elements.
 */
export function renderSelectedOption(context) {
  const [getFilter] = context.useContextState('filter', []);
  const selectedFilters = getFilter() || [];

  selectedFilters
    .filter((selected) => selected && selected.data && selected.data.key)
    .forEach((selected) =>
      addFilterSelectedClass(
        context,
        selected.data.key,
        selected.data.value,
        selected.metaData?.type
      )
    );
}

export const addFilterSelectedClass = (context, key, value, displayType = 'list') => {
  if (displayType === 'box') {
    return addFilterBoxSelectedClass(context, key, value);
  }
  if (displayType === 'swatch') {
    return addFilterSwatchSelectedClass(context, key, value);
  }

  addFilterListSelectedClass(context, key, value);
};

export const removeFilterSelectedClass = (context, key, value, displayType) => {
  if (displayType === 'box') {
    return removeFilterBoxSelectedClass(context, key, value);
  }
  if (displayType === 'swatch') {
    return removeFilterSwatchSelectedClass(context, key, value);
  }

  removeFilterListSelectedClass(context, key, value);
};

export const addFilterListSelectedClass = (context, key, value) => {
  const actionId = simpleMd5(`${key}-${value}`);
  const domElement = context.$(`#${actionId}`, getFilterTreeParent(context));
  domElement && domElement.classList.add(SELECTED_CLASS);
};

export const removeFilterListSelectedClass = (context, key, value) => {
  const actionId = simpleMd5(`${key}-${value}`);
  const domElement = context.$(`#${actionId}`, getFilterTreeParent(context));
  domElement && domElement.classList.remove(SELECTED_CLASS);
};

export const generateFilterDataForList = (context, key, value, filterOption) => {
  if (['variants_price', 'price'].includes(filterOption.filterType)) {
    const [from, to] = value.split(':');
    filterOption.valueDisplay = formatPriceLabel(context, to, from);
  }

  if (filterOption.filterType === 'percent_sale') {
    const [from, to] = value.split(':');
    filterOption.valueDisplay = formatPercentSaleLabel(context, Number(from) || 0, to);
  }

  return {
    data: {
      label: filterOption.label,
      value,
      key,
      valueDisplay: filterOption.valueDisplay || value,
      selectType: filterOption.selectType,
    },
    metaData: { key, value, type: filterOption.displayType },
    type: filterOption.displayType,
  };
};
