export const getThemeSettings = (context) => {
  return context.templateSettings?.themeSettings || {};
};

export const getSearchSettings = (context) => {
  if (!context) {
    console.error('context getSearchSettings null');
    return {};
  }

  let searchSettings =
    context?.templateSettings?.searchSettings || context?.app?.searchSettings || {};

  // Hard code with mobile style
  searchSettings = {
    ...searchSettings,
    suggestionMobileStyle: 'style1',
    suggestionMobileProductItemType: 'grid',
  };
  return searchSettings || {};
};

export const splitGoogleFontStyle = (fontStyle) => {
  if (!fontStyle) {
    return {
      fontWeight: 100,
      fontStyle: 'normal',
    };
  }
  if (fontStyle === 'regular') {
    return {
      fontWeight: 400,
      fontStyle: 'normal',
    };
  }
  const fontWeight = fontStyle.match(/\d+/);
  const fontStyleValue = fontStyle.replace(/\d+/g, '').trim();
  return {
    fontWeight: fontWeight ? fontWeight[0] : 100,
    fontStyle: fontStyleValue || 'normal',
  };
};
