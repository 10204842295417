import { CartAPI } from '../api/cart';
import { getTemplate } from '../template';
import { templateRender } from '../template/templateRender';
import { buildUrlWithLocalization, formatCurrency, setWindowLocation } from '../utils';
import { getThemeSettings } from '../utils/settings';
import { getCartSelectorAndUpdateAction } from './selectors';
import {
  trapFocus,
  focusFirstElement,
  saveFocusElement,
  restoreFocusedElement,
} from '../utils/wcag-modal';
import { KEYS } from '../constants/keys';
import { isKeyboardActivated } from '../utils/keyboardActivated.js';

export const handleCart = (context) => {
  const { enableCart } = getThemeSettings(context)?.cart || {};

  if (!enableCart) return;

  const [getCart, setCart] = context.useContextState('cart', {
    isShow: false,
  });

  const [getForceRender, setForceRender] = context.useContextState('force-render', false);
  const item = getCartSelectorAndUpdateAction();

  if (!item || item?.selector === null) console.error('Cart icon is not found');

  const cartIcon = document.querySelector(item?.selector);

  cartIcon?.addEventListener('click', (e) => {
    e.preventDefault();

    setCart({
      isShow: !getCart().isShow,
    });
  });

  const showCart = () => {
    setCart({ isShow: true });

    if (window.focusedElementBeforeMiniCart === null) {
      window.focusedElementBeforeModalCart = document.activeElement;
    }

    // Use MutationObserver to focus when the modal is fully rendered
    const observer = new MutationObserver((mutations, obs) => {
      const modal = document.querySelector('.boost-sd__modal');
      if (modal) {
        focusFirstElement(modal, 'a[href], button:not([disabled]), input');
        const handleTrapFocus = (e) => {
          trapFocus(
            e,
            modal,
            'a[href], button:not([disabled]), input',
            '.boost-sd__modal-close-btn'
          );
        };

        modal.addEventListener('keydown', handleTrapFocus);

        const closeBtn = document.querySelector('.boost-sd__modal-close-btn');

        function handleCloseBtnKeydown(e) {
          if (KEYS.includes(e.key)) {
            e.preventDefault();
            hideCart();
          }
        }

        closeBtn?.addEventListener('keydown', handleCloseBtnKeydown);

        obs.disconnect(); // Stop observing once the modal is rendered
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  };

  const hideCart = () => {
    setCart({ isShow: false });

    const modal = document.querySelector('.boost-sd__modal');
    if (modal) {
      modal.removeEventListener('keydown', trapFocus);
    }

    // Only return focus if the interaction was via keyboard
    if (isKeyboardActivated()) {
      if (window.focusedElementBeforeMiniCart) {
        window.focusedElementBeforeMiniCart.focus();
        window.focusedElementBeforeMiniCart = null;
      } else if (window.buttonQuickviewFocus) {
        window.buttonQuickviewFocus.focus();
        window.buttonQuickviewFocus = null;
      } else if (window.focusedElementBeforeModalCart) {
        window.focusedElementBeforeModalCart.focus();
        window.focusedElementBeforeModalCart = null;
      }
    }
  };

  const reRenderCart = () => {
    setForceRender(!getForceRender());
  };

  // Listen to custom event to open cart
  window.addEventListener('boost-sd-open-cart', showCart);
  window.addEventListener('boost-sd-close-cart', hideCart);
  window.addEventListener('boost-sd-re-render-cart', reRenderCart);

  // Add event listeners to the cart icon to open the modal on Space and Enter
  const handleCartIconKeydown = (e) => {
    if (KEYS.includes(e.key)) {
      e.preventDefault();
      window.focusedElementBeforeModalCart = document.activeElement;
      showCart();
    }
  };

  cartIcon?.addEventListener('keydown', handleCartIconKeydown);

  context.render(async () => {
    const cart = getCart();

    const { html, itemCount } = await renderCart(context);
    item?.action(itemCount);

    const initOnClickOutSide = (e) => {
      const closeBtn = document.querySelector('.boost-sd__modal-close-btn');
      const cb = () => {
        setCart({
          isShow: false,
        });
      };

      outsideClickListener(document.querySelector('.boost-sd__cart'), e, cb, closeBtn);
    };

    if (cart && cart.isShow) {
      const modal = document.querySelector('.boost-sd__modal');
      if (modal) {
        document.body.removeChild(modal);
      }

      document.body.insertAdjacentHTML('beforeend', html);
      document.body.classList.add('modal-open-cart');

      const cartItems = document.querySelectorAll('.boost-sd__cart-item');
      const cartBtns = document.querySelectorAll('.boost-sd__cart-btn');
      const viewCartBtn = document.querySelector('.boost-sd__btn-view-cart');
      const checkOutBtn = document.querySelector('.boost-sd__btn-checkout');

      const closeBtn = document.querySelector('.boost-sd__modal-close-btn');
      const cartItemCount = document.querySelector('.boost-sd__cart-items-count');

      cartItems?.forEach((cartItem) => {
        const buttons = cartItem.querySelectorAll('.boost-sd__input-number-btn');
        const decreaseQuantityBtn = buttons?.[0];
        const increaseQuantityBtn = buttons?.[1];
        const quantityInput = cartItem.querySelector('.boost-sd__input-number-input');
        const removeItemBtn = cartItem.querySelector('.boost-sd__cart-item-remove-btn');
        const productId = cartItem.getAttribute('data-product-id');

        decreaseQuantityBtn?.addEventListener('click', async () => {
          saveFocusElement(decreaseQuantityBtn); // Save the focused element before the cart update
          await CartAPI.change(productId, Number(quantityInput.value) - 1);
          setForceRender(!getForceRender());
          restoreFocusedElement();
        });
        increaseQuantityBtn?.addEventListener('click', async () => {
          saveFocusElement(increaseQuantityBtn);
          await CartAPI.change(productId, Number(quantityInput.value) + 1);
          setForceRender(!getForceRender());
          restoreFocusedElement();
        });
        removeItemBtn?.addEventListener('click', async () => {
          saveFocusElement(removeItemBtn);
          await CartAPI.change(productId, 0);
          setForceRender(!getForceRender());
          restoreFocusedElement();
        });

        function onlyNumbers(evt) {
          // Prevent non-numeric input
          const charCode = evt?.which || evt?.keyCode;
          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
          }
        }

        quantityInput.addEventListener('keypress', (e) => {
          onlyNumbers(e);
        });

        quantityInput.addEventListener('change', async (e) => {
          const value = e.target.value;

          if (Number.isInteger(Number(value)) && Number(value) > 0) {
            saveFocusElement(quantityInput);
            await CartAPI.change(productId, Number(value));
            setForceRender(!getForceRender());
            restoreFocusedElement();
          }
        });
      });

      viewCartBtn?.addEventListener('click', () => {
        setWindowLocation(buildUrlWithLocalization('cart'));
      });

      checkOutBtn?.addEventListener('click', () => {
        setWindowLocation('/checkout');
      });

      closeBtn?.addEventListener('click', () => {
        hideCart();
      });

      document.querySelector('.boost-sd__modal')?.addEventListener('click', initOnClickOutSide);
    } else {
      const closeBtn = document.querySelector('.boost-sd__modal-close-btn');
      const cartBtns = document.querySelectorAll('.boost-sd__cart-btn');

      if (closeBtn) {
        closeBtn.removeEventListener('click', closeCartModal);
      }
      document
        .getElementById('boost-sd__modal-card')
        ?.removeEventListener('click', initOnClickOutSide);

      document.getElementById('boost-sd__modal-card')?.remove();
      document.body.classList.remove('modal-open-cart');
    }
  }, ['cart', 'force-render']);
};

export const addToCart = async (context, item, target, multiple, onSuccess, onFail) => {
  const [getCart, setCart] = context.useContextState('cart', {
    isShow: false,
  });
  const { productId, quantity = 1 } = item;

  const ctaBtnText = target.querySelector('.boost-sd__cta-button-text, .boost-sd__btn-add-to-cart');
  const element = ctaBtnText || target;

  element.textContent =
    context.app?.translation?.productItem?.atcAddingToCartBtnLabel || 'Adding...';
  element.setAttribute('disabled', 'true');

  let result = {
    status: '',
    response: null,
  };

  try {
    // multiple = true means add multi product to cart - RCM Bundle
    const res = multiple
      ? await CartAPI.addMultiProductToCart(item)
      : await CartAPI.add(productId, quantity);

    if (res.status === 200) {
      element.textContent =
        context.app?.translation?.productItem?.atcAddedToCartBtnLabel || 'Added!';

      const { enableCart, autoOpenAjaxCartOnAddToCart = true } =
        getThemeSettings(context)?.cart || {};
      const { noneCartOption = false } = window.boostWidgetIntegration || {};
      const shouldOpenCart = !noneCartOption && autoOpenAjaxCartOnAddToCart;

      /**
       * Communicate through event because ATC action handler belong to ProductGridFilter or RCM context
       * State from Cart context can't access other contexts and trigger re-render
       */
      if (enableCart) {
        const event = shouldOpenCart ? 'boost-sd-open-cart' : 'boost-sd-re-render-cart';
        window.dispatchEvent(new CustomEvent(event));
      } else {
        setWindowLocation('/cart');
      }

      if (onSuccess) setTimeout(onSuccess, 300);

      result.status = 'success';
    } else {
      element.textContent =
        context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';
      if (onFail) setTimeout(onFail, 300);

      result.status = 'failed';
    }

    result.response = await res.json();
  } catch (error) {
    element.textContent =
      context.app?.translation?.productItem?.atcFailedToCartBtnLabel || 'Failed!';
    if (onFail) setTimeout(onFail, 300);

    result.status = 'failed';
  }

  element.removeAttribute('disabled');

  setTimeout(
    () =>
      (element.textContent =
        context.app?.translation?.productItem?.atcAvailableLabel || 'Add to cart'),
    500
  );

  return result;
};

const outsideClickListener = (element, event, callback, ignore) => {
  if (!element?.contains(event.target) && !ignore?.contains(event.target)) {
    callback(event);
  }
};

const closeCartModal = () => {
  document.getElementById('boost-sd__modal-card')?.remove();
  document.body.classList.remove('modal-open-cart');
};

/* final_price: The price of the line item in the currency's subunit. This includes any line-level discounts.
 * The value is output in the customer's local (presentment) currency.
 */
const getFinalPrice = (item) => {
  return (item.final_price || item.price || 0) / 100;
};

export const renderCart = async (context) => {
  const data = await CartAPI.getAll();

  const {
    cartStyle = 'side',
    generalLayout: { shape = 'round' },
  } = getThemeSettings(context)?.cart || {};

  let html = '';

  data?.items.forEach((item) => {
    item.price = formatCurrency({ context, value: getFinalPrice(item) });
    item.options_with_values = item?.options_with_values?.filter((option) => {
      return option.name !== 'Title' && option.value !== 'Default Title';
    });
  });

  html += context.templateRender(getTemplate(context).cartTemplate, {
    items: data.items,
    item_count: data.item_count,
    items_subtotal_price: formatCurrency({
      context,
      value: (data?.items_subtotal_price || 0) / 100,
    }),
    cartStyle,
    shape,
  });

  return {
    html,
    itemCount: data.item_count,
  };
};
