import {
  buildUrlWithLocalization,
  deleteQueryParamHistory,
  getSessionStorage,
  isCollectionPage,
  scrollToTop,
  setQueryParamHistory,
  setSessionStorage,
  slugify,
} from '../utils/index.js';
import {
  BOOST_SD_COLLECTION_ID_KEY,
  BOOST_SD_COLLECTION_TAGS_KEY,
  FILTER_TAG_KEY,
} from '../constants/filter-tree.js';
import { resetFilterRequest } from '../api/filter.js';
import {
  addFilterSelectedClass,
  removeFilterSelectedClass,
  renderSelectedOption,
} from './option-list.js';
import { clearAllFilter, isEnterOrSpaceKeyPressed } from './index.js';
import { renderCollectionHeader } from '../components/collection-header/index.js';
import { dispatchEventReViewCollection } from '../analytic/index.js';

export function optionCollection(context, action) {
  !isEnterOrSpaceKeyPressed(context) && scrollToTop({ isFilter: true });

  // redirect collection only collection page
  if (isCollectionPage()) {
    redirectCollection(context, action);
  } else {
    // logic search page - still show in refine by
    searchPageCollection(context, action);
  }
}

const saveCollectionTagsSlugKey = (key, value) => {
  const collectionTags = getSessionStorage(BOOST_SD_COLLECTION_TAGS_KEY) || {};
  collectionTags[key] = value;
  setSessionStorage(BOOST_SD_COLLECTION_TAGS_KEY, collectionTags);
};

export const saveCollectionIdByHandle = (handle, value) => {
  const collectionIdsByHandle = getSessionStorage(BOOST_SD_COLLECTION_ID_KEY) || {};
  collectionIdsByHandle[handle] = value;
  setSessionStorage(BOOST_SD_COLLECTION_ID_KEY, collectionIdsByHandle);
};

export const getCollectionsTagFromSlugKey = (key) => {
  const collectionTags = getSessionStorage(BOOST_SD_COLLECTION_TAGS_KEY) || {};
  return collectionTags[key];
};

export const getCollectionIdByHandleKey = (key) => {
  const collectionIds = getSessionStorage(BOOST_SD_COLLECTION_ID_KEY) || {};
  return collectionIds[key] || 0;
};

const redirectCollection = (context, action) => {
  const { key, value, handle, collectionTag } = action;

  addFilterSelectedClass(context, key, value);
  removeFilterSelectedClass(context, key, context.app.generalSettings.collection_id);

  let newUrl = buildUrlWithLocalization(`collections/${handle}`);
  context.app.generalSettings.collection_id = value;
  context.app.generalSettings.collection_handle = handle;

  if (collectionTag) {
    saveCollectionTagsSlugKey(slugify(collectionTag), collectionTag);
    addFilterSelectedClass(context, FILTER_TAG_KEY, collectionTag);

    newUrl = `${newUrl}/${slugify(collectionTag)}`;
    context.app.generalSettings.collection_tags = [collectionTag];
  } else {
    context.app.generalSettings.collection_tags = null;
  }

  history.pushState({}, '', newUrl);
  renderCollectionHeader(context);
  // when redirect set prevent history = true because we has push it above
  clearAllFilter(context, { preventPushHistory: true });

  dispatchEventReViewCollection()
};

const searchPageCollection = (context, action) => {
  const { key, value, handle, collectionTag, displayName, valueDisplay } = action;
  const collectionDisplay = valueDisplay || displayName; // valueDisplay => collection the same level - displayName of multiple collection

  const [getFilterTree, setFilterTree] = context.useContextState('filter', []);
  const filterTree = getFilterTree();

  const oldParams = filterTree.filter(
    (item) => item.data.key !== key && item.data.key !== FILTER_TAG_KEY
  );

  const newFilterTree = [...oldParams];
  const collection = {
    data: {
      label: 'Collection',
      value,
      key,
      valueDisplay: collectionDisplay,
      handle,
    },
    metaData: { key, value },
    type: 'list',
  };

  if (collectionTag) {
    const tag = {
      data: {
        label: 'Collection Tag',
        value: collectionTag,
        key: FILTER_TAG_KEY,
        valueDisplay: collectionTag,
      },
      metaData: { key: FILTER_TAG_KEY, value: collectionTag, dependencies: [{ key, value }] },
      type: 'list',
    };
    collection.metaData.dependencies = [{ key: FILTER_TAG_KEY, value: collectionTag }];
    newFilterTree.push(collection);
    newFilterTree.push(tag);
  } else {
    newFilterTree.push(collection);
  }

  setFilterTree(newFilterTree);
  setQueryParamHistory(key, value);

  if (!collectionTag) {
    deleteQueryParamHistory(FILTER_TAG_KEY, { preventPushHistory: true });
  } else {
    setQueryParamHistory(FILTER_TAG_KEY, collectionTag, { preventPushHistory: true });
  }

  resetFilterRequest(context);
  context.render(() => {
    renderSelectedOption(context);
  }, ['filter', 'filterTree']);
};
