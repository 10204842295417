const RECENT_SEARCHES_KEY = 'boostPFSRecentSearches';

const getOnClickRecentSearches = (maxRecentSearch = 10) => {
  let recentSearches;
  try {
    const value = localStorage.getItem(RECENT_SEARCHES_KEY);
    recentSearches = value && JSON.parse(value);
  } catch {
    recentSearches = [];
  }

  if (!Array.isArray(recentSearches)) {
    recentSearches = [];
  } else {
    if (maxRecentSearch > 0) {
      recentSearches = recentSearches.slice(0, maxRecentSearch);
    }
  }
  const filterRecentSearches = [];
  recentSearches.forEach((recentSearch) => {
    if (recentSearch.title !== '') {
      filterRecentSearches.push(recentSearch);
    }
  });

  return filterRecentSearches;
};

const setOnClickRecentSearches = (item, extraParam = '', scope = '') => {
  // Get current recent searches
  if (typeof item != 'string' || item.trim() === '') return;
  item = item.trim();
  let recentSearches = getOnClickRecentSearches();
  const maxInLocalStorage = 10;

  // Add or move the new search to top
  const index = recentSearches.findIndex(
    (recentSearch) => recentSearch.title === item && recentSearch.extraParam === extraParam
  );
  if (index >= 0) {
    recentSearches.splice(index, 1);
    recentSearches.unshift({ title: item, extraParam, scope });
  } else {
    recentSearches.unshift({ title: item, extraParam, scope });
    recentSearches = recentSearches.slice(0, maxInLocalStorage);
  }
  // Set storage
  try {
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches));
  } catch {}
};

const handleRecentSearchForSuggestionTerm = (event) => {
  if (!event || !event.target) return;
  const target = event.target;
  const clickPopularSuggestion = target?.closest('.boost-sd__suggestion-queries-item--suggestion');
  const clickPopularSearch = target?.closest('.boost-sd__no-search-result-page-suggestion-item');

  if (clickPopularSuggestion) {
    const searchTerm = clickPopularSuggestion
      ?.querySelector('.boost-sd__suggestion-queries-link')
      ?.innerText?.trim();

    if (searchTerm) {
      setOnClickRecentSearches(searchTerm);
    }
  }

  if (clickPopularSearch) {
    setOnClickRecentSearches(clickPopularSearch?.innerText?.trim());
  }
};

export { getOnClickRecentSearches, setOnClickRecentSearches, handleRecentSearchForSuggestionTerm };
