import { CartAPI } from '../api/cart';
import {
  LocalStorageKey,
  addParamsLocale,
  buildProductDetailUrlWithVariant,
  formatCurrency,
  getRecentlyViewedProducts,
  isCartPage,
  isCollectionPage,
  isHomePage,
  isProductPage,
  isSearchPage,
  setLocalStorage,
  setSessionStorage,
  setWindowLocation,
  detectDeviceByWidth,
  splitGoogleFontStyle,
} from '../utils';
import { getTemplateByIds } from '../api/template.js';
import { transformProductPrice, setUniformProductImageHeight } from '../product-item';
import { handleBulkQuantity } from '../bulk-quantity';
import { addToCart, handleCart } from '../cart';
import { SELECTED_PRODUCT_ID } from '../constants/pagination';
import { handleProductSwatches } from '../product-item/index.js';
import { addB2bAPIParams } from '../b2b';
import { handleTooltip } from '../tooltip/index.js';
import { restrictMainCurrency } from '../shopify-market/restrict-user.js';

const MOBILE_BREAKPOINT = 767;
const BUNDLE_LAYOUT = 'bundle';
const CAROUSEL_LAYOUT = 'carousel';
const DROPDOWN_INDEX_ATTRIBUTE = 'boost_sd__dropdown-index';
let recommendInitialized = false;

const lazyloadRecommendWidgets = (context, callback) => {
  const { inViewPortHandler } = window.boostSDTaeUtils;

  inViewPortHandler([context.document.parentElement], (element) => {
    const recElm = element.querySelector("[id^='boost-sd-widget-']");
    if (recElm) {
      recElm.removeAttribute('loading');
      callback();
    }
  });
};

export const handleRecommendation = async (context) => {
  // validate context of recommendation widget info
  const isValid = validateRecommendationWidget(context);
  if (!isValid) {
    context.document.innerHTML = '';
    return;
  }

  // validate add widget into correct page type
  const isValidPage = checkAddWidgetInCorrectPage(context);
  if (!isValidPage) {
    context.document.innerHTML = '';
    return;
  }

  const [getRecommendation] = context.useContextState('recommendation', {});
  const [getLoading, setLoading] = context.useContextState('loading', true);

  lazyloadRecommendWidgets(context, async () => {
    await prepareRecommendWidget(context);

    const { layoutDisplay = '' } = getRecommendWidgetInfo(context).widgetDesignSettings;

    if (layoutDisplay === CAROUSEL_LAYOUT) {
      await initRecommendCarousel();
    } else if (layoutDisplay === BUNDLE_LAYOUT) {
      initRecommendBundle(context);
    }

    setLoading(false);
  });

  context.render(() => {
    // awaiting viewport
    if (getLoading()) return;

    // check data
    const recommendationData = getRecommendation();
    const { products = [] } = recommendationData;

    if (!context.template || products.length < 1) {
      context.document.innerHTML = '';
      return;
    }

    // render template
    const html = context.templateRender(context.template, {
      ...recommendationData,
      request: {
        device: detectDeviceByWidth(),
        money_format: context.app.shop.money_format,
        money_format_with_currency: context.app.shop.money_format_with_currency,
      },
    });
    context.document.innerHTML = html;
    //Generate newId for customize
    const newId = window.boostWidgetIntegration.generateUuid();
    context.state.latestRecommendationRequest = newId;

    transformProductPrice(context);
    handleProductSwatches(context);
    handleTooltip(context);

    // process recommendation event
    processRecommendationEvent(context);

    setUniformProductImageHeight(
      context,
      2,
      getRecommendWidgetInfo(context).widgetDesignSettings.numberOfRecommendProduct,
      MOBILE_BREAKPOINT,
      context.app.generalSettings.breakpointtabletportraitmax || 991,
      getRecommendWidgetInfo(context).widgetDesignSettings.numberOfRecommendProduct
    );

    if (
      context.app.templateSettings?.themeSettings?.productItems?.general?.subLayout ===
      'subLayout_bulk'
    ) {
      handleBulkQuantity(context);
    }
  }, ['loading', 'recommendation']);

  // add event for window
  window.addEventListener('resize', () => {
    const items = document.querySelectorAll('.boost-sd__slider-container .slick-slide');
    items.forEach((item) => {
      item.style.display = 'block';
    });

    calcDropdownPosition(context);
  });

  window.addEventListener('scroll', () => {
    calcDropdownPosition(context);
  });
};

const calcDropdownPosition = (context) => {
  const openedDropdown = document.body.querySelector(getDropdownOpenNodeKey(context.widgetId));
  if (!openedDropdown) return;

  const dropdownBoxs = context.document.querySelectorAll(
    `.boost-sd__recommendation-bundle-item-info-content ${getDropdownNodeKey(context.widgetId)}]`
  );
  if (!dropdownBoxs) return;

  const index = +openedDropdown.getAttribute(DROPDOWN_INDEX_ATTRIBUTE);
  openedDropdown.setAttribute(
    'style',
    buildDropboxStyle(dropdownBoxs[index].getBoundingClientRect())
  );
};

const validateRecommendationWidget = (context) => {
  // If there is no widgetId => do nothing
  if (!context.widgetId || context.widgetId === '') return false;

  // If there is no pageType => do nothing
  const pageType = getPageType(context);
  if (pageType === '') return false;

  // If there is no recommendationWidgets => do nothing
  const recommendationWidgets = context.app?.recommendationWidgets;
  if (!recommendationWidgets || Object.keys(recommendationWidgets).length == 0) return false;

  // If there is no any widget which is created on page by pageType => do nothing
  const widgetsInPage = recommendationWidgets[pageType];
  if (!widgetsInPage || Object.keys(widgetsInPage).length == 0) return false;

  // If there is no widget by widgetId => do nothing
  const widget = widgetsInPage[context.widgetId];
  if (!widget || Object.keys(widget).length == 0) return false;

  // If there is no widgetDesignSettings => do nothing
  const defaultSettings = context.app?.recommendation?.defaultSettings || {};

  const widgetDesignSettings = {
    ...defaultSettings,
    ...widget.widgetDesignSettings,
  };

  if (!widgetDesignSettings || Object.keys(widgetDesignSettings).length == 0) return false;

  // If there is no params of widget => do nothing
  const widgetParams = widget.params;
  if (!widgetParams || Object.keys(widgetParams).length == 0) return false;

  // If there is no templateMetaData => do nothing
  const templateMetaData = context.app?.templateMetadata;
  if (!templateMetaData || Object.keys(templateMetaData).length == 0) return false;

  // Set pageType into context
  context.pageType = pageType;
  return true;
};

const initRecommendCarousel = async () => {
  if (recommendInitialized) return;

  const jquery = await import('jquery');
  window.$ = jquery.default;

  await import('slick-carousel');

  recommendInitialized = true;
};

const initRecommendBundle = (context) => {
  const [getRecommendation, setRecommendation] = context.useContextState('recommendation', {});
  const recommendationData = getRecommendation();
  const { products = [] } = recommendationData;

  let totalPrice = 0;
  for (const product of products) {
    let data = product;
    if (product.variants.length > 0) data = product.variants[0];

    const { price: originPrice = 0, title, id } = data;
    product.selectedOption = { title, originPrice, price: formatPrice(context, originPrice), id };

    if (product.available) {
      product.checked = true;
      totalPrice += +originPrice;
    } else product.checked = false;
  }

  totalPrice = formatPrice(context, totalPrice);
  const hasSelectedProduct =
    products.length > 0 && products.filter((item) => item.available).length > 0;
  setRecommendation({ ...recommendationData, products, totalPrice, hasSelectedProduct });
};

const calcTotalPrice = (products) => {
  let totalPrice = 0;
  for (const product of products) {
    if (product.checked) {
      const { originPrice = 0 } = product.selectedOption;
      totalPrice += +originPrice;
    }
  }
  return totalPrice;
};

const processRecommendationEvent = (context) => {
  const { layoutDisplay = '' } = getRecommendWidgetInfo(context).widgetDesignSettings;

  if (layoutDisplay === CAROUSEL_LAYOUT) {
    processRecommendationCarouselEvent(context);
  } else if (layoutDisplay === BUNDLE_LAYOUT) {
    processRecommendationBundleEvent(context);
  }
};

const processRecommendationCarouselEvent = (context) => {
  const { widgetDesignSettings } = getRecommendWidgetInfo(context);
  const isRtl = document.documentElement.getAttribute('dir') === 'rtl';

  // Init carousel
  const slider = $('.boost-sd__slider-container')
    .not('.slick-initialized')
    .slick({
      dots: true,
      infinite: true,
      slidesToShow: widgetDesignSettings.numberOfProductPerRow || 4,
      slidesToScroll: widgetDesignSettings.numberOfProductPerRow || 4,
      arrows: false,
      draggable: false,
      useTransform: false,
      focusOnSelect: false,
      focusOnChange: false,
      accessibility: false,
      responsive: [
        {
          breakpoint: MOBILE_BREAKPOINT,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            draggable: true,
            focusOnSelect: false,
            focusOnChange: false,
            accessibility: false,
          },
        },
      ],
      rtl: isRtl,
    });

  if (isRtl) {
    $('.boost-sd__slider-container').css({
      direction: 'rtl',
    });

    $('.boost-sd__slider-container .slick-slide').css({
      float: 'right',
    });
  }

  /**
   * updateTabindex() is the function to improve the behavior when navigate carousel by keyboard
   * Updates the tabindex and aria-label attributes of the slider container elements.
   *
   * Specifically, this function:
   * - Sets the aria-label attribute for each non-cloned slide to indicate its position (e.g. "Slide 1 of 5").
   * - Sets the tabindex attribute to 0 for the currently active slide and its focusable child elements.
   * - Sets the tabindex attribute to -1 for all other slides and their focusable child elements.
   *
   * This ensures that the slider is accessible and can be navigated using keyboard navigation.
   */
  const updateTabindex = () => {
    const items = context.document.querySelectorAll('.boost-sd__slider-container .slick-slide');
    const selectors = 'a[href], button:not([disabled]), .boost-sd__radio-label[tabindex]';
    const totalSlides = [...items].filter(
      (item) => !item.classList.contains('slick-cloned')
    ).length;
    let indexOfActiveSlide = 0;

    items.forEach((item) => {
      if (!item.classList.contains('slick-cloned')) {
        indexOfActiveSlide++;
        item.setAttribute('aria-label', `Slide ${indexOfActiveSlide} of ${totalSlides}`);
      }

      const tabIndex = item.classList.contains('slick-active') ? 0 : -1;
      item.tabIndex = tabIndex;
      item.querySelectorAll(selectors).forEach((element) => {
        element.tabIndex = tabIndex;
      });
    });
  };

  const syncSlideFocusAfterChange = () => {
    updateTabindex();
    setTimeout(() => {
      $(`#${context.widgetId} .slick-current`).trigger('focus');
    }, 400);
  };

  updateTabindex();
  slider.on('afterChange', syncSlideFocusAfterChange);

  const carouselButtons = context.document.querySelectorAll('.boost-sd__button--circle');
  carouselButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      const behavior = button.getAttribute('aria-label');
      if (behavior === 'Next') {
        slider.slick('slickNext');
      } else if (behavior === 'Previous') {
        slider.slick('slickPrev');
      }
    });
  });
};

const getDropdownOpenNodeKey = (widgetId) => {
  return `[id="boost-sd__dropdown-list-${widgetId}"].boost-sd__dropdown-option-list--open`;
};

const getDropdownNodeKey = (widgetId) => {
  return `[id="boost-sd__dropdown-${widgetId}"`;
};

const processRecommendationBundleEvent = (context) => {
  const openDropdownClass = 'boost-sd__dropdown-option-list--open';
  const [getRecommendation, setRecommendation] = context.useContextState('recommendation', {});
  const [getOpeningBundleDropdownIdx, setOpeningBundleDropdownIdx] = context.useContextState(
    'openingBundleDropdownIdx',
    -1
  );

  const recommendationData = getRecommendation();
  const { products = [] } = recommendationData;

  // event for dropdown
  const variantDropdowns =
    context.document.querySelectorAll(getDropdownNodeKey(context.widgetId)) || [];

  const productImages =
    context.document.querySelectorAll(
      '.boost-sd__recommendation-bundle-image-item .boost-sd__product-image'
    ) || [];

  for (let pIdx = 0; pIdx < variantDropdowns.length; pIdx++) {
    if (products[pIdx].variants.length < 1 || !products[pIdx].checked) continue;

    const dropdownNode = variantDropdowns[pIdx].querySelector(
      `[id="boost-sd__dropdown-list-${context.widgetId}"`
    );

    // add event open/close for variantDropdowns[pIdx]
    variantDropdowns[pIdx].addEventListener('click', () => {
      if (dropdownNode.classList.contains(openDropdownClass)) {
        dropdownNode.removeAttribute(DROPDOWN_INDEX_ATTRIBUTE);
        dropdownNode.classList.remove(openDropdownClass);
        setOpeningBundleDropdownIdx(-1);
        document.body.removeChild(dropdownNode);
      } else {
        dropdownNode.classList.add(openDropdownClass);
        dropdownNode.setAttribute(DROPDOWN_INDEX_ATTRIBUTE, pIdx);
        dropdownNode.setAttribute(
          'style',
          buildDropboxStyle(variantDropdowns[pIdx].getBoundingClientRect())
        );
        setOpeningBundleDropdownIdx(pIdx);
        document.body.appendChild(dropdownNode);
      }
    });

    // add event select item
    const variantOptionNodes =
      dropdownNode.querySelectorAll(`[id="boost-sd__dropdown-option-${context.widgetId}"`) || [];
    for (let vIdx = 0; vIdx < variantOptionNodes.length; vIdx++) {
      variantOptionNodes[vIdx].addEventListener('click', () => {
        // remove opending dropdown
        const openingDd = document.body.querySelector(`[boost_sd__dropdown-index="${pIdx}"]`);
        openingDd.classList.remove(openDropdownClass);
        document.body.removeChild(openingDd);

        // re-calculate total price
        const { title = '', price: originPrice = 0, id } = products[pIdx]?.variants[vIdx] || {};

        const formattedPrice = formatPrice(context, originPrice);
        products[pIdx].selectedOption = { title, originPrice, price: formattedPrice, id };

        const totalPrice = formatPrice(context, calcTotalPrice(products));
        setRecommendation({ ...recommendationData, products, totalPrice });
      });
    }

    productImages[pIdx].addEventListener('click', () => {
      const { addCollectionToProductUrl, current_tags = [] } = getGeneralSettings(context);

      const { handle, variants, id: productId } = products[pIdx];

      let variantId = '';
      if (variants.length > 0) variantId = variants[0].id;

      const productUrl = buildProductDetailUrlWithVariant(
        context,
        { variants, variant_id: variantId, handle, split_product: '' },
        addCollectionToProductUrl,
        current_tags
      );

      setSessionStorage(SELECTED_PRODUCT_ID, variantId || productId);
      setWindowLocation(productUrl);
    });
  }

  const checkboxs = context.document.querySelectorAll(
    '.boost-sd__recommendation-bundle-checkbox-box'
  );
  const checkedClass = 'boost-sd__recommendation-bundle-checkbox-box--checked';
  for (let cIdx = 0; cIdx < checkboxs.length; cIdx++) {
    checkboxs[cIdx].addEventListener('click', () => {
      if (!products[cIdx].available) return;

      if (checkboxs[cIdx].classList.contains(checkedClass)) {
        products[cIdx].checked = false;
      } else {
        products[cIdx].checked = true;
      }

      const totalPrice = formatPrice(context, calcTotalPrice(products));
      const checkedProducts = products.filter((p) => p.checked);
      const hasSelectedProduct = checkedProducts.length > 0;
      setRecommendation({ ...recommendationData, products, totalPrice, hasSelectedProduct });
    });
  }

  const addToCartBtn = context.document.querySelector('.boost-sd__btn-add-to-cart');
  addToCartBtn.addEventListener('click', () => {
    const data = [];
    for (const product of products) {
      if (!product.checked) continue;

      const { id } = product.selectedOption;
      data.push({ id, quntity: 1 });
    }

    context.handler(addToCart, 'addToCart')(context, data, context.document, true);
  });

  // Check event click outside dropdown and list
  document.addEventListener('click', (e) => {
    const openingBundleDropdownIdx = getOpeningBundleDropdownIdx();
    if (openingBundleDropdownIdx < 0) return;

    const dropdownOpenNodeKey = getDropdownOpenNodeKey(context.widgetId);
    if (
      !e.target.closest(dropdownOpenNodeKey) &&
      !e.target.closest(getDropdownNodeKey(context.widgetId))
    ) {
      const openedDropdown = document.body.querySelector(dropdownOpenNodeKey);
      if (openedDropdown) {
        openedDropdown.removeAttribute(DROPDOWN_INDEX_ATTRIBUTE);
        openedDropdown.classList.remove(openDropdownClass);
        document.body.removeChild(openedDropdown);
      }
    } else {
      const openedDropdowns = document.body.querySelectorAll(dropdownOpenNodeKey);
      for (const openedDropdown of openedDropdowns) {
        const index = openedDropdown.getAttribute(DROPDOWN_INDEX_ATTRIBUTE);
        if (openingBundleDropdownIdx !== +index) {
          openedDropdown.removeAttribute(DROPDOWN_INDEX_ATTRIBUTE);
          openedDropdown.classList.remove(openDropdownClass);
          document.body.removeChild(openedDropdown);
        }
      }
    }
  });
};

// Get Recommendation template
const getRecommendWidgetTemplate = async (context) => {
  const template = context.app?.template?.recommendation || '';
  if (template !== '') {
    context.template = template;
    return;
  }

  const templateId = context.app?.templateMetadata?.recommendation || '';
  const themeNameLib = context.app?.templateMetadata?.themeNameLib || 'default';

  const { templates = {} } = await getTemplateByIds(context, themeNameLib, [
    { name: 'recommendation', id: templateId },
  ]).catch(() => {
    return { templates: {} };
  });
  context.template = templates[templateId];
};

const checkAddWidgetInCorrectPage = (context) => {
  const pageType = getPageType(context);

  if (pageType === '') return false;

  if (pageType === 'home-page' && isHomePage(context)) return true;
  if (pageType === 'collection-page' && isCollectionPage(context)) return true;
  if (pageType === 'product-page' && isProductPage(context)) return true;
  if (pageType === 'cart-page' && isCartPage(context)) return true;

  return false;
};

const getPageType = (context) => {
  const widgetId = context.widgetId;

  if (widgetId.startsWith('homepage')) return 'home-page';
  if (widgetId.startsWith('cartpage')) return 'cart-page';
  if (widgetId.startsWith('collectionpage')) return 'collection-page';
  if (widgetId.startsWith('productpage')) return 'product-page';
  return '';
};

/**
 * get current page
 */
const getCurrentPage = (context) => {
  let currentPage = '';

  switch (true) {
    case isCollectionPage(context):
      currentPage = 'collection_page';
      break;

    case isSearchPage(context):
      currentPage = 'search_page';
      break;

    case isProductPage(context):
      currentPage = 'product_page';
      break;

    case isCartPage(context):
      currentPage = 'cart_page';
      break;

    case isHomePage(context):
      currentPage = 'home_page';
      break;

    default:
      break;
  }

  return currentPage;
};

const getRecommendData = async (context) => {
  const { params: widgetParams } = getRecommendWidgetInfo(context);
  const { recommendationType, limit, shop, layoutDisplay = 'carousel' } = widgetParams;

  const customerId = getCustomerId();
  const collectionId = getCollectionIds(context);
  const productIds = await getProductIds(context);
  if (
    productIds.length === 0 &&
    ['frequently-bought-together', 'related-items', 'recently-viewed'].includes(recommendationType)
  ) {
    return [];
  }

  // get params
  const params = {
    recommendationType,
    limit,
    shop,
    wid: context.widgetId,
    event_type: 'init',
    pg: getCurrentPage(context),
    locale: context?.app?.generalSettings?.current_locale || 'en',
    layoutDisplay,
  };

  if (context.app.b2b?.enabled) {
    addB2bAPIParams(params, context);
  }

  const queryParams = new URLSearchParams(addParamsLocale(params));
  // handle custom parameters
  const customParams = window.boostSdCustomParams;
  if (customParams && Object.keys(customParams).length > 0) {
    for (const key in customParams) {
      queryParams.set(key, customParams[key]);
    }
  }

  // get body
  const { tenantId, ...widgetBody } = widgetParams;
  const body = addParamsLocale({
    ...widgetBody,
    layoutDisplay,
    wid: context.widgetId,
    productIds,
    collectionId,
    customerId: customerId ? String(customerId) : null,
    locale: context?.app?.generalSettings?.current_locale || 'en',
  });

  const url = context.app?.recommendUrl;
  const response = await fetch(`${url}?${queryParams}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    return [];
  }

  const dataResponse = await response.json();

  setLocalStorage(`boostSdRecommend-${context.widgetId}`, {
    wid: context.widgetId,
    rid: dataResponse?.meta?.rid,
    rt: recommendationType,
  });

  if (dataResponse.meta) {
    const { money_format, money_format_with_currency, currency } = dataResponse.meta;
    restrictMainCurrency(context, { money_format, money_format_with_currency, currency });
  }

  return dataResponse.products || [];
};

const getCustomerId = () => {
  return (
    window?.__st?.cid ||
    window?.meta?.page?.customerId ||
    window?.ShopifyAnalytics?.meta?.page?.customerId
  );
};

const getProductIds = async (context) => {
  const { recommendationType } = getRecommendWidgetInfo(context).params;

  let productIds = [];

  if (isProductPage(context)) {
    productIds = [getGeneralSettings(context).product_id];
  }

  if (isCartPage(context)) {
    await CartAPI.getAll()
      .then((res) => {
        productIds = res.items.map((item) => item.product_id);
      })
      .catch(() => (productIds = []));
  }

  if (recommendationType === 'recently-viewed') {
    productIds = getRecentlyViewedProducts();
  }

  return productIds;
};

const getCollectionIds = (context) => {
  let collectionId = 0;

  if (isCollectionPage(context)) {
    collectionId = getGeneralSettings(context).collection_id;
  }

  return collectionId;
};

const getGeneralSettings = (context) => {
  let generalSettings = context.app?.generalSettings || {};
  if (!generalSettings || Object.keys(generalSettings).length === 0) {
    generalSettings = window.boostWidgetIntegration?.generalSettings || {};
  }

  return generalSettings;
};

const prepareRecommendWidget = async (context) => {
  const [_, setRecommendation] = context.useContextState('recommendation', {});

  await getRecommendWidgetTemplate(context);
  let recommendProducts = await context.handler(getRecommendData, 'getRecommendData')(context);
  const widgetSettings = getRecommendWidgetInfo(context);
  const { widgetDesignSettings } = widgetSettings;

  const { product } = window.boostSDData;
  if (widgetDesignSettings.layoutDisplay === BUNDLE_LAYOUT && Object.keys(product).length > 0) {
    const mainProduct = rebuildProductItemDataFromShopifyData(product);
    recommendProducts = [mainProduct, ...recommendProducts];
  }

  // Split font style to font style and font weight
  const { titleFontStyle } = widgetDesignSettings;
  const { fontWeight, fontStyle } = splitGoogleFontStyle(titleFontStyle);

  const defaultData = {
    products: recommendProducts,
    widgetId: context.widgetId,
    widgetName: widgetSettings.widgetName,
    hasSelectedProduct: recommendProducts.length > 0,
    widgetDesignSettings: {
      tenantId: widgetDesignSettings.tenantId,
      layoutDisplay: widgetDesignSettings.layoutDisplay,
      templateType: widgetDesignSettings.templateType,
      bundleStyle: widgetDesignSettings.bundleStyle,
      titleTextColor: widgetDesignSettings.titleTextColor,
      titleFont: widgetDesignSettings.titleFont,
      titleFontSize: widgetDesignSettings.titleFontSize,
      titleFontStyle: fontStyle,
      titleFontWeight: fontWeight,
      titleTextTransform: widgetDesignSettings.titleTextTransform,
      titleAlignment: widgetDesignSettings.titleAlignment,
      numberOfProductPerRow: widgetDesignSettings.numberOfProductPerRow + 1,
      numberOfRecommendProduct: recommendProducts.length,
    },
  };

  setRecommendation(defaultData);
};

const getRecommendWidgetInfo = (context) => {
  return context.app.recommendationWidgets[context.pageType][context.widgetId];
};

const formatPrice = (context, price) => {
  const { showCentAsSuperscript, showCurrencyCodes } =
    context.templateSettings?.themeSettings?.productItems?.productInfo?.elements?.price || {};
  return formatCurrency({
    context,
    value: Number(price),
    showCurrencyCodes,
    showCentAsSuperscript,
    removeDecimalPoint: showCentAsSuperscript,
  });
};

const buildDropboxStyle = (rootPosition) => {
  const { width, height, left, top } = rootPosition;
  return `position:fixed;z-index:2;width:${width}px;left:${left}px;top:${top + height}px`;
};

const rebuildProductItemDataFromShopifyData = (productData) => {
  const variants = productData.variants.map((variant) => {
    const image = variant.featured_image?.src;

    const price = variant.price / 100;

    return {
      ...variant,
      price,
      image,
    };
  });

  return { ...productData, images_info: productData.media, variants };
};
