export const filterOptionBoxTemplate = `
<li class='{{ className }}'>
  <button
    id='{{ actionId }}'
    data-action='{{ dataAction }}'
    role='checkbox'
    aria-label='Find product with {{ label | escape }} is {{ value.label | escape | strip_html }}'
    aria-checked='false'
    class='boost-sd__filter-option-item-button boost-sd__filter-option-item-button--as-button'
  >
    <span
      data-action='{{ dataAction }}'
      class='boost-sd__filter-option-item-label'
    >
      {% if displayAllValuesInUppercaseForm %}
        {{ value.label | escape | upcase }}
      {% else %}
        {{ value.label | escape }}
      {% endif %}
    </span>
  </button>
</li>`;
