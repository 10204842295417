import IntegrationProductReview from './product-review';
import IntegrationMultipleCurrency from './multiple-currency';
import IntegrationProductLabel from './product-label';
import IntegrationWishlist from './wishlist';

export function appIntegration() {
  const env = window.boostWidgetIntegration?.env;
  let integration = window.boostWidgetIntegration?.app?.[env]?.integration;
  // Merge object
  integration = {
    ...integration,
    ...window.boostWidgetIntegration.integration,
  };

  boostWidgetIntegration.integration = integration;

  if (integration) {
    IntegrationProductReview(integration.reviews);
    IntegrationWishlist(integration.wishlists);
    IntegrationMultipleCurrency(integration.currencies);
    IntegrationProductLabel(integration.labels);
  }
}
