/**
 * @description Restrict free user to only able to use main currency
 * @param {Object} context - The context object containing the app configuration.
 * @param {Object} currencyInfo - The object containing currency information.
 * @param {string} currencyInfo.money_format - The format for displaying money.
 * @param {string} currencyInfo.money_format_with_currency - The format for displaying money with currency.
 * @param {string} currencyInfo.currency - The currency code.
 */
export function restrictMainCurrency(context, currencyInfo) {
  const { money_format, money_format_with_currency, currency } = currencyInfo;

  if (money_format) {
    context.app.shop.money_format = money_format;
  }

  if (money_format_with_currency) {
    context.app.shop.money_format_with_currency = money_format_with_currency;
  }

  if (currency) {
    context.app.shop.currency = currency;
  }
}
