export const focusFirstElement = (
  modal,
  selector = 'a[href], button:not([disabled]), input[type=number], [tabindex]:not([tabindex="-1"])'
) => {
  const focusableElements = modal.querySelectorAll(selector);
  const firstFocusableElement = focusableElements.length > 0 ? focusableElements[0] : null;

  if (firstFocusableElement) {
    firstFocusableElement.focus();
  }
};

export const trapFocus = (
  event,
  modal,
  selector = 'a[href], button:not([disabled]), input[type=number], [tabindex]:not([tabindex="-1"])',
  closeButtonSelector = '.boost-sd__modal-close-btn'
) => {
  const focusableElements = modal.querySelectorAll(selector);
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];

  if (event.key === 'Tab') {
    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
      }
    }
  } else if (event.key === 'Escape') {
    const closeButton = modal.querySelector(closeButtonSelector);
    closeButton?.click();
  }
};

let lastFocusedElementRef = null;

export const isFocusable = (element) => {
  return element && !element.disabled && element.offsetParent !== null;
};

export const saveFocusElement = (element) => {
  if (element) {
    const cartItem = element.closest('.boost-sd__cart-item');
    const productId = cartItem?.getAttribute('data-product-id');
    const isQuantityInput = element.tagName.toLowerCase() === 'input';

    if (productId) {
      lastFocusedElementRef = {
        productId,
        isQuantityInput,
        attributeType: element.getAttribute('aria-label'),
      };
    }
  }
};

export const restoreFocusedElement = () => {
  const modal = document.querySelector('#boost-sd__modal-card');
  if (!modal) return;

  const observer = new MutationObserver((mutations, obs) => {
    const { productId, isQuantityInput, attributeType } = lastFocusedElementRef || {};
    let newElement = null;

    if (isQuantityInput) {
      newElement = document.querySelector(`[data-product-id="${productId}"] input[type="number"]`);
    } else {
      newElement = document.querySelector(
        `[data-product-id="${productId}"] button[aria-label="${attributeType}"]`
      );
    }

    if (newElement && isFocusable(newElement)) {
      newElement.focus();
      obs.disconnect();
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
};
