import { FILTER_KEY_PREFIX } from '../constants/filter-tree.js';
import { isCollectionPage } from './validate.js';

/**
 * @param { string } behavior - push | replace | null - default behavior = push
 * @param { string } urlHref - current URL to push history
 * @param { Object } state - save state or empty object
 */
function mutateHistory(behavior = 'push', urlHref, state = {}) {
  if (behavior === 'push') {
    // not prevent then create a new history
    window.history.pushState(state, '', urlHref);
  } else {
    // replace history to avoid create new history
    window.history.replaceState(state, '', urlHref);
  }
}

export const convertValueRequestStockStatus = (v) => {
  if (Array.isArray(v)) {
    return v.map((_v) => {
      return _v !== 'out-of-stock';
    });
  }

  return v !== 'out-of-stock';
};

export const appendQueryParamHistory = (key, value) => {
  const url = new URL(window.location?.href);
  url.searchParams.append(key, value);
  url.searchParams.sort();
  window.history.pushState({}, '', url.href);
};

export const setQueryParamHistory = (
  key,
  value,
  replace = false,
  { preventPushHistory } = { preventPushHistory: false }
) => {
  const url = new URL(window.location?.href);
  url.searchParams.set(key, value);
  url.searchParams.sort();

  replace
    ? window.history.replaceState({}, '', url.href)
    : !preventPushHistory
      ? window.history.pushState({}, '', url.href)
      : '';
};

export const removeQueryParamHistory = (key, value) => {
  removeQueryParamsHistory([{ key, value }]);
};

export const removeQueryParamsHistory = (params) => {
  const url = new URL(window.location?.href);
  const { searchParams } = url;

  params.forEach((param) => {
    const { key, value } = param;
    if (searchParams.has(key)) {
      const filterValues = searchParams.getAll(key).filter((v) => v !== value);
      searchParams.delete(key);
      filterValues.forEach((v) => searchParams.append(key, v));
    }
  });
  searchParams.sort();
  window.history.pushState({}, '', url.href);
};

export const deleteQueryParamHistory = (
  key,
  { preventPushHistory } = { preventPushHistory: false }
) => {
  deleteQueryParamsHistory([key], { preventPushHistory });
};

export const deleteQueryParamsHistory = (
  keys,
  { preventPushHistory } = { preventPushHistory: false }
) => {
  const url = new URL(window.location?.href);
  const { searchParams } = url;

  keys.forEach((key) => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
    }
  });
  searchParams.sort();
  const behavior = preventPushHistory ? 'replace' : 'push';
  mutateHistory(behavior, url.href, {});
};

export const clearAllParamHistory = ({ preventPushHistory } = { preventPushHistory: false }) => {
  const url = new URL(window.location?.href);
  const { searchParams } = url;
  const removeKeys = [];
  searchParams.forEach((_, key) => {
    if (key.startsWith(FILTER_KEY_PREFIX)) {
      removeKeys.push(key);
    }
  });
  removeKeys.forEach((k) => searchParams.delete(k));

  const behavior = preventPushHistory ? 'replace' : 'push';
  mutateHistory(behavior, url.href, {});
};

export function getQueryParamByKey(key) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}

export const getParamsHistory = () => {
  const url = new URL(window.location?.href);
  const { searchParams } = url;

  const paramList = [];
  const sort = searchParams.get('sort');
  const pagination = { page: searchParams.get('page') };

  const paramKeys = [];
  for (let key of searchParams.keys()) {
    if (key.startsWith(FILTER_KEY_PREFIX) && !paramKeys.includes(key)) {
      paramKeys.push(key);
      paramList.push({
        key,
        values: searchParams.getAll(key),
      });
    }
  }

  return { paramList, sort, pagination };
};

/**
 * Builds a URLSearchParams object from the given params object.
 * Converts params object into a query string format by appending
 * array values and encoding values.
 *
 * @param {Object} params - Object containing keys and values to convert to query string
 * @returns {URLSearchParams} - URLSearchParams object built from params
 */
export function buildQueryParams(params) {
  const queryParams = new URLSearchParams();
  queryParams.sort();
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.append(`${key}[]`, item);
        });
      } else {
        queryParams.set(key, value);
      }
    }
  }

  const urlParams = new URLSearchParams(window.location.search);
  const searchQuery = urlParams.get('q');

  if (searchQuery) {
    queryParams.set('q', searchQuery);

    if (isCollectionPage()) {
      queryParams.set('incollection_search', true);
      queryParams.set('event_type', 'incollection_search');
    }
  }

  return queryParams;
}

export const parseFilterTreeToParams = (filterTree) => {
  const filterParams = {};
  filterTree.forEach((filter) => {
    let { key, value } = filter.metaData || filter.data;
    if (key.startsWith(`pf_st_`)) {
      value = convertValueRequestStockStatus(value);
    }

    filterParams[key] = [...(filterParams[key] || []), value];
  });
  return filterParams;
};

export const calcPageAction = (page, action) => {
  let newPage = Number(page);
  if (action === 'next') {
    newPage += 1;
  } else if (action === 'prev') {
    newPage = newPage - 1 > 0 ? newPage - 1 : 0;
  } else {
    newPage = Number(action);
  }

  return newPage;
};

/**
 * Check whether the filter option param exist
 */
export const checkExistFilterOptionParam = () => {
  const queryParams = new URLSearchParams(window.location.search);

  for (const [key, value] of queryParams.entries()) {
    if (key.indexOf('pf_') > -1) {
      return true;
    }
  }

  return false;
};
