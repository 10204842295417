//import { buildProductItemUrl } from '../utils';

const IntegrationWishlist = (type) => {
  /**
   * Call callback function after render product list
   */
  const callbackFunc = () => {
    try {
      switch (type) {
        case 'plus':
          window?._swat?.initializeActionButtons?.('.boost-sd__product-list');
          window?._swat?.initializeActionButtons?.('.boost-sd__recommendation');
          document.dispatchEvent(new CustomEvent('swym:collections-loaded'));

          break;

        case 'smart':
          window?.ReloadSmartWishlist?.();
          break;
        case 'i-wishlist':
          if (typeof jQuery !== 'undefined') {
            jQuery('.iWishAddColl').click(function (e) {
              e.preventDefault();
              var iWishvId = jQuery(this).attr('data-variant');
              iwish_addCollection(jQuery(this), iWishvId);
              return false;
            });
          }
          break;
        case 'king':
          try {
            if (!window?.WishlistKing) return;

            const theme = window.WishlistKing?.theme;
            if (theme) {
              theme.watch?.(
                {
                  selector: '.boost-sd__product-link-image',
                },
                (target) => {
                  target.insertAfter(
                    theme.createComponent?.('wishlist-button-collection', {
                      dataset: {
                        productHandle: theme.getProductHandle?.(target.element.href),
                        variantId: theme.getVariantId?.(target.element.href),
                      },
                      props: {
                        showIcon: true,
                        showText: false,
                        floating: {
                          reference: target,
                          position: {
                            placement: 'top-end',
                            inset: true,
                          },
                        },
                      },
                    })
                  );
                }
              );
            } else {
              window.WishlistKing?.observe?.(
                {
                  selector: '.boost-sd__product-item',
                },
                (target) => {
                  target.append?.(
                    window.WishlistKing?.createComponent?.('wishlist-button-floating', {
                      id: target.container?.getAttribute?.('id'),
                    })
                  );
                }
              );
            }
          } catch (error) {}
          break;
        default:
          return null;
      }
    } catch (error) {
      console.log('Error when try to callbackFunc() from 3rd app - Product Wishlist');
      console.log(error.stack);
    }
  };

  callbackFunc();
};

export default IntegrationWishlist;
