import { setQueryParamHistory } from '../utils/index.js';
import { resetFilterRequest } from '../api/filter.js';

export function handleSort(context, target) {
  const [getSort, setSort] = context.useContextState('sort', {
    isShow: false,
    sort: null,
  });

  if (
    target.classList.contains('boost-sd__sorting-value') ||
    target.classList.contains('boost-sd__sorting-button') ||
    target.classList.contains('boost-sd__sorting-option')
  ) {
    const { isShow, sort } = getSort();
    setSort({
      sort,
      isShow: !isShow,
    });
    if (target.classList.contains('boost-sd__sorting-option')) {
      const sort = target.getAttribute('data-value');
      setSort({
        sort,
        isShow: !isShow,
      });
      setQueryParamHistory('sort', sort);
      resetFilterRequest(context);
    }
  }

  context.render(() => {
    const sort = getSort();
    const childElementsList = context.document.querySelector('.boost-sd__sorting-list');
    const childElementsButton = context.document.querySelector('.boost-sd__sorting-button');

    if (sort && !!sort.isShow) {
      childElementsList.classList.add('boost-sd__sorting-list--active');
      childElementsButton.classList.add('boost-sd__sorting-button--active');
    } else {
      childElementsList.classList.remove('boost-sd__sorting-list--active');
      childElementsButton.classList.remove('boost-sd__sorting-button--active');
      childElementsButton.focus();
    }
  }, ['sort']);
}

export const handleClickOutsideSort = (context, target) => {
  const sortingList = context.$('.boost-sd__sorting-list');

  if (sortingList) {
    if (!target?.closest('.boost-sd__sorting-button')) {
      const [getSort, setSort] = context.useContextState('sort');

      const sort = getSort();

      if (sort?.isShow) {
        setSort({
          isShow: false,
        });
      }
    }
  }
};
