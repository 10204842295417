import { buildParams } from '../api';
import { getMostPopularProductTemplate } from '../api/search';
import { getTemplate } from '../template';
import { templateRender } from '../template/templateRender';
import {
  buildQueryParams,
  debounce,
  getSearchSettings,
  getThemeSettings,
  isMobile,
  setWindowLocation,
} from '../utils';
import { translateWithComponent } from '../utils/translation';
import {
  handleSearchInputChange,
  searchInputListener,
  searchInputKeydownListener,
  searchButtonListener,
  resetButtonListener,
} from '../instant-search-widget';
import { KEYS } from '../constants/keys';

const MOBILE_BREAKPOINT = 767;

const getNumberFromText = (text) => {
  const regex = /\d+/; // Matches one or more digits
  const match = text.match(regex);

  if (match) {
    return parseInt(match[0], 10); // Convert the matched string to a number (base 10)
  } else {
    return 0; // No number found
  }
};
const handleBeforeChangeTab = (context, currentTab, searchToolbarResult) => {
  const previousActiveTab = context.document.querySelector(
    '.boost-sd__search-result-toolbar-panel--active'
  );

  if (previousActiveTab) {
    previousActiveTab.classList.remove('boost-sd__search-result-toolbar-panel--active');
    previousActiveTab.setAttribute('aria-selected', 'false');
    previousActiveTab.setAttribute('tabindex', '-1'); // Make the previous tab unfocusable
  }

  const previousActiveTabContent = context.document.querySelector(
    '.boost-sd__search-result-panel-content'
  );

  if (previousActiveTabContent) {
    previousActiveTabContent.remove();
  }

  currentTab.classList.add('boost-sd__search-result-toolbar-panel--active');
  currentTab.setAttribute('aria-selected', 'true');
  currentTab.setAttribute('tabindex', '0'); // Make the current tab focusable
  currentTab.focus(); // Move focus to the newly selected tab

  const count = getNumberFromText(currentTab.innerText);

  const translationKey = count > 1 ? 'search.searchTotalResults' : 'search.searchTotalResult';
  const translationFallback =
    count > 1 ? 'Showing {{ count }} results' : 'Showing {{ count }} result';

  const showingResultText = translateWithComponent(
    context.translate(translationKey, translationFallback),
    { count }
  );

  if (showingResultText && searchToolbarResult) {
    searchToolbarResult.innerText = showingResultText;
  }
};

const handleLinksClick = (context) => {
  const content = context.document.querySelector('.boost-sd__search-result-panel-content');

  if (content) {
    const contentItems = content.querySelectorAll('.boost-sd__search-result-panel-content-item');

    contentItems?.forEach((contentItem) => {
      contentItem?.addEventListener('click', (e) => {
        const href = contentItem?.getAttribute('href');

        if (href) {
          setWindowLocation(href);
        }
      });
    });
  }
};

export const initMostPopularProductsSlider = async () => {
  const jquery = await import('jquery');
  window.$ = jquery.default;

  await import('slick-carousel');
};
const showOrHideArrowButtons = (context, productsLength, prevButton, nextButton) => {
  const isMobileSize = isMobile(
    context?.app?.generalSettings?.isTabletPortraitMax || MOBILE_BREAKPOINT
  );

  if ((isMobileSize && productsLength <= 2) || (!isMobileSize && productsLength <= 4)) {
    if (prevButton) prevButton.style.display = 'none';
    if (nextButton) nextButton.style.display = 'none';
  } else {
    if (prevButton) prevButton.style.display = 'block';
    if (nextButton) nextButton.style.display = 'block';
  }
};

export const getMostPopularProducts = async (context) => {
  if (!context?.templateSettings?.searchSettings?.searchPanelBlocks?.mostPopularProducts?.active)
    return;

  const noSearchResult = context.document.querySelector('.boost-sd-no-search-result');

  if (noSearchResult) {
    const handle =
      context?.templateSettings?.searchSettings?.suggestionNoResult?.products?.data || [];

    if (handle?.length === 0) return;

    const widgetName = 'mostPopularProducts';
    const widgetId = context?.app?.templateMetadata?.[widgetName];
    const themeLib = context?.app?.templateMetadata?.themeNameLib || 'default';

    try {
      const res = await getMostPopularProductTemplate(context, handle);

      if (!res.template || res.products.length === 0) return;

      const noSearchResultPage = context.document.querySelector('.boost-sd__no-search-result-page');

      const template = res?.template?.replace(/loading="lazy"/g, '');

      let products = res?.products || [];

      const html = context.templateRender(template, {
        products,
        request: {
          money_format: context.app.shop.money_format,
          money_format_with_currency: context.app.shop.money_format_with_currency,
        },
      });

      noSearchResultPage.insertAdjacentHTML('beforeend', html);

      const mostPopularProducts = context.document.querySelector(
        '.boost-sd__no-search-result-page-popular-products'
      );
      const prevButton = mostPopularProducts?.querySelector('.boost-sd__prev-button');
      const nextButton = mostPopularProducts?.querySelector('.boost-sd__next-button');

      const productsLength = products.length;
      const slidesToShow = productsLength >= 4 ? 4 : productsLength;

      // Hide to avoid break layout before finishing slider init
      if (mostPopularProducts) {
        mostPopularProducts.style.display = 'none';
      }

      showOrHideArrowButtons(context, productsLength, prevButton, nextButton);

      await initMostPopularProductsSlider();

      const slider = $('.slick-slider');

      slider?.slick({
        slidesToShow,
        slidesToScroll: slidesToShow,
        infinite: true,
        arrow: false,
        responsive: [
          {
            breakpoint: MOBILE_BREAKPOINT,
            settings: {
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
            },
          },
        ],
      });

      if (mostPopularProducts) mostPopularProducts.style.display = 'block';

      // Handle prev/next slider
      prevButton?.addEventListener('click', () => {
        slider?.slick('slickPrev');
      });
      nextButton?.addEventListener('click', () => {
        slider?.slick('slickNext');
      });

      window.addEventListener(
        'resize',
        debounce(() => {
          showOrHideArrowButtons(context, productsLength, prevButton, nextButton);
        }, 300)
      );
    } catch (e) {
      console.log(e);
    }
  }
};

export const handleSearchResultTabs = async (context) => {
  const params = buildParams(context, {}, null);
  const queryParams = buildQueryParams(params);

  const { searchPanelBlocks } = getSearchSettings(context);
  const { additionalElements } = getThemeSettings(context);
  const { pagination } = additionalElements || {};

  const {
    displayImage: displayCollectionsImage,
    displayDescription: displayCollectionsDescription,
    pageSize: collectionPageSize,
  } = searchPanelBlocks?.collections || {};
  const {
    displayImage: displayPagesImage,
    displayExcerpt: displayPagesDescription,
    pageSize: pagePageSize,
  } = searchPanelBlocks?.pages || {};

  const paginationAlignment = pagination?.alignment;
  const paginationButtonShape = pagination?.button?.shape;
  const paginationNumberShape = pagination?.number?.shape;

  const [getCollectionTabNumber, setCollectionTabNumber] = context.useContextState(
    'collection-tab-page-number',
    0
  );
  const [getPageTabNumber, setPageTabNumber] = context.useContextState('page-tab-page-number', 0);

  setCollectionTabNumber(0);
  setPageTabNumber(0);

  const wrapperSearchPage = context.document.querySelector('.boost-sd-container-search-page');
  const searchToolbarResult = context.document.querySelector(
    '.boost-sd__search-result-toolbar-result'
  );
  const productsTab = context.document.querySelector(
    '#boost-sd__search-result-toolbar-panel-products'
  );
  const collectionsTab = context.document.querySelector(
    '#boost-sd__search-result-toolbar-panel-collections'
  );
  const pagesTab = context.document.querySelector('#boost-sd__search-result-toolbar-panel-pages');
  const boostLayout = context.document.querySelector('.boost-sd-layout');
  const toolbarContainer = context.document.querySelector('.boost-sd__toolbar-container');
  const searchInput = context.document.querySelector('.boost-sd__search-form-input');

  let collections = [];
  let pages = [];
  let totalCollection = 0;
  let totalPage = 0;

  getMostPopularProducts(context);

  const handleTabEvent = (tab, context, searchToolbarResult, callback) => {
    const handleEvent = (event) => {
      if (event.type === 'click' || KEYS.includes(event.key)) {
        event.preventDefault();
        handleBeforeChangeTab(context, tab, searchToolbarResult);
        callback();
      }
    };

    // Add both click and keydown event listeners
    tab?.addEventListener('click', handleEvent);
    tab?.addEventListener('keydown', handleEvent);
  };

  // Apply the function to each tab
  handleTabEvent(productsTab, context, searchToolbarResult, () => {
    if (boostLayout) {
      boostLayout.style.display = 'flex';
    }
    if (toolbarContainer) {
      toolbarContainer.style.display = 'block';
    }
  });

  handleTabEvent(collectionsTab, context, searchToolbarResult, () => {
    setCollectionTabNumber(1);
  });

  handleTabEvent(pagesTab, context, searchToolbarResult, () => {
    setPageTabNumber(1);
  });

  const tabs = context.document.querySelectorAll('.boost-sd__search-result-toolbar-panel');
  const tabPanels = context.document.querySelectorAll('.boost-sd__search-result-panel-content');
  tabs.forEach((tab, index) => {
    tab.addEventListener('click', () => {
      handleBeforeChangeTab(context, tab, searchToolbarResult);
    });

    tab.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowRight') {
        const nextTab = tabs[(index + 1) % tabs.length];
        nextTab.focus();
      } else if (e.key === 'ArrowLeft') {
        const prevTab = tabs[(index - 1 + tabs.length) % tabs.length];
        prevTab.focus();
      } else if (KEYS.includes(e.key)) {
        handleBeforeChangeTab(context, tab, searchToolbarResult);
      }
    });

    // Ensure that only the active tab is in the tab order
    tab.setAttribute('tabindex', index === 0 ? '0' : '-1');
  });

  tabPanels.forEach((panel) => {
    panel.setAttribute('tabindex', '-1'); // Remove panels from the tab order
  });

  if (searchInput) {
    // Bind event for ISW
    // Input
    const contextISW = window.boostWidgetIntegration.blocks['bc-instant-search'];
    if (contextISW) {
      searchInput.addEventListener('input', (e) => {
        handleSearchInputChange(contextISW, e, searchInput);
      });

      searchInput.addEventListener('click', async (e) => {
        searchInputListener(contextISW, e, searchInput);
      });

      searchInput.addEventListener('focus', async (e) => {
        searchInputListener(contextISW, e, searchInput);
      });

      searchInput.addEventListener('keydown', (e) => {
        searchInputKeydownListener(contextISW, e, searchInput);
      });
    }

    // Button
    // detect button: search and reset
    const searchForm = searchInput.closest('form');
    if (searchForm) {
      // Button search
      const searchButton = searchForm.querySelector('[type="submit"]');
      if (searchButton) {
        searchButton.addEventListener('click', (e) => {
          searchButtonListener(contextISW, e, searchInput);
        });

        searchButton.addEventListener('keydown', (e) => {
          if (KEYS.includes(e.key)) {
            sessionStorage.setItem('keyboardActivatedSearch', 'true');
          }
        });
      }

      // After detecting the 'search?q' parameter in the URL:
      const searchParams = new URLSearchParams(window.location.search);
      const keyboardActivatedSearch = sessionStorage.getItem('keyboardActivatedSearch') === 'true';
      if (searchParams.has('q') && keyboardActivatedSearch) {
        if (tabs.length > 0) {
          tabs[0].focus(); // Set focus to the first tab
        }
        // Reset the flag after usage
        sessionStorage.removeItem('keyboardActivatedSearch');
      }

      // Button reset
      const resetButton = searchForm.querySelector('[type="reset"]');
      if (resetButton) {
        if (!!searchInput.value) {
          resetButton.classList.remove('boost-sd__g-hide');
        }
        resetButton.addEventListener('click', (e) => {
          resetButtonListener(searchInput, resetButton);
        });
      }
    }
  }

  context.render(async () => {
    const page = getCollectionTabNumber();

    if (page === 0) return;

    try {
      queryParams.set('page', page);
      queryParams.set('limit', collectionPageSize);

      const response = await fetch(`${context.app.searchUrl + '/collections'}?${queryParams}`, {
        method: 'GET',
      });

      const result = await response.json();

      if (result?.collections) {
        collections = result?.collections;
        totalCollection = result?.total_collection;
      }
    } catch (e) {
      console.log(e);
    }

    const html = context.templateRender(getTemplate(context).searchTabCollectionsTemplate, {
      collections,
      displayImage: displayCollectionsImage,
      displayDescription: displayCollectionsDescription,
      paginationAlignment,
      paginationButtonShape,
      paginationNumberShape,
      pageSize: collectionPageSize || 24,
      totalCollection,
      currentPage: Number(page),
    });

    if (boostLayout) {
      boostLayout.style.display = 'none';
    }
    if (toolbarContainer) {
      toolbarContainer.style.display = 'none';
    }

    const searchPanelContent = wrapperSearchPage?.querySelector(
      '.boost-sd__search-result-panel-content'
    );

    if (searchPanelContent) {
      searchPanelContent.innerHTML = html;
    } else {
      wrapperSearchPage.insertAdjacentHTML('beforeend', html);
    }

    handleLinksClick(context);

    const paginationNumbers = context.document.querySelectorAll(
      '.boost-sd__search-result-panel-content .boost-sd__pagination-number'
    );
    const paginationBtnPrevious = context.document.querySelector(
      '.boost-sd__search-result-panel-content  .boost-sd__pagination-button--previous'
    );
    const paginationBtnNext = context.document.querySelector(
      '.boost-sd__search-result-panel-content .boost-sd__pagination-button--next'
    );

    paginationNumbers?.forEach((paginationNumber) => {
      paginationNumber?.addEventListener('click', async (e) => {
        e.stopPropagation();

        const page = paginationNumber?.getAttribute('data-page');

        if (page) {
          setCollectionTabNumber(page);
          handleLinksClick(context);
        }
      });
    });

    paginationBtnPrevious?.addEventListener('click', async (e) => {
      e.stopPropagation();
      setCollectionTabNumber(page - 1);
    });
    paginationBtnNext?.addEventListener('click', async (e) => {
      e.stopPropagation();
      setCollectionTabNumber(page + 1);
    });
  }, ['collection-tab-page-number']);

  context.render(async () => {
    const page = getPageTabNumber();

    if (page === 0) return;

    try {
      queryParams.set('page', page);
      queryParams.set('limit', pagePageSize);

      const response = await fetch(`${context.app.searchUrl + '/pages'}?${queryParams}`, {
        method: 'GET',
      });

      const result = await response.json();

      if (result?.pages) {
        pages = result?.pages;
        totalPage = result?.total_page;
      }
    } catch (e) {
      console.log(e);
    }

    const html = context.templateRender(getTemplate(context).searchTabPagesTemplate, {
      pages,
      displayImage: displayPagesImage,
      displayDescription: displayPagesDescription,
      paginationAlignment,
      paginationButtonShape,
      paginationNumberShape,
      pageSize: pagePageSize || 24,
      totalPageTab: totalPage,
      currentPage: Number(page),
    });

    if (boostLayout) {
      boostLayout.style.display = 'none';
    }
    if (toolbarContainer) {
      toolbarContainer.style.display = 'none';
    }

    const searchPanelContent = wrapperSearchPage?.querySelector(
      '.boost-sd__search-result-panel-content'
    );

    if (searchPanelContent) {
      searchPanelContent.innerHTML = html;
    } else {
      wrapperSearchPage.insertAdjacentHTML('beforeend', html);
    }

    handleLinksClick(context);

    const paginationNumbers = context.document.querySelectorAll(
      '.boost-sd__search-result-panel-content .boost-sd__pagination-number'
    );
    const paginationBtnPrevious = context.document.querySelector(
      '.boost-sd__search-result-panel-content .boost-sd__pagination-button--previous'
    );
    const paginationBtnNext = context.document.querySelector(
      '.boost-sd__search-result-panel-content .boost-sd__pagination-button--next'
    );

    paginationNumbers?.forEach((paginationNumber) => {
      paginationNumber?.addEventListener('click', async (e) => {
        e.stopPropagation();

        const page = paginationNumber?.getAttribute('data-page');

        if (page) {
          setPageTabNumber(page);
          handleLinksClick(context);
        }
      });
    });
    paginationBtnPrevious?.addEventListener('click', async (e) => {
      e.stopPropagation();
      setPageTabNumber(page - 1);
    });
    paginationBtnNext?.addEventListener('click', async (e) => {
      e.stopPropagation();
      setPageTabNumber(page + 1);
    });
  }, ['page-tab-page-number']);
};
