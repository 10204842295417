import { addToCart, renderCart } from '../cart';

import {
  DEFAULT,
  INFINITE,
  LOAD_MORE,
  PREV_PAGE,
  SELECTED_PRODUCT_ID,
  SELECTED_PRODUCT_PAGE,
} from '../constants/pagination';
import { getTemplate } from '../template';
import { templateRender } from '../template/templateRender';
import {
  buildProductDetailUrlWithVariant,
  formatCurrency,
  getQueryParamByKey,
  getSessionStorage,
  getThemeSettings,
  handleClickOutside,
  safeParseJSON,
  setQueryParamHistory,
  setSessionStorage,
  setWindowLocation,
  debounce,
} from '../utils';
import { translateWithComponent } from '../utils/translation';

export * from './product-swatch';
import { trapFocus, focusFirstElement } from '../utils/wcag-modal';
import { KEYS } from '../constants/keys';

let prevSelectedPopUp = null;
window.focusedElementBeforeMiniCart = null;

export const quickAddToCart = (context, item, target) => {
  // Remove previous popup selection if exists
  if (prevSelectedPopUp) {
    prevSelectedPopUp.style.display = 'none';
  }

  const selectedId = item?.variant_id || item?.productId;

  if (!selectedId) return;

  // Open current popup selection
  const productItem = target?.closest('.boost-sd__product-item');

  const popupSelection = productItem.querySelector('.boost-sd__popup-select-option');
  if (popupSelection) {
    popupSelection.style.display = 'block';
    handleQuickAddToCartSelectOption(context, popupSelection);
    window.focusedElementBeforeMiniCart = document.activeElement;

    /**
     * Popup Selection - Support WCAG
     */

    focusFirstElement(popupSelection, 'label, button');

    const handlePopupTrapFocus = (e) => {
      trapFocus(e, popupSelection, 'label, button', '.boost-sd__popup-select-option-close-btn');
    };

    popupSelection.addEventListener('keydown', handlePopupTrapFocus);

    prevSelectedPopUp = popupSelection;
  }
};

export const closeQuickAddToCart = (target) => {
  const popupSelection = target.closest('.boost-sd__popup-select-option');
  popupSelection.style.display = 'none';
  target
    .closest('.boost-sd__product-item')
    .querySelector('.boost-sd__button--select-option')
    .focus();
};

export const handleQuickAddToCartSelectOption = (context, popupSelection) => {
  const productSwatches = popupSelection.querySelectorAll('.boost-sd__product-swatch');
  const addToCartButton = popupSelection.querySelector('.boost-sd__btn-add-to-cart');
  const addToCartButtonText = popupSelection.querySelector(
    '.boost-sd__btn-add-to-cart .boost-sd__button-text'
  );

  const productItem = popupSelection.closest('.boost-sd__product-item');
  const { variants } = safeParseJSON(productItem.getAttribute('data-product')) || {};

  let productVariants = variants;

  if (typeof variants === 'string') {
    productVariants = safeParseJSON(variants);
  }

  if (!productVariants) return;

  const [getVariant, setVariant] = context.useContextState('selection-option', {});

  setVariant({
    selectedVariant: productVariants[0],
  });

  productSwatches?.forEach((swatch) => {
    const swatchOptions = swatch.querySelectorAll('.boost-sd__product-swatch-option');
    swatchOptions?.forEach((option) => {
      const handleSelect = () => {
        swatchOptions?.forEach((option) => {
          const label = option.querySelector('.boost-sd__radio-label');
          label?.classList.remove('boost-sd__radio-label--selected');
        });

        const label = option.querySelector('.boost-sd__radio-label');
        label?.classList.add('boost-sd__radio-label--selected');

        const selectedSwatchOptions = popupSelection.querySelectorAll(
          '.boost-sd__radio-label--selected'
        );

        let title = '';
        selectedSwatchOptions?.forEach((option, i) => {
          title += option.textContent.trim();

          if (i < selectedSwatchOptions.length - 1) {
            title += ' / ';
          }
        });

        const selectedVariant = productVariants?.find((variant) => {
          return variant.title === title;
        }) || {
          available: false,
          id: '',
          price: null,
        };

        setVariant({
          selectedVariant,
        });
      };

      option.addEventListener('click', handleSelect);

      // Support WCAG when selected option using keyboard
      const handleOptionKeyup = (e) => {
        if (KEYS.includes(e.key)) {
          handleSelect();
        }
      };

      option.addEventListener('keyup', handleOptionKeyup);
    });
  });

  addToCartButton?.addEventListener('click', async () => {
    context.handler(addToCart, 'addToCart')(
      context,
      { productId: getVariant().selectedVariant.id, quantity: 1 },
      addToCartButton,
      false,
      () => {
        popupSelection && (popupSelection.style.display = 'none');
      }
    );
  });

  context.render(() => {
    const { selectedVariant } = getVariant();

    if (!addToCartButton || !selectedVariant) return;

    const isSoldOutVariantSelected = !selectedVariant.available;

    if (isSoldOutVariantSelected) {
      addToCartButton.disabled = true;

      if (addToCartButtonText) {
        addToCartButtonText.textContent =
          context.app?.translation?.productItem?.soldoutLabel || 'Sold out';
      }
    } else {
      addToCartButton.removeAttribute('disabled');

      if (addToCartButtonText) {
        addToCartButtonText.textContent =
          context.app?.translation?.productItem?.atcAvailableLabel || 'Add to cart';
      }
    }

    // For analytics
    addToCartButton?.setAttribute('data-variant-id', selectedVariant.id);
  }, ['selection-option']);
};

export const handleClickOutsideMiniPopup = (target) => {
  // Close quick cart popup selection when click out side
  if (target.closest('.boost-sd__button--select-option')) {
    return;
  }
  const miniPopup = target
    ?.closest('.boost-sd__product-item')
    ?.querySelector('.boost-sd__popup-select-option');
  const miniPopupOpening = document.querySelector(
    '.boost-sd__popup-select-option[style="display: block;"]'
  );
  if (!miniPopup && miniPopupOpening) {
    miniPopupOpening.style.display = 'none';
  }
};

export const handleClickProductItem = (context, action, target) => {
  // check if click banner-link then open banner link
  if (target?.closest('.boost-sd__product-banner-link')) {
    const bannerElement = target?.closest('.boost-sd__product-banner-link');
    const url = bannerElement.getAttribute('href');
    const openNewTab = bannerElement.getAttribute('target') === '_blank';

    if (openNewTab) {
      window.open(url, '_blank').focus();
    } else {
      setWindowLocation(url);
    }

    return;
  }
  //check click on embedded bundle product list
  if (target?.closest('.boost-sd__product-item--embedded-bundle')) {
    return;
  }

  //Check if need to redirect or not, exclude if the tagName is wishlist-button-collection
  const tagName = target.tagName.toLowerCase();
  const closestTagWishlist = target.closest('wishlist-button-collection');
  const noRedirect = target.dataset.noRedirect;
  const closestNoRedirect = target.closest("[data-no-redirect='true']");
  if (
    tagName === 'wishlist-button-collection' ||
    closestTagWishlist ||
    noRedirect === 'true' ||
    closestNoRedirect
  )
    return;

  const productItem = target.closest('.boost-sd__product-item');
  const { addCollectionToProductUrl, current_tags = [] } = context.app?.generalSettings || {};

  if (productItem) {
    const { handle, splitProduct, variantId, variants } =
      safeParseJSON(productItem.getAttribute('data-product')) || {};
    const productId = productItem.getAttribute('data-product-id');

    const productUrl = buildProductDetailUrlWithVariant(
      context,
      { variants, variant_id: variantId, handle, split_product: splitProduct },
      addCollectionToProductUrl,
      current_tags
    );

    const index = getIndexOfProductItem(context, productItem);

    trackSelectedProductPage(context, index, productId);
    setWindowLocation(productUrl);
  }
};

const handlePrice = ({ priceMin, compareAtPriceMin }) => {
  priceMin = Number(priceMin);
  compareAtPriceMin = Number(compareAtPriceMin);

  const isSale = (compareAtPriceMin || 0) > priceMin;

  const salePercent =
    isSale && compareAtPriceMin
      ? Math.round((((compareAtPriceMin || 0) - priceMin) * 100) / compareAtPriceMin) + '%'
      : undefined;

  const saleAmount = isSale ? (compareAtPriceMin || 0) - priceMin : undefined;

  return {
    isSale,
    salePercent,
    saleAmount,
  };
};

const mainPrice = (
  context,
  priceMin,
  compareAtPriceMin,
  priceMax,
  {
    showCentAsSuperscript,
    showCurrencyCodes,
    compareAtPricePosition,
    showSavingDisplay,
    textAlign,
    productItemSavingAmountTranslation,
    productItemAmountTranslation,
  }
) => {
  let html = '';

  const { isSale, salePercent, saleAmount } = handlePrice({
    priceMin,
    compareAtPriceMin,
  });

  const minPriceWithFormat = formatCurrency({
    context,
    value: Number(priceMin),
    showCurrencyCodes,
    showCentAsSuperscript,
    removeDecimalPoint: showCentAsSuperscript,
  });
  const maxPriceWithFormat = formatCurrency({
    context,
    value: Number(priceMax),
    showCurrencyCodes,
    showCentAsSuperscript,
    removeDecimalPoint: showCentAsSuperscript,
  });

  const saleAmountWithFormat = formatCurrency({ context, value: saleAmount });

  const compareAtPriceWithFormat =
    !compareAtPriceMin || compareAtPriceMin === '0' || !isSale
      ? null
      : formatCurrency({
          context,
          value: Number(compareAtPriceMin),
          showCurrencyCodes,
          showCentAsSuperscript,
          removeDecimalPoint: showCentAsSuperscript,
        });

  const savingPriceWithFormat =
    !showSavingDisplay || !saleAmount
      ? null
      : translateWithComponent(productItemSavingAmountTranslation, {
          salePercent,
          saleAmount: saleAmountWithFormat,
        });

  // priceMax used for multi variant price display where it can have min-max price with format and translation
  const priceValueWithFormat = priceMax
    ? translateWithComponent(productItemAmountTranslation, {
        minPrice: minPriceWithFormat,
        maxPrice: maxPriceWithFormat,
      })
    : minPriceWithFormat;

  /**
   * Corresponding classes for price display matched with Theme Settings
   *   .boost-sd__product-price--default
   *   .boost-sd__product-price--sale
   *   .boost-sd__product-price--compare
   *   .boost-sd__product-price--saving
   */

  html += context.templateRender(getTemplate(context).productPriceTemplate, {
    compareAtPricePosition,
    isSale,
    textAlign,

    priceValueWithFormat,
    compareAtPriceWithFormat,
    savingPriceWithFormat,
  });

  return html;
};

export const transformProductPrice = (context) => {
  if (context.blockType === 'filter') {
    const productList = context.document?.querySelector('.boost-sd__product-list');
    if (productList?.dataset?.productPrice === 'true') {
      return;
    }
  }

  if (context.blockType === 'recommendation') {
    const recommendation = context.document?.querySelector('.boost-sd__recommendation');
    if (recommendation?.dataset?.productPrice === 'true') {
      return;
    }
  }

  const productItems = context.document?.querySelectorAll(
    '.boost-sd__product-item, .boost-sd__product-item-list-view-layout'
  );

  const { showCentAsSuperscript, showCurrencyCodes, compareAtPricePosition, showSavingDisplay } =
    context?.templateSettings?.themeSettings?.productItems?.productInfo?.elements?.price || {};
  const { textAlign } = context?.templateSettings?.themeSettings?.productItems?.productInfo || {};

  // In SSR, field showMultiVariantPrice is removed from themeSettings so we need to check multi variant based on translation
  const productItemAmountTranslation = context.app.translation?.productItem?.amount;
  const productItemSavingAmountTranslation = context.app.translation?.productItem?.savingAmount;

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const { target: productItem } = entry;
        const productPrice = productItem.querySelector('.boost-sd__product-price');

        if (!productPrice) return;

        const { priceMin, priceMax, compareAtPriceMin, compareAtPriceMax } =
          safeParseJSON(productItem.getAttribute('data-product')) || {};
        /** Check if min-max is same value, show one price */
        const isSamePrice =
          `${priceMin}-${compareAtPriceMin}` === `${priceMax}-${compareAtPriceMax}`;

        const regex = /\{\{(?:\s*minPrice\s*|\bminPrice\b)\}}|{{\s*maxPrice\s*|\bmaxPrice\b}}/i;
        const hasMultiVariantPrice = regex?.test(productItemAmountTranslation);

        const noPriceMax = isSamePrice || !hasMultiVariantPrice;

        const priceHTML = mainPrice(
          context,
          priceMin,
          compareAtPriceMin,
          noPriceMax ? null : priceMax,
          {
            showCentAsSuperscript,
            showCurrencyCodes,
            compareAtPricePosition,
            showSavingDisplay,
            textAlign,
            productItemSavingAmountTranslation,
            productItemAmountTranslation,
          }
        );

        // Has price after transformation
        if (priceHTML && priceHTML.match(/\d/)) {
          productPrice.innerHTML = priceHTML;
        }

        observer.unobserve(productItem);
      }
    });
  });

  productItems?.forEach((productItem) => {
    observer.observe(productItem);
  });
};

const trackSelectedProductPage = (context, index, productId) => {
  const pageParam = getQueryParamByKey('page');
  const currPage = pageParam ? Number(pageParam) : 1;
  const limit = context?.defaultParams?.limit || 24;
  const page = Math.ceil((index + 1) / limit) || 1;
  const prevPage = getSessionStorage(PREV_PAGE);
  const paginationType =
    getThemeSettings(context)?.additionalElements?.pagination?.paginationType || DEFAULT;
  const calc = {
    [DEFAULT]: currPage,
    [LOAD_MORE]: prevPage - 1 + page,
    [INFINITE]: prevPage - 1 + page,
  };

  if (!paginationType) return;

  const actualPage = calc[paginationType];

  setSessionStorage(SELECTED_PRODUCT_PAGE, calc[paginationType]);
  setSessionStorage(SELECTED_PRODUCT_ID, productId);

  setQueryParamHistory('page', actualPage.toString(), true);
};

const getIndexOfProductItem = (context, targetItem) => {
  const productItems = context.document.querySelectorAll(
    '.boost-sd__product-list .boost-sd__product-item'
  );

  for (let i = 0; i < productItems.length; i++) {
    if (productItems[i] === targetItem) {
      return i;
    }
  }
  return -1;
};

export const setUniformProductImageHeight = (
  context,
  mobileItemsPerRow,
  desktopItemsPerRow,
  mobileBreakPoint,
  tabletPortraitBreakPoint,
  tabletPortraitItemsPerRow
) => {
  /**
   *   The code appears to adjust the aspect ratio of product image wrappers based on the natural dimensions of the images. It does this by:
   *   1. Querying the product image wrappers and items.
   *   2. Determining the number of items per row based on the screen width and theme settings.
   *   3. Iterating through the product items in chunks of numberProductItemPerRow and setting the aspect ratio of each wrapper based on the maximum ratio found in that chunk.
   *   The code also handles cases where images may not have loaded yet by using the onload and onerror events.
   */

  const adjustProductImageHeights = () => {
    const productImageWrappers = context.document.querySelectorAll(
      '.boost-sd__product-image-wrapper--auto'
    );

    if (productImageWrappers.length === 0) {
      return;
    }

    const productItems = Array.from(context.document.querySelectorAll('.boost-sd__product-item'));

    let numberProductItemPerRow = mobileItemsPerRow;

    if (window.innerWidth > tabletPortraitBreakPoint) {
      numberProductItemPerRow = desktopItemsPerRow;
    } else if (window.innerWidth > mobileBreakPoint) {
      numberProductItemPerRow = tabletPortraitItemsPerRow;
    }

    const setAspectRatioForRow = (itemsInRow) => {
      let maxRatio = 0;

      const updateDimensions = (img) => {
        const { naturalHeight, naturalWidth } = img;

        const ratio = naturalHeight / naturalWidth;
        if (ratio > maxRatio) {
          maxRatio = ratio;
        }

        itemsInRow.forEach((item) => {
          const wrapper = item.querySelector('.boost-sd__product-image-wrapper--auto');
          if (wrapper) {
            wrapper.style.aspectRatio = `1/${maxRatio}`;
          }
        });
      };

      itemsInRow.forEach((item) => {
        const img = item.querySelector('.boost-sd__product-image-wrapper--auto img');
        if (img) {
          if (img.complete) {
            updateDimensions(img);
          } else {
            img.onload = () => updateDimensions(img);
            img.onerror = () => {
              console.error('Failed to load image:', img.src);
            };
          }
        }
      });
    };

    for (let i = 0; i < productItems.length; i += numberProductItemPerRow) {
      const itemsInRow = productItems.slice(i, i + numberProductItemPerRow);
      setAspectRatioForRow(itemsInRow);
    }
  };

  // Adjust heights initially and on resize with debounce
  const debouncedAdjustProductImageHeights = debounce(adjustProductImageHeights, 200);

  window.addEventListener('resize', debouncedAdjustProductImageHeights);

  // Run the function once the DOM is fully loaded
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', adjustProductImageHeights);
  } else {
    adjustProductImageHeights();
    console.log('run equal height');
  }
};
