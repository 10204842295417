export function addB2bAPIParams(params, context) {
  const companyLocationId = `${context.app.b2b.current_company_id}_${context.app.b2b.current_location_id}`;
  const shopifyCurrencySettings = window.Shopify?.currency;

  const currency = shopifyCurrencySettings?.active;
  const currencyRate = shopifyCurrencySettings?.rate;

  if (params instanceof URLSearchParams) {
    params.set('company_location_id', companyLocationId);
    params.set('currency', currency);
    params.set('currency_rate', currencyRate);
  } else {
    params.company_location_id = companyLocationId;
    params.currency = currency;
    params.currency_rate = currencyRate;
  }
}
