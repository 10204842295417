export const FILTER_KEY_PREFIX = 'pf_';
export const FILTER_TAG_KEY = 'pf_tag';
export const FILTER_MOBILE_WRAPPER_SELECTOR = '#boost-sd_filter-tree-mobile';
export const FILTER_DESKTOP_WRAPPER_SELECTOR = '.boost-sd-left';
export const FILTER_MOBILE_ICON_SELECTOR = '#boost-sd__filter-tree-toggle-button';
export const FILTER_MOBILE_FULL_HEIGHT_CLASS = 'boost-sd__filter-option-itemwrapper--full-height';
export const FILTER_MOBILE_FULL_HEIGHT_SELECTOR = `.${FILTER_MOBILE_FULL_HEIGHT_CLASS}`;
export const FILTER_OPTION_SELECTOR = '.boost-sd__filter-option';
export const FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS =
  'boost-sd__filter-option-itemwrapper--hidden';
export const FILTER_OPTION_ITEM_WRAPPER_COLLAPSED_CLASS =
  'boost-sd__filter-option-itemwrapper--collapsed';
export const FILTER_OPTION_TITLE_OPENING_CLASS = 'boost-sd__filter-option-title--opening';
export const FILTER_OPTION_SEARCH_OPTION_SELECTOR = '.boost-sd__filter-option-search-option';
export const FILTER_MOBILE_BUTTON_CLOSE_CLASS =
  '.boost-sd__filter-tree-vertical-mobile-toolbar .boost-sd__button--close';
export const FILTER_MOBILE_BUTTON_BACK_CLASS =
  '.boost-sd__filter-tree-vertical-mobile-toolbar .boost-sd__button--back';
export const FILTER_MOBILE_BUTTON_HIDDEN = 'boost-sd__button--hidden';
export const FILTER_MOBILE_BUTTON_BACK = 'boost-sd__button--back';
export const FILTER_MOBILE_BUTTON_CLOSE = 'boost-sd__button--close';
export const FILTER_TREE_TOGGLE_BUTTON_SELECTOR = '.boost-sd__filter-tree-toggle-button';
export const FILTER_TREE_STATE_KEY = {
  DESKTOP_OPENING: 'filterTreeDesktopOpening',
  CURRENT_FOCUS: 'filterCurrentFocus',
  KEY_CODE: 'filterKeyCode',
};
export const FILTER_TREE_MOBILE_TOOL_BAR = '.boost-sd__filter-tree-vertical-mobile-toolbar';
export const FILTER_TREE_MOBILE_BUTTON_CLEAR =
  '.boost-sd__filter-tree-vertical-mobile-toolbar #boost-sd__button-toolbar-mobile-clear';
export const FILTER_OPTION_DISPLAY_TYPE = {
  LIST: 'list',
  BOX: 'box',
  RANGE: 'range',
  SWATCH: 'swatch',
  RATING: 'rating',
  SUB_CATEGORY: 'sub_category',
  MULTI_LEVEL_COLLECTIONS: 'multi_level_collections',
  MULTI_LEVEL_TAG: 'multi_level_tag',
};

export const FILTER_OPTION_TYPE = {
  COLLECTION: 'collection',
  VENDOR: 'vendor',
  PRODUCT_TYPE: 'product_type',
  TITLE: 'title',
  PRICE: 'price',
  VARIANTS_PRICE: 'variants_price',
  PERCENT_SALE: 'percent_sale',
  REVIEW_RATINGS: 'review_ratings',
  WEIGHT: 'weight',
  STOCK: 'stock',
  TAG: 'tag',
  MULTI_LEVEL_TAG: 'multi_level_tag',
  LOCATION: 'location',
  COLOR: 'opt_color',
  SIZE: 'opt_size',
  PRODUCT_CATEGORY: 'product_category',
};

export const BOOST_SD_COLLECTION_TAGS_KEY = 'boost_sd_collection_tags_key';
export const BOOST_SD_COLLECTION_ID_KEY = 'boost_sd_collection_id_key';
export const COLLECTION_ALL_STORAGE_KEY = 'boostSdCollectionIdAll';
