import { debounce, simpleMd5, debug, stripHtml, xssFilterOption } from '../utils';
import { templateRender } from '../template/templateRender';
import { getTemplate } from '../template';
import { FILTER_OPTION_SELECTOR } from '../constants/filter-tree';
import { getFilterSettings, getSelectedKeysFilter } from '.';
import { addFilterListSelectedClass, removeFilterListSelectedClass } from './option-list';

const FILTER_OPTION_ITEM_SWATCH_TYPE = {
  ONE_COLOR: 'one_color',
  TWO_COLORS: 'two_colors',
  IMAGE: 'image',
};
const SELECTED_CLASS = 'boost-sd__filter-option-swatch-item-img--selected';

export function renderOptionsSwatch(context, dom, option, limit) {
  if (!dom) return;
  const selectedKeys = getSelectedKeysFilter(context);

  const [swatchShape, swatchType] = (option.swatchStyle || 'circle-grid')?.split('-');

  const classNameUnselected = `boost-sd__filter-option-swatch-item-img boost-sd__filter-option-swatch-item-img--${swatchType} boost-sd__filter-option-swatch-item-img--${swatchShape}`;
  const classNameSelected = `${classNameUnselected} ${SELECTED_CLASS}`;

  let html = '';
  const size = limit && option.values.length > limit ? limit : option.values.length;

  const [getActionMapping, setActionMapping] = context.useContextState('actionMapping', {});
  const actionMapping = getActionMapping() || {};
  const values = xssFilterOption(option, size);
  const label = option.label;

  const { showFilterOptionCount: showDocCount } = getFilterSettings(context);

  for (const value of values) {
    let className = classNameUnselected;
    let classButton =
      'boost-sd__filter-option-item-button boost-sd__filter-option-item-button--as-button ';
    if (selectedKeys[`${option.filterOptionId}-${value.key}`?.toString()?.toLowerCase()]) {
      classButton += 'boost-sd__filter-option-item-button--selected';
      className = classNameSelected;

      console.log('className', className, 'classButton', classButton);
    }

    const swatchValue = renderSingleOptionSwatch(context, option, value.key);

    const actionId = simpleMd5(`${option.filterOptionId}-${value.key}`);
    const dataAction = `${option.filterOptionId}.${actionId}`;
    if (!value.label) {
      value.label = value.key;
    }
    const action = {
      optionList: {
        key: option.filterOptionId,
        value: stripHtml(value.key.replace("'", '&apos;').replace('"', '&quot;')),
        label: option.label,
        filterType: option.filterType,
        selectType: option.selectType,
        displayType: option.displayType,
        valueDisplay: value.label || '',
      },
    };

    // assign mapping event
    actionMapping[option.filterOptionId] = actionMapping[option.filterOptionId] || {};
    actionMapping[option.filterOptionId][actionId] = action;

    html += templateRender(getTemplate(context).filterOptionSwatchTemplate, {
      option,
      actionId,
      value,
      label,
      className,
      classButton,
      dataAction,
      swatchValue,
      swatchShape,
      swatchType,
      showDocCount,
    });
  }

  setActionMapping(actionMapping);
  dom.innerHTML = html;

  // create custom events - after render
  const renderFiltered = new CustomEvent('afterRenderHtmlForFilter');
  window.dispatchEvent(renderFiltered);
}

export function renderSingleOptionSwatch(context, option, value) {
  let swatchValue = buildSwatchSettings(context, value);

  // If don't have swatch settings, fallback to default file names
  if (!swatchValue?.hasSwatchSetting) {
    swatchValue = {};
    // Not build backgroundImage when hasSwatchSetting == false
    // swatchValue.swatchFileName = buildSwatchFileName(option, value);
    // swatchValue.swatchFileUrl = getFilePath(swatchValue.swatchFileName, 'jpg', 1111111);
    // swatchValue.backgroundImage = 'url(' + swatchValue.swatchFileUrl + ');';
    swatchValue.backgroundColor = buildBackgroundColor(value);
  }

  swatchValue.swatchBorder = ['white', '#FFFFFF'].includes(swatchValue.backgroundColor)
    ? 'has-border'
    : '';
  return swatchValue;
}

/**
 * Build swatch file name based on
 * parent.filterOptionId, parent.prefix, and this.label
 * @returns {string} - The swatch file name
 */
function buildSwatchFileName(option, value) {
  let swatchName = value;

  // Get prefix of swatch from filter option id
  const swatchFileNamePrefix = option.filterOptionId.trim().toLowerCase().replace(/ /g, '_');

  return swatchFileNamePrefix + '-' + swatchName.replace(/#/g, '');
}

export function buildSwatchSettings(context, value) {
  const { swatch_settings: swatchSettings } = getFilterSettings(context);
  if (!swatchSettings) return {};

  let swatchValue = { hasSwatchSetting: false };
  let swatchKeys = Object.keys(swatchSettings);
  if (Array.isArray(swatchKeys) && swatchKeys.length > 0) {
    // Duplicate trimmable item
    swatchKeys.forEach(function (key) {
      if (key.toString().trim() !== key.toString()) {
        var _key = key.toString().trim();
        var swatch = swatchSettings[key];
        swatch['name'] = _key;
        swatchSettings[_key] = swatch;
        swatchKeys.push(_key);
      }
    });
    const swatchSettingKey = swatchKeys.find(
      (key) =>
        key.replace('pfs-swatch-', '') == value ||
        key.replace('pfs-swatch-', '').toLowerCase() == value.toLowerCase()
    );
    swatchValue = swatchSettings[swatchSettingKey];
  }

  if (swatchSettings && swatchValue && Object.keys(swatchValue).length > 1) {
    switch (swatchValue.type) {
      case FILTER_OPTION_ITEM_SWATCH_TYPE.ONE_COLOR:
        if (swatchValue.colorCodes.length > 0 && swatchValue.colorCodes[0]) {
          swatchValue.backgroundImage = 'none';
          swatchValue.backgroundColor = swatchValue.colorCodes[0];
          swatchValue.hasSwatchSetting = true;
        }
        break;
      case FILTER_OPTION_ITEM_SWATCH_TYPE.TWO_COLORS:
        if (
          swatchValue.colorCodes.length > 1 &&
          swatchValue.colorCodes[0] &&
          swatchValue.colorCodes[1]
        ) {
          swatchValue.backgroundImage =
            'linear-gradient(to top left, ' +
            swatchValue.colorCodes[1] +
            ' 50%, ' +
            swatchValue.colorCodes[0] +
            ' 50%);';
          swatchValue.backgroundColor = 'transparent';
          swatchValue.hasSwatchSetting = true;
        }
        break;
      case FILTER_OPTION_ITEM_SWATCH_TYPE.IMAGE:
        if (swatchValue.imageUrl) {
          swatchValue.backgroundImage = 'url(' + swatchValue.imageUrl + ');';
          swatchValue.backgroundColor = buildBackgroundColor(swatchValue.key); // Fallback color for image
          swatchValue.hasSwatchSetting = true;
        }
        break;
      default:
        break;
    }
  }

  return swatchValue;
}

export function buildBackgroundColor(label) {
  if (typeof label === 'undefined') return '';
  var splitColorArr = label.split('-');
  return splitColorArr[splitColorArr.length - 1];
}

export function lazyLoadOptionSwatch(context, dom, id, option) {
  const domMouseMove = dom?.closest(FILTER_OPTION_SELECTOR);

  const mousemoveRender = debounce(() => {
    debug(context);
    renderFullOptionSwatch(context, dom, id, option);

    domMouseMove.removeEventListener('mousemove', mousemoveRender);
    domMouseMove.removeEventListener('touchstart', mousemoveRender);
  }, 200);

  domMouseMove && domMouseMove.addEventListener('mousemove', mousemoveRender);
  domMouseMove && domMouseMove.addEventListener('touchstart', mousemoveRender);
}

/**
 * render full option swatch
 * @param context
 * @param dom
 * @param id
 * @param option
 */
export const renderFullOptionSwatch = (context, dom, id, option) => {
  if (
    context.filterTreeViewPort &&
    context.filterTreeViewPort[id] &&
    context.filterTreeViewPort[id].loaded < context.filterTreeViewPort[id].total &&
    context.filterTreeViewPort[id].displayType === 'swatch'
  ) {
    renderOptionsSwatch(context, dom, option, context.filterTreeViewPort[id].total);
    context.filterTreeViewPort[id] = {
      loaded: context.filterTreeViewPort[id].total,
      total: option.values.length,
      displayType: 'list',
    };
  }
};

export const addFilterSwatchSelectedClass = (context, key, value) => {
  // add general list
  addFilterListSelectedClass(context, key, value);

  // add special for watch
  const actionId = simpleMd5(`${key}-${value}`);
  const domElement = context.$(
    `.boost-sd__filter-option-swatch-item-img`,
    context.$(`#${actionId}`)
  );

  domElement && domElement.classList.add(SELECTED_CLASS);
};

export const removeFilterSwatchSelectedClass = (context, key, value) => {
  // remove general list
  removeFilterListSelectedClass(context, key, value);

  // remove special for swatch
  const actionId = simpleMd5(`${key}-${value}`);
  const domElement = context.$(
    `.boost-sd__filter-option-swatch-item-img`,
    context.$(`#${actionId}`)
  );
  domElement && domElement.classList.remove(SELECTED_CLASS);
};
