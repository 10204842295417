import { restrictMainCurrency } from '../shopify-market/restrict-user.js';
import { addParamsLocale, getTenantId } from '../utils/index.js';
export const predictiveBundleByProductId = async (context, productId) => {
  if (!productId) return [];

  let params = {
    product_id: productId,
    shop: getTenantId(),
    country: Shopify.country || '',
  };

  params = addParamsLocale(params);

  if (context.app.b2b?.enabled) {
    addB2bAPIParams(params, context);
  }

  let queryParams = '';

  // build query params
  Object.keys(params).map((key) => {
    queryParams += (queryParams ? '&' : '') + key + '=' + params[key];
  });

  const response = await fetch(`${context?.app?.bundleUrl}?${queryParams}`);
  if (response.status === 200) {
    const data = await response.json();

    if (data.meta) {
      const { money_format, money_format_with_currency, currency } = data.meta;
      restrictMainCurrency(context, { money_format, money_format_with_currency, currency });
    }

    return data?.bundles || [];
  }

  return [];
};
