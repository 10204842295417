import { KEYS } from '../constants/keys';
let keyboardActivated = false;

// Detect when the user interacts using the keyboard globally
document.addEventListener('keydown', (event) => {
  if (KEYS.includes(event.key)) {
    keyboardActivated = true;
  }
});

// Reset the flag after any mouse or touch interaction
document.addEventListener('mousedown', () => {
  keyboardActivated = false;
});

document.addEventListener('touchstart', () => {
  keyboardActivated = false;
});

export const isKeyboardActivated = () => keyboardActivated;
