import { getTemplateByIds } from '../../api/template';
import { getThemeSettings } from '../../utils/settings';
import { getParamsHistory, getLocalStorage } from '../../utils';

const utils = { getParamsHistory, getLocalStorage };

export const renderCollectionHeader = async (context) => {
  const { additionalElements } = getThemeSettings(context);
  if (!additionalElements?.collectionHeader?.isEnable) {
    return;
  }

  const id = context.app.templateMetadata?.collectionHeader || 'collection_header_id';
  const templatePromise = getTemplateByIds(context, context.app.templateMetadata.themeNameLib, [
    {
      name: 'collectionHeader',
      id,
    },
  ]);
  const collectionPromise = getCollectionData(context);
  const htmlPromise = Promise.all([templatePromise, collectionPromise]).then(async (list) => {
    const templates = list[0].templates;
    const collection = list[1];
    return context.templateRender(templates[id], collection);
  });
  context.render(() => {
    htmlPromise.then((html) => {
      const dom = context.$('#boost-sd__collection-header');
      if (dom) {
        dom.innerHTML = html;
      }
    });
  }, ['filterTree']);
};

const getCollectionData = async (context, params = {}) => {
  const { productList } = getThemeSettings(context);
  const productPerPage = productList?.productsPerPage || 16;
  const selectedValueOfLimitList = getLocalStorage('boostSDLimit') || productPerPage;
  const limitSetting = getLocalStorage('boostSDLimitSetting');
  const isLimitSettingEqual = limitSetting === productPerPage;
  const defaultLimit = 16;

  const limit = isLimitSettingEqual
    ? parseInt(selectedValueOfLimitList, 10)
    : productPerPage || defaultLimit;
  const page = params.page || getParamsHistory().page || 1;

  const url = `${window.location.origin}${window.location.pathname}.json`;
  const queryParams = new URLSearchParams({ limit, page });

  const { collection_id } = context.app?.generalSettings;
  if (!collection_id) {
    return {
      title: context.app?.translation?.collectionHeader?.collectionAllProduct || 'Products',
      products_count: context.app?.generalSettings?.collection_product_count,
      request: {
        limit,
        page,
      },
    };
  }

  return fetch(`${url}?${queryParams.toString()}`).then(async (res) => {
    if (res.status === 200) {
      const toJson = await res.json();
      if (toJson.collection.image?.src) {
        const tempImg = new Image();
        tempImg.src = toJson.collection.image?.src; // cache image before inject collection header into dom
      }
      return {
        ...toJson.collection,
        request: {
          limit,
          page,
        },
      };
    }

    return {
      title: context.app?.translation?.collectionHeader?.collectionAllProduct || 'Products',
      products_count: context.app?.generalSettings?.collection_product_count,
      request: {
        limit,
        page,
      },
    };
  });
};
