import { CartAPI } from '../api/cart';
import { getCartSelectorAndUpdateAction } from '../cart/selectors';

export const handleBulkQuantity = async (context) => {
  const bulkQuantityContainers = document.querySelectorAll('.boost-sd__bulk-quantity');
  const data = await CartAPI.getAll();

  const [getForceRender, setForceRender] = context.useContextState('force-render', false);

  const handleQuantityChange = async (variantId, quantity) => {
    const data = await CartAPI.getAll();
    const item = data.items.find((item) => item.variant_id === parseInt(variantId, 10));

    if (quantity === 0 || !item) {
      await CartAPI.add(variantId, quantity);
    } else {
      await CartAPI.change(variantId, quantity);
    }

    setForceRender(!getForceRender);
  };

  const handleQuantityInput = (quantityInput, progressBarContainer, minQuantity, variantId) => {
    quantityInput.addEventListener('change', async () => {
      let currentQuantity = parseInt(quantityInput.value, 10);

      if (currentQuantity < minQuantity) {
        quantityInput.setCustomValidity(`Please enter a quantity of at least ${minQuantity}`);
        quantityInput.reportValidity();
      } else {
        quantityInput.setCustomValidity('');
      }

      progressBarContainer.classList.remove('boost-sd__progress-bar-container--hidden');

      if (Number.isInteger(currentQuantity) && currentQuantity > 0) {
        await handleQuantityChange(variantId, currentQuantity);
      }

      progressBarContainer.classList.add('boost-sd__progress-bar-container--hidden');
    });
  };

  const handleButtonClick = (
    quantityInput,
    progressBarContainer,
    decreaseButton,
    quantityStep,
    variantId,
    operation
  ) => {
    const handleButtonClickEvent = async (event) => {
      const currentQuantity = parseInt(quantityInput.value, 10);

      if (operation === 'decrease') {
        if (currentQuantity >= quantityStep) {
          quantityInput.value = currentQuantity - quantityStep;
        } else {
          quantityInput.value = 0;
        }
      } else if (operation === 'increase') {
        quantityInput.value = currentQuantity + quantityStep;
      }

      progressBarContainer.classList.remove('boost-sd__progress-bar-container--hidden');

      if (currentQuantity === 0) {
        decreaseButton.disabled = true;
        decreaseButton.setAttribute('disabled', 'disabled');
      } else {
        decreaseButton.disabled = false;
        decreaseButton.removeAttribute('disabled');
      }

      await handleQuantityChange(variantId, parseInt(quantityInput.value, 10));

      progressBarContainer.classList.add('boost-sd__progress-bar-container--hidden');
    };

    return handleButtonClickEvent;
  };

  bulkQuantityContainers.forEach((container) => {
    const decreaseButton = container.querySelector('.boost-sd__bulk-quantity-button--decrease');
    const increaseButton = container.querySelector('.boost-sd__bulk-quantity-button--increase');
    const quantityInput = container.querySelector('.boost-sd__bulk-quantity-field');
    const progressBarContainer = container.querySelector('.boost-sd__progress-bar-container');
    const quantityStep = parseInt(quantityInput.getAttribute('step'), 10);
    const minQuantity = parseInt(quantityInput.getAttribute('min'), 10);
    const variantId = container.getAttribute('data-quantity-variant-id');
    const item = data.items.find((item) => item.variant_id === parseInt(variantId, 10));

    if (item) {
      quantityInput.value = item.quantity;
    } else {
      quantityInput.value = 0;
    }

    if (quantityInput.value === '0') {
      decreaseButton.disabled = true;
      decreaseButton.setAttribute('disabled', 'disabled');
    } else {
      decreaseButton.disabled = false;
      decreaseButton.removeAttribute('disabled');
    }

    handleQuantityInput(quantityInput, progressBarContainer, minQuantity, variantId);

    decreaseButton.addEventListener(
      'click',
      handleButtonClick(
        quantityInput,
        progressBarContainer,
        decreaseButton,
        quantityStep,
        variantId,
        'decrease'
      )
    );
    increaseButton.addEventListener(
      'click',
      handleButtonClick(
        quantityInput,
        progressBarContainer,
        decreaseButton,
        quantityStep,
        variantId,
        'increase'
      )
    );
  });

  context.render(async () => {
    const data = await CartAPI.getAll();
    const itemCount = data.items.reduce((acc, item) => acc + item.quantity, 0);

    const cartSelectorAndUpdateAction = getCartSelectorAndUpdateAction();
    cartSelectorAndUpdateAction?.action(itemCount);

    bulkQuantityContainers.forEach((container) => {
      const variantId = container.getAttribute('data-quantity-variant-id');
      const item = data.items.find((item) => item.variant_id === parseInt(variantId, 10));

      if (item) {
        const quantityInput = container.querySelector('.boost-sd__bulk-quantity-field');
        quantityInput.value = item.quantity;
      }
    });
  }, ['force-render']);
};
