import { debounce, simpleMd5, debug, stripHtml, xssFilterOption } from '../utils'; // simpleMd5
import { getTemplate } from '../template';
import { FILTER_OPTION_SELECTOR, FILTER_TAG_KEY } from '../constants/filter-tree';
import { getSelectedKeysFilter } from '.';

export function renderOptionMultiLevelCollection(context, dom, option, limit) {
  if (!dom) return;
  const selectedKeys = getSelectedKeysFilter(context);
  if (context.app.generalSettings.collection_id) {
    selectedKeys[`${option.filterOptionId}-${context.app.generalSettings.collection_id}`] = true;
  }

  if (context.app.generalSettings?.collection_tags?.length > 0) {
    selectedKeys[`${FILTER_TAG_KEY}-${context.app.generalSettings?.collection_tags[0]}`] = true;
  }

  const classNameUnselected = `boost-sd__filter-option-item-button boost-sd__filter-option-item-button--as-link`;
  const classNameSelected = classNameUnselected + ' boost-sd__filter-option-item-button--selected';

  let html = '';
  const size = limit && option.values.length > limit ? limit : option.values.length;
  const values = xssFilterOption(option, size);
  for (const value of values) {
    const actionId = simpleMd5(`${option.filterOptionId}-${value.key}`);
    const isDisabled = option.keepValuesStatic && value.doc_count === 0;
    let className = classNameUnselected;
    value.isDisabled = isDisabled;

    if (selectedKeys[`${option.filterOptionId}-${value.key}`]) {
      className = classNameSelected;
    }

    const metaData = {
      action: {
        optionCollection: {
          key: option.filterOptionId,
          value: value.key,
          label: option.label,
          filterType: option.filterType,
          displayName: value.displayName || value.label,
          handle: value.handle,
          actionId,
        },
      },
    };

    if (value.tags?.length > 0) {
      value.existedTags = true;
      const actionIdArrow = simpleMd5(`${option.filterOptionId}-arrow-${value.key}`);
      const targetIdList = simpleMd5(`${option.filterOptionId}-target-${value.key}`);
      const targetIdArrow = simpleMd5(`${option.filterOptionId}-target-arrow-${value.key}`);

      value.actionIdArrow = actionIdArrow;
      value.targetIdList = targetIdList;
      value.targetIdArrow = targetIdArrow;

      value.metaDataArrow = {
        action: {
          expandCollapseMultiLevel: {
            actionId: actionIdArrow,
            targets: [
              {
                targetId: targetIdList,
                classToggle: 'boost-sd__g-hide',
                shouldAddClass: true,
              },
              {
                targetId: targetIdArrow,
                classToggle: 'boost-sd__arrow-icon--down',
                shouldAddClass: false,
              },
            ],
          },
        },
      };

      value.tags.forEach((_tag) => {
        // assign actionId and metadata level2
        _tag.actionId = simpleMd5(`${FILTER_TAG_KEY}-${_tag.tag}`);
        _tag.targetId = simpleMd5(`${FILTER_TAG_KEY}-target-${_tag.tag}`);

        _tag.metaData = {
          action: {
            optionCollection: {
              key: option.filterOptionId,
              value: value.key,
              displayName: value.displayName,
              collectionTag: stripHtml(_tag.tag),
              label: option.label,
              handle: value.handle,
              tagDisplayName: _tag.displayName,
              filterType: option.filterType,
              valueDisplay: _tag.label || '',
              actionId: _tag.actionId,
            },
          },
        };

        _tag.className = classNameUnselected;
        if (selectedKeys[`${FILTER_TAG_KEY}-${_tag?.tag?.toLowerCase()}`]) {
          _tag.className = classNameSelected;
        }

        if (_tag.subTags?.length > 0) {
          _tag.existedSubTags = true;
          const actionIdArrowLevel2 = simpleMd5(
            `${option.filterOptionId}-arrow-level2-${_tag.tag}`
          );
          const targetIdListLevel2 = simpleMd5(
            `${option.filterOptionId}-target-level2-${_tag.tag}`
          );
          const targetIdArrowLevel2 = simpleMd5(
            `${option.filterOptionId}-target-arrow-level2-${_tag.tag}`
          );

          _tag.actionIdArrow = actionIdArrowLevel2;
          _tag.targetIdList = targetIdListLevel2;
          _tag.targetIdArrow = targetIdArrowLevel2;

          _tag.metaDataArrow = {
            action: {
              expandCollapseMultiLevel: {
                actionId: actionIdArrowLevel2,
                targets: [
                  {
                    targetId: targetIdListLevel2,
                    classToggle: 'boost-sd__g-hide',
                    shouldAddClass: true,
                  },
                  {
                    targetId: targetIdArrowLevel2,
                    classToggle: 'boost-sd__arrow-icon--down',
                    shouldAddClass: false,
                  },
                ],
              },
            },
          };

          _tag.subTags.forEach((_subTag) => {
            // assign actionId and metadata level2
            _subTag.actionId = simpleMd5(`${FILTER_TAG_KEY}-${_subTag.tag}`);
            _subTag.targetId = simpleMd5(`${FILTER_TAG_KEY}-target-${_subTag.tag}`);
            _subTag.metaData = {
              action: {
                optionCollection: {
                  key: option.filterOptionId,
                  value: value.key,
                  displayName: value.displayName,
                  collectionTag: stripHtml(_subTag.tag),
                  label: option.label,
                  handle: value.handle,
                  tagDisplayName: _subTag.displayName,
                  filterType: option.filterType,
                  valueDisplay: _subTag.label || '',
                  actionId: _subTag.actionId,
                },
              },
            };

            _subTag.className = classNameUnselected;
            if (selectedKeys[`${FILTER_TAG_KEY}-${_subTag?.tag?.toLowerCase()}`]) {
              _subTag.className = classNameSelected;
            }
          });
        }
      });
    }

    html += context.templateRender(getTemplate(context).filterOptionMultiLevelCollection, {
      option,
      actionId,
      metaData,
      value,
      className,
      simpleMd5,
    });
  }
  dom.innerHTML = html;

  // create custom events - after render
  const renderFiltered = new CustomEvent('afterRenderHtmlForFilter');
  window.dispatchEvent(renderFiltered);
}

export function lazyLoadOptionMultiLevelCollection(context, dom, id, option) {
  const domMouseMove = dom?.closest(FILTER_OPTION_SELECTOR);

  const mousemoveRender = debounce(() => {
    debug(context);

    renderFullOptionMultiLevelCollection(context, dom, id, option);

    domMouseMove.removeEventListener('mousemove', mousemoveRender);
    domMouseMove.removeEventListener('touchstart', mousemoveRender);
  }, 200);

  domMouseMove && domMouseMove.addEventListener('mousemove', mousemoveRender);
  domMouseMove && domMouseMove.addEventListener('touchstart', mousemoveRender);
}

/**
 * render full option Multi Level Collection
 * @param context
 * @param dom
 * @param id
 * @param option
 */
export function renderFullOptionMultiLevelCollection(context, dom, id, option) {
  if (
    context.filterTreeViewPort &&
    context.filterTreeViewPort[id] &&
    context.filterTreeViewPort[id].loaded < context.filterTreeViewPort[id].total &&
    context.filterTreeViewPort[id].displayType === 'list'
  ) {
    renderOptionMultiLevelCollection(context, dom, option, context.filterTreeViewPort[id].total);
    context.filterTreeViewPort[id] = {
      loaded: context.filterTreeViewPort[id].total,
      total: option.values.length,
      displayType: 'list',
    };
  }
}
