import { resetFilterRequest } from '../api/filter';
import {
  clearAllParamHistory,
  debounce,
  debug,
  getParamsHistory,
  isMobile,
  scrollToTop,
  updateValuesOptions,
  simpleMd5,
  stripHtml,
  removeQueryParamsHistory,
  deleteQueryParamsHistory,
  inViewPortHandler,
  translateByKey,
  isSearchPage,
  requestIdleCallback,
} from '../utils';

import {
  generateFilterDataForList,
  lazyLoadOptionList,
  removeFilterSelectedClass,
  renderFullOptionList,
  renderOptionList,
} from './option-list';
import { generateFilterDataForRange, renderRangeSlider } from './option-range-slider';
import { renderRefineBy } from './refine-by';
import { lazyLoadOptionBox, renderFullOptionBox, renderOptionBox } from './option-box';
import { lazyLoadOptionSwatch, renderFullOptionSwatch, renderOptionsSwatch } from './option-swatch';
import { lazyLoadOptionRating, renderFullOptionRating, renderOptionRating } from './option-rating';
import {
  lazyLoadOptionMultiLevelTag,
  renderFullOptionMultiLevelTag,
  renderOptionMultiLevelTag,
} from './option-multi-level-tag';
import {
  lazyLoadOptionMultiLevelCollection,
  renderFullOptionMultiLevelCollection,
  renderOptionMultiLevelCollection,
} from './option-multi-level-collection';
import {
  FILTER_DESKTOP_WRAPPER_SELECTOR,
  FILTER_MOBILE_FULL_HEIGHT_CLASS,
  FILTER_MOBILE_FULL_HEIGHT_SELECTOR,
  FILTER_MOBILE_WRAPPER_SELECTOR,
  FILTER_OPTION_ITEM_WRAPPER_COLLAPSED_CLASS,
  FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS,
  FILTER_OPTION_SEARCH_OPTION_SELECTOR,
  FILTER_OPTION_TITLE_OPENING_CLASS,
  FILTER_MOBILE_BUTTON_CLOSE_CLASS,
  FILTER_MOBILE_BUTTON_BACK_CLASS,
  FILTER_MOBILE_BUTTON_BACK,
  FILTER_MOBILE_BUTTON_CLOSE,
  FILTER_MOBILE_BUTTON_HIDDEN,
  FILTER_TAG_KEY,
  FILTER_TREE_STATE_KEY,
  FILTER_OPTION_SELECTOR,
  FILTER_TREE_MOBILE_BUTTON_CLEAR,
  FILTER_TREE_MOBILE_TOOL_BAR,
} from '../constants/filter-tree.js';
import { renderRecommendationForSearchPage } from '../dynamicBundle/index.js';
import {
  getCollectionIdByHandleKey,
  getCollectionsTagFromSlugKey,
  saveCollectionIdByHandle,
} from './option-collection.js';

const LIMIT_FIRST_LOAD = 30;
const COLLAPSE_STATE = 'collapseState';

/**
 * Renders the filter tree UI based on the filter tree options state.
 * Calls lazyLoadFilterTree() to initialize loading the options.
 * Renders the filter options that are active.
 * For list options, checks if they are in viewport and renders or lazy loads accordingly.
 */
export function handleFilterTree(context) {
  window.addEventListener(
    'resize',
    debounce(function () {
      handleFilterTreeResponsive(context);
    }, 100)
  );
  context.render(
    function renderFilterTreeOnStateChanged() {
      lazyLoadFilterTree(context);
      handleFilterTreeResponsive(context);
      if (!isMobile(context.app.generalSettings.isTabletPortraitMax)) {
        console.log('render filter tree from handleFilterTree');
        renderFilterTree(context);
      }

      // register when search/filter in search page
      if (isSearchPage()) {
        renderRecommendationForSearchPage(context);
      }

      const { collection_handle, collection_id, page } =
        boostWidgetIntegration?.generalSettings || {};
      if (page === 'collection') {
        saveCollectionIdByHandle(collection_handle, collection_id);
      }
    },
    ['filterTree']
  );

  context.render(() => {
    renderRefineBy(context);
  }, ['filter']);

  window.addEventListener('afterRenderHtmlForFilter', function (e) {
    if (isEnterOrSpaceKeyPressed(context)) {
      const [getCurrentFocus] = context.useContextState(FILTER_TREE_STATE_KEY.CURRENT_FOCUS, '');
      const currentFocus = getCurrentFocus();
      const currentFocusElement = document.getElementById(currentFocus);

      const { filterLayout, filterTreeHorizontalStyle } = getFilterSettings(context);

      if (filterLayout === 'horizontal' && filterTreeHorizontalStyle != 'style-expend') {
        currentFocusElement
          ?.closest('.boost-sd__filter-option')
          ?.querySelector('.boost-sd__filter-option-title')
          ?.focus();
      } else {
        currentFocusElement?.setAttribute('tabindex', '0');
        currentFocusElement?.focus();
      }
    }
  });
}

export const getSelectedKeysFilter = (context) => {
  const [getFilter] = context.useContextState('filter', []);
  let selectedFilters = getFilter();
  selectedFilters = Array.isArray(selectedFilters) ? selectedFilters : [];

  const selectedKeys = selectedFilters.reduce(
    (obj, val) => ({ ...obj, [`${val.data.key}-${val.data.value}`.toLowerCase()]: true }),
    {}
  );

  return selectedKeys;
};

export const getSelectedFilter = (context) => {
  const [getFilter] = context.useContextState('filter', []);
  let selectedFilters = getFilter();
  selectedFilters = Array.isArray(selectedFilters)
    ? selectedFilters?.filter((f) => f && f.data)
    : [];

  return selectedFilters;
};

export const isFilterOptionSupported = (displayType) => {
  return ['list', 'box', 'swatch', 'range', 'rating', 'multi_level_collections'].includes(
    displayType
  );
};

export const renderFilterOption = (context, dom, option, limit) => {
  switch (true) {
    case option.displayType === 'multi_level_collections':
      renderOptionMultiLevelCollection(context, dom, option, limit);
      break;

    case option.displayType === 'list' && option.filterType === 'multi_level_tag':
      renderOptionMultiLevelTag(context, dom, option, limit);
      break;

    case option.displayType === 'list' && option.filterType !== 'multi_level_tag':
      renderOptionList(context, dom, option, limit);
      break;

    case option.displayType === 'box':
      renderOptionBox(context, dom, option, limit);
      break;

    case option.displayType === 'swatch':
      renderOptionsSwatch(context, dom, option, limit);
      break;

    case option.displayType === 'rating':
      renderOptionRating(context, dom, option, limit);
      break;

    case option.displayType === 'range':
      renderRangeSlider(context, dom, option);
      break;

    default:
      console.log('renderFilterOption not cover', option);
      break;
  }
};

export const renderFullyFilterOption = (context, dom, option) => {
  switch (true) {
    case option.displayType === 'multi_level_collections':
      renderFullOptionMultiLevelCollection(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'list' && option.filterType === 'multi_level_tag':
      renderFullOptionMultiLevelTag(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'list' && option.filterType !== 'multi_level_tag':
      renderFullOptionList(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'box':
      renderFullOptionBox(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'swatch':
      renderFullOptionSwatch(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'rating':
      renderFullOptionRating(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'range':
      renderRangeSlider(context, dom, option);
      break;

    default:
      console.log(`renderFullyFilterOption not cover type ${option.displayType}`);
      break;
  }
};

export const renderLazyFilterOption = (context, dom, option) => {
  switch (true) {
    case option.displayType === 'multi_level_collections':
      lazyLoadOptionMultiLevelCollection(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'list' && option.filterType === 'multi_level_tag':
      lazyLoadOptionMultiLevelTag(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'list' && option.filterType !== 'multi_level_tag':
      lazyLoadOptionList(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'box':
      lazyLoadOptionBox(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'swatch':
      lazyLoadOptionSwatch(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'rating':
      lazyLoadOptionRating(context, dom, option.filterOptionId, option);
      break;

    case option.displayType === 'range':
      renderRangeSlider(context, dom, option);
      break;

    default:
      console.log('renderLazyFilterOption not cover', option.displayType);
      break;
  }
};

const buttonClearOrClearAll = (context, type = 'clearAll', optionId) => {
  const buttonClear = document.createElement('button');
  buttonClear.type = 'button';
  buttonClear.id = 'boost-sd__button-toolbar-mobile-clear';
  buttonClear.className = 'boost-sd__button boost-sd__button--clear';

  if (type === 'clear' && optionId) {
    buttonClear.textContent = translateByKey(context, 'clear', 'Clear');
    buttonClear.setAttribute(
      'data-metadata',
      `{ "action": { "resetFilterOption": { "key": "${optionId}" }}}`
    );
  } else {
    buttonClear.textContent = translateByKey(context, 'clearAll', 'Clear All');
    buttonClear.setAttribute('data-metadata', '{ "action": { "clearAllFilter": "" }}');
  }

  return buttonClear;
};

export const renderFilterTree = (context) => {
  // add log monitoring render filter tree
  console.log(`Trigger call when filter tree render in ${isMobile() ? 'mobile' : 'desktop'}`);
  const { $, useContextState } = context;
  const [getFilterTreeOption] = useContextState('filterTree', {});
  const [getLatestFilterSearchRequest] = useContextState(
    'latestFilterSearchRequest',
    context.latestFilterSearchRequest
  );
  context.filterTreeViewPort = {
    key: getLatestFilterSearchRequest(),
  };

  // Name is showSingleOption but data return from BE hideSingOption
  const {
    showOutOfStockOption,
    showSingleOption: hideSingleOption,
    filterLayout,
    filterTreeHorizontalStyle,
    filterTreeVerticalStyle,
    filterTreeMobileStyle,
  } = getFilterSettings(context);

  // issue: PFSN-53876 re-calc current position when open style1, style2 horizontal - adapt left or right
  if (['style1', 'style2'].includes(filterTreeHorizontalStyle) && filterLayout === 'horizontal') {
    const filterOptions = document.querySelectorAll(
      `#boost-sd__filter-tree-wrapper .boost-sd__filter-option--${filterTreeHorizontalStyle}.boost-sd__filter-option--horizontal`
    );
    if (filterOptions?.length > 0) {
      const WIDTH_WRAPPER_OPTION_STYLE1_HORIZONTAL = 240; // px
      const WIDTH_WRAPPER_OPTION_STYLE2_HORIZONTAL = 532; // px

      const widthFilterOptionStyle =
        filterTreeHorizontalStyle === 'style1'
          ? WIDTH_WRAPPER_OPTION_STYLE1_HORIZONTAL
          : WIDTH_WRAPPER_OPTION_STYLE2_HORIZONTAL;

      filterOptions?.forEach((filterOption) => {
        const currentFilterOption = filterOption?.getBoundingClientRect();

        const wrapper = filterOption?.querySelector(
          `.boost-sd__filter-option-itemwrapper--${filterTreeHorizontalStyle}`
        );
        if (
          window.innerWidth / 2 < currentFilterOption?.left &&
          window.innerWidth - currentFilterOption?.left < widthFilterOptionStyle
        ) {
          filterOption?.setAttribute('style', 'position: relative');
          wrapper?.setAttribute('style', 'right: 0');
        }
      });
    }
  }

  const [getFilterOptionIdMobileSelected] = context.useContextState(
    'filterOptionIdMobileSelected',
    ''
  );
  const { level, key: currentFilterOptionIdMobile } = getFilterOptionIdMobileSelected();

  const settingOptionValues = {
    showOutOfStockOption: showOutOfStockOption,
    hideSingleOption,
  };

  let options = updateValuesOptions(getFilterTreeOption()?.options, settingOptionValues, context);
  const isMobileStyle2 =
    ['style2', 'style2-fullwidth'].includes(filterTreeMobileStyle) &&
    isMobile(context.app.generalSettings.isTabletPortraitMax);

  if (options) {
    options = options.filter(
      (option) =>
        option.status === 'active' && option.values && Object.values(option.values).length > 0
    );
    options.forEach(function cbRenderFilterOption(option) {
      // not render option not have values
      if (option.values && isFilterOptionSupported(option.displayType)) {
        if (isMobileStyle2) {
          if (currentFilterOptionIdMobile === option.filterOptionId) {
            // title refine by mobile
            const refineByTitle = context.$(
              '.boost-sd__filter-tree-vertical-toolbar-middle',
              FILTER_MOBILE_WRAPPER_SELECTOR
            );

            refineByTitle.innerHTML = option.label;
          }
        }
        //build color for title filter option
        const filterTreeParent = getFilterTreeParent(context);
        const filterOptionIdEncodeMetaField = option.filterOptionId.replace(/::/g, '__');

        const optionDom = $(`#${filterOptionIdEncodeMetaField}`, filterTreeParent);
        const rect = optionDom ? optionDom.getBoundingClientRect() : null;
        if (rect && rect.top < window.innerHeight && rect.bottom >= 0) {
          if (optionDom) {
            const firstLoad =
              option.values?.length < LIMIT_FIRST_LOAD ? option.values?.length : LIMIT_FIRST_LOAD;
            context.filterTreeViewPort[option.filterOptionId] = {
              loaded: firstLoad,
              total: option.values?.length,
              displayType: option.displayType,
            };
            renderFilterOption(context, optionDom, option, firstLoad);
          }
        } else {
          context.filterTreeViewPort[option.filterOptionId] = {
            loaded: 0,
            total: option.values?.length,
            displayType: option.displayType,
          };
        }

        if (!optionDom) {
          return;
        }
        if (!isMobile(context.app.generalSettings.isTabletPortraitMax)) {
          if (
            (filterLayout === 'vertical' &&
              ['style-expand', 'style-off-canvas'].includes(filterTreeVerticalStyle)) ||
            (filterLayout === 'horizontal' && filterTreeHorizontalStyle === 'style-expand')
          ) {
            renderFilterOption(
              context,
              optionDom,
              option,
              Math.min(LIMIT_FIRST_LOAD, option.values?.length)
            );
          }
        }

        renderLazyFilterOption(context, optionDom, option);

        // bind event input on search option
        const searchOptionByFilterOption = context.$(
          `#boost-sd__search-option-${filterOptionIdEncodeMetaField}`,
          filterTreeParent
        );
        if (searchOptionByFilterOption) {
          searchOptionByFilterOption.addEventListener(
            'input',
            debounce(function (e) {
              // deep copy object
              const _optionCopy = JSON.parse(JSON.stringify(option));
              const value = e.target.value?.toLowerCase();

              // special case -> collection key = displayName
              let key = 'key';
              if (option.filterType === 'collection') {
                key = 'displayName';
              }

              _optionCopy.values = _optionCopy.values.filter((item) =>
                item[key]?.toString()?.toLowerCase()?.includes(value)
              );

              renderFilterOption(context, optionDom, _optionCopy, option.values?.length);
            }, 200)
          );
        }
      }
    });

    debug(context);
  }

  // bind clearAll button to FilterTree Mobile
  if (isMobileStyle2) {
    const filterSelected = getSelectedFilter(context);
    let buttonClear = '';

    if (level === 1 || !level) {
      buttonClear = buttonClearOrClearAll(context, 'clearAll');
    } else if (level === 2 && currentFilterOptionIdMobile) {
      const hasFilteringCurrentOptionId = filterSelected.find(
        (filter) => filter.metaData?.key === currentFilterOptionIdMobile
      );

      if (hasFilteringCurrentOptionId) {
        buttonClear = buttonClearOrClearAll(context, 'clear', currentFilterOptionIdMobile);
      }
    }

    if (buttonClear) {
      if (filterSelected.length === 0) {
        buttonClear.classList.add('boost-sd__g-hide');
      }

      if (!context.$(FILTER_TREE_MOBILE_BUTTON_CLEAR)) {
        const toolBarMobile = context.$(FILTER_TREE_MOBILE_TOOL_BAR);
        toolBarMobile?.append(buttonClear);
      } else {
        context.$(FILTER_TREE_MOBILE_BUTTON_CLEAR).replaceWith(buttonClear);
      }
    }
  }
};

export const handleFilterTreeResponsive = (context) => {
  const mobileParent = context.$(FILTER_MOBILE_WRAPPER_SELECTOR);
  const desktopParent = context.$(FILTER_DESKTOP_WRAPPER_SELECTOR);
  const { filterLayout, filterTreeHorizontalStyle, filterTreeMobileStyle } =
    getFilterSettings(context);
  if (!mobileParent || !desktopParent) {
    return;
  }

  const isMobileSize = isMobile(context.app.generalSettings.isTabletPortraitMax);
  if (isMobileSize && desktopParent && desktopParent?.style?.display !== 'none') {
    desktopParent.style.display = 'none';
  }

  if (!isMobileSize && desktopParent && desktopParent?.style?.display === 'none') {
    closeMobileFilterTree(context);
    desktopParent.style.display = 'unset';

    // only render one times
    if (!window.renderFilterTreeFirstLoad) {
      console.log('render filter tree from handleFilterTreeResponsive');
      renderFilterTree(context);
      window.renderFilterTreeFirstLoad = true;
    }
  }

  const isFilterTreeMobileStyle1 = filterTreeMobileStyle == 'style1';
  const isFilterTreeMobileStyle2 =
    filterTreeMobileStyle == 'style2' || filterTreeMobileStyle == 'style2-fullwidth';
  const isFilterTreeMobileStyle3 =
    filterTreeMobileStyle == 'style3' || filterTreeMobileStyle == 'style3-fullwidth';
  const isDesktopHorizontal = !isMobileSize && filterLayout == 'horizontal';
  const filterOptionListHorizontal = '.boost-sd__filter-tree-horizontal-option-list';
  const filterOptionListMobile =
    '#boost-sd_filter-tree-mobile .boost-sd__filter-tree-vertical-option-list';

  if ((isFilterTreeMobileStyle3 || isFilterTreeMobileStyle1) && isMobileSize) {
    addRemoveHiddenClass(context, 'remove', filterOptionListMobile);
  }

  if (isDesktopHorizontal) {
    if (filterTreeHorizontalStyle == 'style-expand') {
      addRemoveHiddenClass(context, 'remove', filterOptionListHorizontal);
    } else {
      addRemoveHiddenClass(context, 'add', filterOptionListHorizontal);
    }
  }
};

const addRemoveHiddenClass = (context, action, targetElement) => {
  const filterOptionItemWrapper = '.boost-sd__filter-option-itemwrapper';

  if (context.$(targetElement) !== null) {
    Array.from(context.$(targetElement)?.querySelectorAll(filterOptionItemWrapper))?.forEach(
      (e) => {
        if (action == 'add') {
          e?.classList?.add(FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS);
        }
        if (action == 'remove') {
          e?.classList?.remove(FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS);
        }
      }
    );
  }
};

export const getFilterTreeParent = (context) => {
  if (isMobile(context?.app?.generalSettings?.isTabletPortraitMax)) {
    return context.$(FILTER_MOBILE_WRAPPER_SELECTOR);
  }

  return context.$(FILTER_DESKTOP_WRAPPER_SELECTOR);
};

export const showMobileFilterTree = (context) => {
  const { filterTreeMobileStyle } = getFilterSettings(context);
  const filterTreeMobileWrapper = context.$(FILTER_MOBILE_WRAPPER_SELECTOR);
  if (filterTreeMobileWrapper.style.display === 'none') {
    filterTreeMobileWrapper.style.display = 'block';
  } else {
    filterTreeMobileWrapper.style.display = 'none';
  }

  if (filterTreeMobileStyle != 'style1') {
    document.body.classList.add('boost-sd__g-no-scroll');
  } else {
    toggleFilterTreeIcon(context);
  }

  console.log('render filter tree from showMobileFilterTree');
  renderFilterTree(context);
};

export function toggleFilterTreeIcon(context) {
  const isMobileSize = isMobile(context?.app?.generalSettings?.isTabletPortraitMax);
  const filterTreeToggleButton = isMobileSize
    ? context.$('.boost-sd__toolbar-top-mobile .boost-sd__filter-tree-toggle-button')
    : context.$('.boost-sd__filter-tree-toggle-button--expand');

  filterTreeToggleButton?.classList?.toggle('boost-sd__filter-tree-toggle-button--active');

  // Toggle tabindex on toolbar elements when the filter button is clicked
  let toolbarElements = document.querySelectorAll('.boost-sd__toolbar [tabindex]');
  function updateTabIndex(element) {
    const tabIndex = element.getAttribute('tabindex');
    if (tabIndex === '0') {
      element.setAttribute('tabindex', '-1');
      filterTreeToggleButton?.removeAttribute('tabindex');
    } else if (tabIndex === '-1') {
      element.setAttribute('tabindex', '0');
      filterTreeToggleButton?.setAttribute('tabindex', '0');
      filterTreeToggleButton?.focus();
    }
  }
  if (toolbarElements.length > 0) {
    toolbarElements.forEach(updateTabIndex);
  }
}

export const closeMobileFilterTree = (context) => {
  const mobile = context.$(FILTER_MOBILE_WRAPPER_SELECTOR);
  if (mobile) {
    mobile.style.display = 'none';
  }
  document.body?.classList?.remove('boost-sd__g-no-scroll');
};

export function fillFilterTree(context) {
  // case state has filter tree data
  if (context?.state?.filterTree?.options) {
    handleFillFilterTree(context);
    return;
  }

  //case state do not have filter tree then trigger when state update filter tree
  context.render(() => {
    if (context.state.filterTree && context.state.filterTree.options) {
      handleFillFilterTree(context);
    }
  }, ['filterTree']);
}

const handleFillFilterTree = (context) => {
  const [_, setFilter] = context.useContextState('filter', []);

  // Name is showSingleOption but data return from BE hideSingOption
  const { showOutOfStockOption, showSingleOption: hideSingleOption } = getFilterSettings(context);
  const settingOptionValues = {
    showOutOfStockOption: showOutOfStockOption,
    hideSingleOption,
  };

  const options = updateValuesOptions(
    context.state.filterTree.options,
    settingOptionValues,
    context
  );
  const newFilter = fillMissValueForFilterTree(context, options, context.state.filter);
  setFilter(newFilter);
};

const flattenTagsToObject = (array) => {
  let result = {};

  array.forEach((item) => {
    result[item.tag] = item.displayName;
    if (item.subTags) {
      Object.assign(result, flattenTagsToObject(item.subTags));
    }
  });

  return result;
};

const fillMissValueForFilterTree = (context, options, selectedFilters) => {
  const optionsMap = options.reduce((obj, val) => ({ ...obj, [val.filterOptionId]: val }), {});
  let fillTagDependencies = false;

  const filterTree = selectedFilters.map((selectedFilter) => {
    let { key, value, valueDisplay } = selectedFilter.data;
    if (selectedFilter.type) {
      // filter already has data, do not need to fill
      return selectedFilter;
    }

    if (key === FILTER_TAG_KEY) {
      fillTagDependencies = true;
      return {
        data: { label: 'Collection Tag', value, key, valueDisplay: value },
        metaData: { key, value },
        type: 'tag',
      };
    }

    if (optionsMap[key].displayType === 'range') {
      return generateFilterDataForRange(key, value, optionsMap[key]);
    }

    const filterOption = optionsMap[key];
    // fix: BC-1033 - Incorrect refine by element label of multi_level_tag
    if (filterOption.filterType === 'multi_level_tag' && !valueDisplay) {
      const keyDisplayName = flattenTagsToObject(filterOption.values);
      valueDisplay = keyDisplayName[value];
    }

    if (filterOption.filterType === 'multi_level_tag') {
      filterOption.valueDisplay = valueDisplay;
    } else {
      // filterOption.values[0].label use to check this filter have label to display
      if (filterOption.values && filterOption.values.length > 0 && filterOption.values[0].label) {
        const matchValue = filterOption.values.find((v) => v.key == value); // use == in case compare string vs number
        if (matchValue) {
          filterOption.valueDisplay = matchValue.label;
        }
      }
    }
    return generateFilterDataForList(context, key, value, filterOption);
  });

  const collectionFilterOptionId = getCollectionFilterOptionId(options);

  if (fillTagDependencies) {
    const tag = filterTree.find((f) => f.data.key === FILTER_TAG_KEY);
    const collection = filterTree.find((f) => f.data.key === collectionFilterOptionId);
    const tagMetadata = { ...tag.metaData };
    tag.metaData.dependencies = [{ ...collection.metaData }];
    collection.metaData.dependencies = [tagMetadata];
  }

  return filterTree;
};

export function clearAllFilter(context, { preventPushHistory = false }) {
  const [_, setFilter] = context.useContextState('filter', []);
  setFilter([]);
  resetFilterRequest(context);
  clearAllParamHistory({ preventPushHistory });
  scrollToTop({ isFilter: true });
}

export function removeFilterFromRefineBy(context, action) {
  // force true => always call api
  clearFilter(context, action, true);
}

export function clearFilter(context, action, forceCall = false) {
  const { filterLayout } = getFilterSettings(context);
  let isCall = true;

  if (!forceCall && filterLayout === 'horizontal') {
    isCall = false;
  }

  const filters = action.dependencies ? [action, ...action.dependencies] : [action];
  const [getFilter, setFilter] = context.useContextState('filter', []);
  let newFilterTree = getFilter();
  filters.forEach((filter) => {
    const { key, value, type, displayType } = filter;
    newFilterTree = newFilterTree.filter(
      (ft) =>
        ft && (ft.metaData.key !== key || (ft.metaData.key === key && ft.metaData.value !== value))
    );
    removeFilterSelectedClass(context, key, value, type || displayType);
  });
  removeQueryParamsHistory(filters);
  setFilter(newFilterTree);
  isCall && resetFilterRequest(context);
}

export const parseHistoryParams = (context = {}) => {
  const { paramList, sort, pagination } = getParamsHistory();

  if (sort) {
    const [_, setSort] = context.useContextState('sort', {});
    setSort({ sort });
  }

  if (pagination.page) {
    const [_, setPage] = context.useContextState('pagination ', {});
    setPage({ page: pagination.page });
  }

  const listFilters = [];
  paramList.forEach((param) => {
    param.values.forEach((value) => {
      listFilters.push({ data: { key: param.key, value } });
    });
  });
  const [_, setFilter] = context.useContextState('filter', []);
  setFilter(listFilters);
  context.handler(renderRefineBy, 'renderRefineBy')(context);
};

export const parseCollectionPath = (context) => {
  const pathname = window.location.pathname;
  if (pathname.startsWith('/collections/')) {
    // slide 2 to remove '' and 'collections'
    const path = pathname.split('/').slice(2);

    if (path.length > 0) {
      const collectionHandle = path[0];
      context.app.generalSettings.collection_id = getCollectionIdByHandleKey(collectionHandle);
      context.app.generalSettings.collection_handle = collectionHandle;
    }

    // have tag in collection
    if (path.length > 1) {
      const collectionTag = getCollectionsTagFromSlugKey(path[1]);
      context.app.generalSettings.collection_tags = [collectionTag];
    } else {
      context.app.generalSettings.collection_tags = null;
    }
  }
};

async function lazyLoadFilterTree(context) {
  const { $, useContextState } = context;
  const [getFilterTreeOption] = useContextState('filterTree', {});

  let options = getFilterTreeOption().options;
  if (!options) {
    await new Promise((resolve) => setTimeout(resolve, 50));
    return lazyLoadFilterTree(context);
  }

  context.filterTreeViewPort = {};
  // init viewport management for filter tree
  for (const option of options) {
    if (option.values && isFilterOptionSupported(option.displayType)) {
      context.filterTreeViewPort[option.filterOptionId] = {
        loaded: 0,
        total: option.values.length,
        displayType: option.displayType,
      };
    }
  }

  if (Object.keys(context.filterTreeViewPort).length > 0) {
    for (const [key, value] of Object.entries(context.filterTreeViewPort)) {
      if (value.loaded !== 0) continue;

      const keyEncodeMetaField = key.replace(/::/g, '__');
      const optionDom = $().querySelectorAll(`#${keyEncodeMetaField}`);
      if (!optionDom.length) continue;

      const filterOptionWrapperDom = Array.from(optionDom).map((dom) =>
        dom.closest(`${FILTER_OPTION_SELECTOR}`)
      );

      const option = options.find((option) => option.filterOptionId === key);

      if (filterOptionWrapperDom && option) {
        inViewPortHandler(filterOptionWrapperDom, function renderFilterOptionInViewPort(target) {
          const targetDom = target.querySelector(`#${keyEncodeMetaField}`);
          if (!targetDom) return;
          if (isFilterOptionSupported(value.displayType)) {
            const valuesLength = option.values.length;
            const firstLoad = valuesLength < LIMIT_FIRST_LOAD ? valuesLength : LIMIT_FIRST_LOAD;
            context.filterTreeViewPort[key] = {
              loaded: firstLoad,
              total: valuesLength,
              displayType: option.displayType,
            };

            // renderFilterOption(context, targetDom, option, firstLoad);

            // Each filter option render should be added to the task queue
            requestIdleCallback(function renderFilterTreeMacroTask() {
              return renderFilterOption(context, targetDom, option, firstLoad);
            });
          }
        });
      }
    }
  }
}

const buildEventFilterOptionCollapsed = (context, rawAction, metadata) => {
  // build desktop
  context
    .$(`${FILTER_DESKTOP_WRAPPER_SELECTOR} #${rawAction}`)
    ?.setAttribute('data-metadata', metadata);
  context
    .$(`${FILTER_DESKTOP_WRAPPER_SELECTOR} #${rawAction} svg`)
    ?.setAttribute('data-metadata', metadata);
  context
    .$(`${FILTER_DESKTOP_WRAPPER_SELECTOR} #${rawAction} svg path`)
    ?.setAttribute('data-metadata', metadata);

  // build mobile
  context
    .$(`${FILTER_MOBILE_WRAPPER_SELECTOR} #${rawAction}`)
    ?.setAttribute('data-metadata', metadata);
  context
    .$(`${FILTER_MOBILE_WRAPPER_SELECTOR} #${rawAction} svg`)
    ?.setAttribute('data-metadata', metadata);
  context
    .$(`${FILTER_MOBILE_WRAPPER_SELECTOR} #${rawAction} svg path`)
    ?.setAttribute('data-metadata', metadata);
};

const buildAttributeDataFilterOptionListItem = (context, rawAction, option) => {
  // build desktop
  context
    .$(`${FILTER_DESKTOP_WRAPPER_SELECTOR} #${rawAction}`)
    ?.closest('.boost-sd__filter-option')
    ?.setAttribute('data-filterOptionId', option.filterOptionId);

  // build mobile
  context
    .$(`${FILTER_MOBILE_WRAPPER_SELECTOR} #${rawAction}-target`)
    ?.closest('.boost-sd__filter-option')
    ?.setAttribute('data-filterOptionId', option.filterOptionId);
};

const showHideSearchBoxOption = (context, rawAction, option) => {
  // build Show Hide search option
  if (option.showSearchBoxFilterPC || option.showSearchBoxFilterMobile) {
    // if PC not show -> mobile show -> disabled search box desktop
    if (!option.showSearchBoxFilterPC) {
      // Remove dom input desktop
      context
        .$(
          `${FILTER_DESKTOP_WRAPPER_SELECTOR} #${rawAction}-target ${FILTER_OPTION_SEARCH_OPTION_SELECTOR}`
        )
        ?.remove();
    }

    // if Mobile not show -> desktop show -> disabled search box mobile
    if (!option.showSearchBoxFilterMobile) {
      // Remove dom input mobile
      context
        .$(
          `${FILTER_MOBILE_WRAPPER_SELECTOR} #${rawAction}-target ${FILTER_OPTION_SEARCH_OPTION_SELECTOR}`
        )
        ?.remove();
    }
  }
};

export function initFilterOptionCollapse(context) {
  const { options } = context.state.filterTree;
  const { keepToggleState, filterLayout, filterTreeHorizontalStyle, filterTreeMobileStyle } =
    getFilterSettings(context);
  //  TODO1
  if (!options) return;
  watchCollapseStateChange(context);

  // remove class hidden in mobile style1 - FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS
  if (filterTreeMobileStyle === 'style1') {
    const wrapperItemsStyle1 = context?.document?.querySelectorAll(
      `${FILTER_MOBILE_WRAPPER_SELECTOR} .boost-sd__filter-option-itemwrapper--style1`
    );

    if (wrapperItemsStyle1?.length > 0) {
      wrapperItemsStyle1?.forEach((wrapper) => {
        wrapper?.classList?.remove(FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS);
      });
    }
  }

  const [getCollapseState, setCollapseState] = context.useContextState(COLLAPSE_STATE, {});
  const _getCollapseState = getCollapseState();

  const [getFilter] = context.useContextState('filter', []);
  const selectedFilters = getFilter();

  options.map((option) => {
    const rawAction = `${option.filterOptionId.replace(/::/g, '__')}-toggle`;
    const metadata = JSON.stringify({
      action: {
        filterOptionCollapse: {
          actionId: rawAction,
        },
      },
    });
    buildAttributeDataFilterOptionListItem(context, rawAction, option);
    buildEventFilterOptionCollapsed(context, rawAction, metadata);
    showHideSearchBoxOption(context, rawAction, option);

    if (
      !isMobile(context.app.generalSettings.breakpointtabletportraitmax) &&
      filterLayout === 'vertical'
    ) {
      // append clear in filter option
      const filterOptionBtnClear = context.$(
        `${FILTER_DESKTOP_WRAPPER_SELECTOR} #${rawAction}-target-label .boost-sd__button--clear`
      );

      if (filterOptionBtnClear) {
        const filterOptionSelected = selectedFilters.map((selected) => selected.data.key);

        if (!filterOptionBtnClear.dataset.metadata) {
          const metadata = JSON.stringify({
            action: {
              resetFilterOption: { key: option.filterOptionId },
              from: 'client',
            },
          });
          filterOptionBtnClear.setAttribute('data-metadata', metadata);
          filterOptionBtnClear.querySelector('span')?.setAttribute('data-metadata', metadata);
        }

        if (filterOptionSelected.includes(option.filterOptionId)) {
          filterOptionBtnClear.classList?.remove('boost-sd__g-hide');
        } else {
          filterOptionBtnClear.classList?.add('boost-sd__g-hide');
        }
      }
    }

    if (
      !isMobile(context.app.generalSettings.breakpointtabletportraitmax) &&
      filterLayout === 'horizontal'
    ) {
      // bind event to apply and reset button
      const applyId = simpleMd5(`${option.filterOptionId}-apply-button`);
      const clearId = simpleMd5(`${option.filterOptionId}-reset-button`);
      const metadataApply = JSON.stringify({
        action: {
          applyFilterOption: {
            key: option.filterOptionId,
            from: 'client',
          },
        },
      });

      const resetFilterOption = JSON.stringify({
        action: {
          resetFilterOption: {
            key: option.filterOptionId,
            from: 'client',
          },
        },
      });

      if (filterTreeHorizontalStyle !== 'style-expand') {
        const btnApply = context.$(`${FILTER_DESKTOP_WRAPPER_SELECTOR} #${applyId}`);
        const btnClear = context.$(`${FILTER_DESKTOP_WRAPPER_SELECTOR} #${clearId}`);

        if (btnApply && !btnApply.dataset.metadata)
          btnApply.setAttribute('data-metadata', metadataApply);
        if (btnClear && !btnClear.dataset.metadata)
          btnClear.setAttribute('data-metadata', resetFilterOption);
      }
    }
  });

  // bind event for Apply and Clear All button in horizontal style-expand
  const isHorizontalStyleExpand =
    !isMobile(context.app.generalSettings.breakpointtabletportraitmax) &&
    filterLayout === 'horizontal' &&
    filterTreeHorizontalStyle === 'style-expand';
  if (isHorizontalStyleExpand) {
    const dom = context.$(`.boost-sd__filter-tree-horizontal-button`);
    if (dom) {
      dom.innerHTML =
        `
      <button id="boost-sd__apply-all-button" metadata='{"action": { "applyFilterOption": "applyAll" }}' type="button" class="boost-sd__button boost-sd__button--apply">
        <span class="boost-sd__button-text">` +
        context.app?.translation?.applyAll +
        `</span>
      </button>
      <button id="boost-sd__reset-all-button" metadata='{"action": { "clearAllFilter": "" }}' type="button" class="boost-sd__button boost-sd__button--clear">
          <span class="boost-sd__button-text">` +
        context.app?.translation?.clearAll +
        `</span>
      </button>
      `;
    }
  }

  // only init when first load not collapse status or keepToggleState
  if (Object.keys(_getCollapseState)?.length > 0 && !keepToggleState) return;

  let collapseInitState = {};

  options.forEach((option) => {
    // TODO check mobile
    const rawAction = `${option.filterOptionId.replace(/::/g, '__')}-toggle`;

    const targetId = `${rawAction}-target`;
    const targetIdLabel = `${rawAction}-target-label`;

    const actionId = rawAction;
    const isCollapsedHorizontal =
      !isMobile(context.app.generalSettings.breakpointtabletportraitmax) &&
      filterLayout === 'horizontal' &&
      filterTreeHorizontalStyle !== 'style-expand';

    // init collapsed vertical
    if (filterLayout !== 'horizontal') {
      collapseInitState[actionId] = {
        actionId: actionId,
        filterLayout: filterLayout,
        targets: [
          {
            targetId: targetId,
            classToggle: 'boost-sd__filter-option-itemwrapper--collapsed',
            shouldAddClass: option.isCollapsePC ? true : false,
          },
          {
            classToggle: 'boost-sd__filter-option-label--collapsed',
            targetId: targetIdLabel,
            shouldAddClass: option.isCollapsePC ? true : false,
          },
        ],
      };
    }

    // Init collapsed horizontal style1, style2, style3 (! style-expand)
    if (isCollapsedHorizontal) {
      collapseInitState[actionId] = {
        actionId: actionId,
        filterLayout: filterLayout,
        targets: [
          {
            targetId: targetId,
            classToggle: FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS,
            shouldAddClass: true,
          },
          {
            classToggle: FILTER_OPTION_TITLE_OPENING_CLASS,
            targetId: targetIdLabel,
            shouldAddClass: false,
          },
        ],
      };
    }

    // Remove class hidden if template return have class hidden
    if (isHorizontalStyleExpand) {
      const filterTreeParent = getFilterTreeParent(context);
      context
        .$(`#${targetId}`, filterTreeParent)
        ?.classList?.remove(FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS);
    }
  });

  setCollapseState(collapseInitState);
}

export function watchCollapseStateChange(context) {
  context.render(() => {
    const [getCollapseState] = context.useContextState(COLLAPSE_STATE, {});
    const _getCollapseState = getCollapseState();
    const keys = Object.keys(_getCollapseState);

    if (keys.length > 0) {
      keys.forEach((key) => {
        handleCollapse(context, _getCollapseState[key]);
      });
    }
  }, [COLLAPSE_STATE]);
}

export function handleCollapse(context, action) {
  if (!action) return; // cover horizontal with style = style-expand
  const filterTreeParent = getFilterTreeParent(context);

  let targets = action.targets;
  if (targets.length > 0) {
    targets = targets.map((target) => {
      const targetElement = context.$(`#${target.targetId}`, filterTreeParent);
      const shouldAddClass = target.shouldAddClass;

      if (targetElement) {
        shouldAddClass
          ? targetElement.classList.add(target.classToggle)
          : targetElement.classList.remove(target.classToggle);
      }
    });
  }
}

export function handleToggle(context, action, containerId = '') {
  if (!action) return; // cover horizontal with style = style-expand
  let targets = action.targets;
  if (targets.length > 0) {
    targets = targets.map((target) => {
      const targetElement = context.$(`${containerId ? containerId + ' ' : ''}#${target.targetId}`);
      targetElement?.classList?.toggle(target.classToggle);
    });
  }
}

/**
 * Toggle class collapse
 * @param context
 * @param action actionProps
 * {
 *    actionId: string, Id of user click
 *    targets: [
 *      {
 *          classToggle: string, // class toggle
 *          shouldAddClass: boolean, // true -> add class, false -> remove class
 *          targetId: string // Id effect
 *      },
 *      ...
 *    ]
 * }
 */
export function filterOptionCollapse(context, action) {
  if (isMobile(context.app.generalSettings.isTabletPortraitMax)) {
    collapseMobileBtn(context, action);
    return;
  }

  const { filterLayout } = getFilterSettings(context);
  const [getCollapseState, setCollapseState] = context.useContextState(COLLAPSE_STATE, {});
  const _getCollapseState = getCollapseState();

  // horizontal close filterOption opening
  if (filterLayout === 'horizontal') {
    Object.keys(_getCollapseState).forEach((key) => {
      const targets = _getCollapseState[key].targets;
      if (
        targets &&
        targets.length > 0 &&
        key !== action.actionId &&
        !targets[0].shouldAddClass &&
        targets[1]?.shouldAddClass
      ) {
        _getCollapseState[key] = getFilterOptionToggleState(_getCollapseState[key]);
      }
    });
  }

  // set toggle current filterOption
  _getCollapseState[action.actionId] = getFilterOptionToggleState(
    _getCollapseState[action.actionId]
  );

  setCollapseState(_getCollapseState);
}

export function getFilterOptionToggleState(currentAction) {
  let targets = currentAction?.targets || [];

  if (targets.length > 0) {
    targets = targets.map((target) => {
      return {
        ...target,
        shouldAddClass: !target.shouldAddClass,
      };
    });

    currentAction.targets = targets;
  }

  return currentAction;
}

export function closeFilterOptionHorizontalOpening(context) {
  // horizontal close filterOption opening
  const [getCollapseState, setCollapseState] = context.useContextState(COLLAPSE_STATE, {});
  const _getCollapseState = getCollapseState();
  Object.keys(_getCollapseState).forEach((key) => {
    const targets = _getCollapseState[key].targets;
    if (targets && targets.length > 0 && !targets[0].shouldAddClass && targets[1]?.shouldAddClass) {
      _getCollapseState[key] = getFilterOptionToggleState(_getCollapseState[key]);
    }
  });
  setCollapseState(_getCollapseState);
}

export function applyFilterOption(context, action) {
  if (action === 'applyAll') {
    // force close filter Tree
    const buttonToggle = document.querySelector(
      '.boost-sd__filter-tree-toggle-button.boost-sd__filter-tree-toggle-button--expand'
    );
    buttonToggle?.click();
  }

  scrollToTop({ isFilter: true });
  closeFilterOptionHorizontalOpening(context);
  resetFilterRequest(context);

  // reset selecting when apply
  const [_, setItemSelecting] = context.useContextState('filterOptionItemSelecting', {});
  setItemSelecting({});
}

export function resetFilterOption(context, action) {
  const { key } = action;
  const [getFilterTreeOption] = context.useContextState('filterTree', {});
  const options = getFilterTreeOption()?.options;
  const keys = key === getCollectionFilterOptionId(options) ? [key, FILTER_TAG_KEY] : [key];

  const [getFilterTree, setFilterTree] = context.useContextState('filter', []);
  const filterTree = getFilterTree();
  const newFilterTree = filterTree.filter((ft) => ft && !keys.includes(ft.data.key));
  setFilterTree(newFilterTree);

  closeFilterOptionHorizontalOpening(context);

  deleteQueryParamsHistory(keys);
  resetFilterRequest(context);
}

export function expandCollapseMultiLevel(context, action) {
  // TODO keep state of multi level
  handleToggle(context, action, isMobile() ? '#boost-sd_filter-tree-mobile' : '');
}

export const collapseMobileBtn = (context, action) => {
  const filterTreeParent = getFilterTreeParent(context);
  const targetElement = context.$(
    `#${action.elementId || `${action.actionId}-target`}`,
    filterTreeParent
  );
  const { filterTreeMobileStyle } = getFilterSettings(context);
  const isMobileStyle2 =
    filterTreeMobileStyle == 'style2' || filterTreeMobileStyle == 'style2-fullwidth';

  if (filterTreeMobileStyle !== 'style1') {
    const refineByTitle = context.$(
      '.boost-sd__filter-tree-vertical-toolbar-middle',
      filterTreeParent
    );
    const currentTitle = context.$(`#${action.actionId}`);

    if (refineByTitle && currentTitle) {
      refineByTitle.innerHTML = currentTitle.firstChild?.textContent?.replace(/\\n|\s/, '');
    }
  }

  if (isMobileStyle2) {
    // change clearAll to clear if current filterOptionId filtered
    const domClearAllButton = context.$(FILTER_TREE_MOBILE_BUTTON_CLEAR);

    const selectedFilter = getSelectedFilter(context);
    const filterOptionId = action.actionId?.replace('-toggle', '');
    const existKey = selectedFilter.find((item) => item.metaData?.key === filterOptionId);

    const [_, setFilterOptionIdMobileSelected] = context.useContextState(
      'filterOptionIdMobileSelected',
      ''
    );

    filterOptionId && setFilterOptionIdMobileSelected({ level: 2, key: filterOptionId });

    if (existKey) {
      const clearButton = buttonClearOrClearAll(context, 'clear', filterOptionId);
      domClearAllButton.replaceWith(clearButton);
    } else if (domClearAllButton) {
      domClearAllButton?.classList?.add('boost-sd__g-hide');
    }
  }

  Array.from(
    context.$(FILTER_MOBILE_WRAPPER_SELECTOR)?.querySelectorAll('.boost-sd__filter-option-label')
  )?.forEach((e) => {
    if (isMobileStyle2) {
      e?.classList?.toggle('boost-sd__filter-option-label--hidden');
    }
  });

  if (targetElement) {
    if (filterTreeMobileStyle == 'style2') {
      context.$(FILTER_MOBILE_BUTTON_CLOSE_CLASS)?.classList?.remove(FILTER_MOBILE_BUTTON_HIDDEN);
    }

    if (isMobileStyle2) {
      targetElement.classList?.toggle(FILTER_MOBILE_FULL_HEIGHT_CLASS);
      targetElement.classList?.toggle(FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS);
      targetElement.classList?.remove(FILTER_OPTION_ITEM_WRAPPER_COLLAPSED_CLASS);
      context.$(FILTER_MOBILE_BUTTON_CLOSE_CLASS)?.classList?.add(FILTER_MOBILE_BUTTON_BACK);
      context.$(FILTER_MOBILE_BUTTON_CLOSE_CLASS)?.classList?.remove(FILTER_MOBILE_BUTTON_CLOSE);
    }

    const domList = context.$('.boost-sd__filter-option-listitem', targetElement);

    if (domList) {
      const domFilterOption = domList?.closest('.boost-sd__filter-option');
      const filterOptionId = domFilterOption?.dataset?.filteroptionid;
      const option = getFilterOptionById(context, filterOptionId);
      option && renderFullyFilterOption(context, domList, option);
    }
  }
};

const getFilterOptionById = (context, id) => {
  const { useContextState } = context;
  const [getFilterTreeOption] = useContextState('filterTree', {});
  const options = getFilterTreeOption().options;
  return options.find((op) => op.filterOptionId === id);
};

export const backFilterTreeMobile = (context) => {
  const { filterTreeMobileStyle } = getFilterSettings(context);

  if (filterTreeMobileStyle !== 'style1') {
    const refineByMobileTitle = context.$('.boost-sd__filter-tree-vertical-toolbar-middle');

    if (refineByMobileTitle) {
      refineByMobileTitle.innerHTML = translateByKey(context, 'refineMobile', 'Refine by');
    }
  }

  // check if filtering - show button clear All
  if (['style2', 'style2-fullwidth'].includes(filterTreeMobileStyle)) {
    const selectedFilter = getSelectedFilter(context);
    if (selectedFilter.length > 0) {
      const btnClearAll = buttonClearOrClearAll(context, 'clearAll');

      if (!context.$(FILTER_TREE_MOBILE_BUTTON_CLEAR)) {
        const toolBarMobile = context.$(FILTER_TREE_MOBILE_TOOL_BAR);
        toolBarMobile.append(btnClearAll);
      } else {
        context.$(FILTER_TREE_MOBILE_BUTTON_CLEAR).replaceWith(btnClearAll);
      }
    }

    // set level1 show button clear All
    const [_, setFilterOptionIdMobileSelected] = context.useContextState(
      'filterOptionIdMobileSelected',
      ''
    );
    setFilterOptionIdMobileSelected({ level: 1 });
  }

  if (filterTreeMobileStyle == 'style2') {
    context.$(FILTER_MOBILE_BUTTON_BACK_CLASS)?.classList?.add(FILTER_MOBILE_BUTTON_HIDDEN);
  }
  context.$(FILTER_MOBILE_BUTTON_BACK_CLASS)?.classList?.add(FILTER_MOBILE_BUTTON_CLOSE);
  context.$(FILTER_MOBILE_BUTTON_BACK_CLASS)?.classList?.remove(FILTER_MOBILE_BUTTON_BACK);

  Array.from(
    context.$(FILTER_MOBILE_WRAPPER_SELECTOR)?.querySelectorAll('.boost-sd__filter-option-label')
  ).forEach((targetElement) =>
    targetElement?.classList?.remove('boost-sd__filter-option-label--hidden')
  );

  Array.from(
    context.$(FILTER_MOBILE_WRAPPER_SELECTOR)?.querySelectorAll(FILTER_MOBILE_FULL_HEIGHT_SELECTOR)
  ).forEach((targetElement) => {
    targetElement?.classList?.remove(FILTER_MOBILE_FULL_HEIGHT_CLASS);
    targetElement?.classList?.add(FILTER_OPTION_ITEM_WRAPPER_HIDDEN_CLASS);
  });
};

export const getFilterSettings = (context) => {
  if (!context) {
    console.error('context getFilterSettings null');
    return {};
  }

  // swatch_settings get from filterSettings app admin
  return {
    ...(context?.templateSettings?.filterSettings || {}),
    swatch_settings: context?.app?.filterSettings?.swatch_settings,
  };
};

export const toggleFilterTreeButtonDesktop = (context, filterTreeParent) => {
  const { filterLayout, filterTreeVerticalStyle } = getFilterSettings(context);
  const [getFilterTreeDesktopOpening, setFilterTreeDesktopOpening] = context.useContextState(
    FILTER_TREE_STATE_KEY.DESKTOP_OPENING,
    false
  );
  const _currentStateOpening = getFilterTreeDesktopOpening();

  if (filterLayout === 'horizontal') {
    // if style Show all filter options
    const styleShowAllOptionSelector =
      '#boost-sd__filter-tree-wrapper .boost-sd__filter-tree-horizontal';
    const targetElement = context.$(styleShowAllOptionSelector, filterTreeParent);

    if (_currentStateOpening) {
      targetElement?.classList?.add('boost-sd__filter-tree-horizontal--hidden');
    } else {
      targetElement?.classList?.remove('boost-sd__filter-tree-horizontal--hidden');
    }

    toggleFilterTreeIcon(context);
    setFilterTreeDesktopOpening(!_currentStateOpening);
  } else {
    // if style Collapsed - Expand
    if (filterTreeVerticalStyle === 'style-expand') {
      const styleCollapseExpandSelector = '.boost-sd__filter-tree-vertical';
      const targetElement = context.$(styleCollapseExpandSelector, filterTreeParent);
      if (_currentStateOpening) {
        targetElement?.classList?.add('boost-sd__filter-tree-vertical--hidden');
      } else {
        targetElement?.classList?.remove('boost-sd__filter-tree-vertical--hidden');
      }
      toggleFilterTreeIcon(context);
      setFilterTreeDesktopOpening(!_currentStateOpening);
    }

    // if style Off canvas - Left side
    if (filterTreeVerticalStyle === 'style-off-canvas') {
      const styleOffCanvasSelector =
        '#boost-sd__filter-tree-wrapper #boost-sd__filter-tree-vertical--style-off-canvas';

      const targetElement = context.$(styleOffCanvasSelector, filterTreeParent);
      if (_currentStateOpening) {
        targetElement?.classList?.add('boost-sd__filter-tree-vertical--hidden');
      } else {
        targetElement?.classList?.remove('boost-sd__filter-tree-vertical--hidden');
      }
      toggleFilterTreeIcon(context);
      setFilterTreeDesktopOpening(!_currentStateOpening);
      document.body.classList.add('boost-sd__g-no-scroll');
    }
  }
};

export function initCurrencyForFilterPrice(context) {
  const [getFilterTree] = context.useContextState('filterTree', []);
  const options = getFilterTree()?.options;

  if (options?.length > 0) {
    options.forEach((option) => {
      if (['price', 'variants_price'].includes(option.filterType)) {
        option.moneyFormatValue = stripHtml(context?.app?.shop?.money_format);
      }
    });
  }
}

export const getCollectionFilterOptionId = (options) => {
  if (options?.length > 0) {
    return options.filter((option) => option.filterType === 'collection')?.[0]?.filterOptionId;
  }

  return 'pf_c_collection';
};

export function isEnterOrSpaceKeyPressed(context) {
  const [getFilterKeyCode] = context.useContextState(FILTER_TREE_STATE_KEY.KEY_CODE, '');
  const keyCode = getFilterKeyCode();
  const isEnterOrSpace = keyCode === 32 || keyCode === 13;
  return isEnterOrSpace;
}
