import {
  clearAllFilter,
  clearFilter,
  removeFilterFromRefineBy,
  handleFilterTree,
  parseHistoryParams,
  fillFilterTree,
  filterOptionCollapse,
  applyFilterOption,
  resetFilterOption,
  initFilterOptionCollapse,
  expandCollapseMultiLevel,
  showMobileFilterTree,
  closeMobileFilterTree,
  backFilterTreeMobile,
  parseCollectionPath,
  getFilterTreeParent,
  getFilterSettings,
  toggleFilterTreeButtonDesktop,
  closeFilterOptionHorizontalOpening,
  initCurrencyForFilterPrice,
  toggleFilterTreeIcon,
} from './filter-tree/index.js';
import {
  formatMoney,
  isMobile,
  isSearchPage,
  safeParseJSON,
  scrollToTop,
  simpleMd5,
  debounce,
  checkExistFilterOptionParam,
  getQueryParamByKey,
  setSessionStorage,
  getThemeSettings,
  setPaginationSession,
  setLocalStorage,
  getLocalStorage,
  handleClickTagAItemBuildUrlWithLocale,
  isProductPage,
} from './utils';
import { handleClickOutsideSort, handleSort } from './toolbar/sort.js';
import {
  closeQuickAddToCart,
  handleClickProductItem,
  handleProductSwatches,
  quickAddToCart,
  transformProductPrice,
  setUniformProductImageHeight,
  handleClickOutsideMiniPopup,
} from './product-item/index.js';
import { handleBulkQuantity } from './bulk-quantity';
import {
  pagination,
  scrollRestoration,
  handleProductListResponsive,
  handlePaginationInfiniteScroll,
  handleExceededPage,
} from './product-list/index.js';
import { quickView } from './product-item/quick-view.js';
import { initState } from './state/index.js';
import { initGetApi } from './api';
import { initDom } from './utils/dom';
import { watchFirstLoad } from './utils';
import {
  handleClickOutsideLimitList,
  handleLimitListOptionClick,
  handleToggleShowLimitList,
} from './toolbar/limit-list.js';
import { handleViewAsClick } from './toolbar/view-as.js';
import { handleRecommendation } from './recommendation/index.js';
import {
  initDynamicBundle,
  renderPredictiveBlocksForProductPage,
  handleClickEmbeddedBundle,
} from './dynamicBundle/index.js';
import { callFilterRequest } from './api/filter.js';
import { optionList } from './filter-tree/option-list.js';
import { addToCart, handleCart } from './cart/index.js';
import { handleInCollectionSearch } from './in-collection-search/index.js';
import {
  FILTER_MOBILE_ICON_SELECTOR,
  FILTER_TREE_STATE_KEY,
  FILTER_TREE_TOGGLE_BUTTON_SELECTOR,
  COLLECTION_ALL_STORAGE_KEY,
} from './constants/filter-tree.js';
import { handleTooltip } from './tooltip/index.js';
import { handleInstantSearchWidget } from './instant-search-widget/index.js';
import { templateRender, translate } from './template/templateRender';
import { optionCollection } from './filter-tree/option-collection.js';
import { renderCollectionHeader } from './components/collection-header/index.js';
import { handleSearchResultTabs } from './search/index.js';

import { appIntegration } from './integration/index.js';
import { LIMIT, LIMIT_SETTING } from './constants/pagination.js';
import { WATERMARK_POWERED_BY_BOOST_URL } from './constants/url.js';
import { handleRecentSearchForSuggestionTerm } from './instant-search-widget/recent-search.js';

export function initApp(context) {
  /**
   * Renders a template with data and includes translations from the application context.
   * @param {string} template - The template to render.
   * @param {Record<string, any>} data - The data to pass to the template.
   * @returns {string} - The rendered template.
   */
  context.templateRender = function (template, data) {
    /**
     * The translations available in the application context.
     * @type {Record<string, any>}
     */
    const translations = context?.app?.translation || {};

    return templateRender(template, { ...data, translations });
  };
  /**
   * Translates a key using translations from the application context.
   * @param {string | number} key - The key to translate.
   * @param {string} defaultLabel - The default label to use if translation is not found.
   * @returns {string} - The translated label.
   */
  context.translate = function (key, defaultLabel) {
    /**
     * The translations available in the application context.
     * @type {Record<string, any>}
     */
    const translations = context?.app?.translation || {};
    return translate(key, translations, defaultLabel);
  };
  /**
   * init general
   * Wraps the provided callback function to handle customization logic.
   * Checks if custom handler functions are defined for the callback name
   * and block type, and wraps the original callback to call them before/after.
   * Allows overriding default handlers or injecting logic before/after calls.
   */
  context.handler = function (callBack, handlerName) {
    return async (...args) => {
      handlerName = !handlerName ? callBack.name : handlerName;
      const blockType = context.blockType;
      let fc = callBack;
      if (
        context.app.customization &&
        context.app.customization[handlerName] &&
        typeof context.app.customization[handlerName] === 'function'
      ) {
        fc = context.app.customization[handlerName];
      }

      if (
        context.app.customization &&
        context.app.customization[blockType]?.[handlerName] &&
        typeof context.app.customization[blockType]?.[handlerName] === 'function'
      ) {
        fc = context.app.customization[blockType][handlerName];
      }
      const capitalizedHandlerName = handlerName[0].toUpperCase() + handlerName.slice(1);
      const beforeHandlerName = `before${capitalizedHandlerName}`;
      const afterHandlerName = `after${capitalizedHandlerName}`;
      if (
        context.app.customization &&
        context.app.customization[beforeHandlerName] &&
        typeof context.app.customization[beforeHandlerName] === 'function'
      ) {
        await context.app.customization[beforeHandlerName](context, ...args);
      }
      if (
        context.app.customization &&
        context.app.customization[blockType]?.[beforeHandlerName] &&
        typeof context.app.customization[blockType][beforeHandlerName] === 'function'
      ) {
        await context.app.customization[blockType][beforeHandlerName](context, ...args);
      }
      const result = await fc(...args);
      if (
        context.app.customization &&
        context.app.customization[blockType]?.[handlerName]?.appIntegration &&
        typeof context.app.customization[blockType]?.[handlerName]?.appIntegration === 'function'
      ) {
        context.app.customization[blockType]?.[handlerName]?.appIntegration(...args);
      }

      if (
        context.app.customization &&
        context.app.customization[afterHandlerName] &&
        typeof context.app.customization[afterHandlerName] === 'function'
      ) {
        await context.app.customization[afterHandlerName](context, result, ...args);
      }
      if (
        context.app.customization &&
        context.app.customization[blockType]?.[afterHandlerName] &&
        typeof context.app.customization[blockType][afterHandlerName] === 'function'
      ) {
        await context.app.customization[blockType][afterHandlerName](context, result, ...args);
      }
      return result;
    };
  };

  /**
   * Renders the widget by executing the provided callback function.
   * Stores the callback in a map by hash of function string to allow
   * re-rendering when dependencies change.
   */
  context.render = function (callback, dependencies) {
    if (!dependencies) dependencies = ['latestFilterSearchRequest'];
    if (dependencies && Array.isArray(dependencies)) dependencies.push('latestFilterSearchRequest');
    if (!Array.isArray(dependencies)) throw new Error('dependencies must is a array');
    if (typeof callback === 'function') {
      callback(context);

      context.watchRender = context.watchRender || {};

      const fcName = simpleMd5(String(callback));
      if (!Object.keys(context.watchRender).includes(callback))
        context.watchRender[fcName] = {
          watchState: dependencies,
          key: fcName,
          callback,
        };
    }
  };
  initGetApi(context);
  initState(context);
  initDom(context);
  watchFirstLoad(context);
  handleLazyloadImage(context);
  context.app.customization = context.app.customization || {};
  context.app.customization[context.blockType] = context.app.customization[context.blockType] || {};
  context.app.customization[context.blockType].appIntegration = appIntegration;

  // Init block type filter
  if (context.blockType === 'filter') {
    let { collection_id, collection_handle } = boostWidgetIntegration?.generalSettings || {};
    // save Id collection All to localStorage
    const getIdCollectionAll = getLocalStorage(COLLECTION_ALL_STORAGE_KEY);
    if (collection_handle === 'all' && getIdCollectionAll !== collection_id) {
      setLocalStorage(COLLECTION_ALL_STORAGE_KEY, collection_id);
    }

    const debounceScrollToTopButton = debounce(function () {
      const btnScrollToTop = document.querySelector('.boost-sd__scroll-to-top');
      if (btnScrollToTop) {
        btnScrollToTop.style.display = window.scrollY > 300 ? 'block' : 'none';
      }
    }, 200);

    window.addEventListener('scroll', debounceScrollToTopButton);

    context.document = document.getElementById(context.id);
    handleExceededPage(context);
    handleFilterTree(context);
    handleInCollectionSearch(context);
    renderCollectionHeader(context);

    context.render(() => {
      const robot = document.querySelector('meta[content="noindex,nofollow,nosnippet"]');
      const { enableRobot = true } = context.app?.generalSettings || {};

      if (
        enableRobot &&
        !robot &&
        (checkExistFilterOptionParam() || (getQueryParamByKey('q') && !isSearchPage(context)))
      ) {
        const meta = document.createElement('meta');
        meta.name = 'robots';
        meta.content = 'noindex,nofollow,nosnippet';

        document.head.append(meta);
      }

      //bind unit format currency for range slider
      const _formatMoney = formatMoney(context);
      const units = context?.document?.querySelectorAll(
        '.boost-sd__filter-option-range-slider-unit'
      );

      if (units && units?.length > 0) {
        units?.forEach((unit) => {
          if (unit) unit.innerHTML = _formatMoney;
        });
      }

      const { filterLayout, filterTreeHorizontalStyle } = getFilterSettings(context);
      const { paginationType = 'default' } =
        getThemeSettings(context)?.additionalElements?.pagination || {};

      if (filterLayout === 'horizontal' && filterTreeHorizontalStyle === 'style-expand') {
      }

      // Need Remove animate class to position fixed is working.
      const animateNeedRemove = document.querySelectorAll(
        '.animate--slide-in, .animation--fade-in'
      );
      if (animateNeedRemove.length > 0) {
        animateNeedRemove.forEach((ele) => {
          ele.classList?.remove('animate--slide-in');
          ele.classList?.remove('animation--fade-in');
        });
      }

      initFilterOptionCollapse(context);
      initCurrencyForFilterPrice(context);
      // Product list
      scrollRestoration(context);
      handleTooltip(context);
      transformProductPrice(context);
      handleProductSwatches(context);
      handleProductListResponsive(context);
      setUniformProductImageHeight(
        context,
        context.app.templateSettings?.themeSettings?.productList?.productsPerRowOnMobile || 2,
        context.app.templateSettings?.themeSettings?.productList?.productsPerRowOnDesktop || 3,
        context.app.generalSettings?.breakpointmobile || 575,
        context.app.generalSettings?.breakpointtabletportraitmax || 991,
        3
      );
      if (
        context.app.templateSettings?.themeSettings?.productItems?.general?.subLayout ===
        'subLayout_bulk'
      ) {
        handleBulkQuantity(context);
      }
      if (isSearchPage(context)) {
        handleSearchResultTabs(context);
      }
      if (paginationType === 'infinite_scroll') {
        handlePaginationInfiniteScroll(context);
      }
    }, ['filterTree']);

    // NOTE: document listener, only use for click outside context.blockType == filter
    document.addEventListener('click', function (event) {
      if (!event || !context) return;
      const target = event.target;

      const { filterLayout, filterTreeMobileStyle, filterTreeVerticalStyle } =
        getFilterSettings(context);

      //click outside filter option with horizontal style
      if (filterLayout === 'horizontal') {
        // click outside and current filter option is opening
        if (
          !target.closest('.boost-sd__filter-option') &&
          context.$('.boost-sd__filter-option-title--opening')
        ) {
          const [getItemSelecting] = context.useContextState('filterOptionItemSelecting', {});

          if (Object.keys(getItemSelecting()).length > 0) {
            applyFilterOption(context, {});
          } else {
            closeFilterOptionHorizontalOpening(context);
          }
        }
      }

      handleClickOutsideSort(context, event.target);
      handleClickOutsideLimitList(context, event.target);
      handleClickOutsideMiniPopup(event.target);
    });

    // General context.document filter listener
    context.document?.addEventListener('click', function (event) {
      const { filterLayout, filterTreeMobileStyle, filterTreeVerticalStyle } =
        getFilterSettings(context);
      const filterTreeParent = getFilterTreeParent(context);
      const [_, setFilterTreeDesktopOpening] = context.useContextState(
        FILTER_TREE_STATE_KEY.DESKTOP_OPENING,
        false
      );
      const [, setCurrentFilterFocus] = context.useContextState(
        FILTER_TREE_STATE_KEY.CURRENT_FOCUS,
        ''
      );

      const _isMobile = isMobile(context?.app?.generalSettings?.breakpointtabletportraitmax);

      const target = event.target;
      const metadataValue = target.dataset.metadata || target.getAttribute('metadata');
      const metadataObject = safeParseJSON(metadataValue);

      const currentButton = target.closest('button');
      if (currentButton && currentButton.id) {
        setCurrentFilterFocus(currentButton.id);
      }

      handleRecentSearchForSuggestionTerm(event);

      const handlers = {
        optionList: context.handler(optionList, 'handleFilterOption'),
        clearAllFilter: context.handler(clearAllFilter, 'clearAllFilter'),
        clearFilter: context.handler(clearFilter, 'clearFilter'),
        removeFilterFromRefineBy: context.handler(
          removeFilterFromRefineBy,
          'removeFilterFromRefineBy'
        ),
        addToCart: context.handler(addToCart, 'addToCart'),
        quickView: context.handler(quickView, 'quickView'),
        optionCollection: context.handler(optionCollection, 'optionCollection'),
        filterOptionCollapse: context.handler(filterOptionCollapse, 'filterOptionCollapse'),
        quickAddToCart: context.handler(quickAddToCart, 'quickAddToCart'),
        applyFilterOption: context.handler(applyFilterOption, 'applyFilterOption'),
        resetFilterOption: context.handler(resetFilterOption, 'resetFilterOption'),
        expandCollapseMultiLevel: context.handler(
          expandCollapseMultiLevel,
          'expandCollapseMultiLevel'
        ),
        goToProductDetail: context.handler(handleClickProductItem, 'handleClickProductItem'),
      };

      if (
        target.closest('.boost-sd__product-item, .boost-sd__product-item-list-view-layout') &&
        !target.closest('.boost-sd__popup-select-option') &&
        (!target.closest('.boost-sd__product-swatch') ||
          target.closest('.boost-sd__product-swatch-more')) &&
        !target.closest('.boost-sd__bulk-quantity') &&
        !metadataObject &&
        !metadataObject?.action
      ) {
        context.handler(handleClickProductItem, 'handleClickProductItem')(context, null, target);
      }

      // action.optionCollection -> collection multi level - prevent default
      // Not prevent when click product banner - !target?.closest('.boost-sd__product-banner-link')
      if (
        (!target.getAttribute('href') || metadataObject.action?.optionCollection) &&
        !target?.closest('.boost-sd__product-banner-link')
      ) {
        event.preventDefault();
      }

      if (metadataObject && metadataObject.action) {
        // event.preventDefault();
        Object.keys(metadataObject.action).forEach((key) => {
          handlers[key](context, metadataObject.action[key], target);
        });
      }

      // click data-action
      const actionId = target.dataset.action || target.getAttribute('data-action');
      if (actionId) {
        const [getActionMapping] = context.useContextState('actionMapping', {});
        const actionMapping = getActionMapping();
        const [key, id] = actionId.split('.');

        if (actionMapping[key] && actionMapping[key][id]) {
          const actionHandler = Object.keys(actionMapping[key][id])[0];
          const action = actionMapping[key][id][actionHandler];
          handlers[actionHandler](context, action, target);
        }
      }

      if (
        target.classList &&
        [...target.classList].some((className) => className.startsWith('boost-sd__sorting-'))
      ) {
        context.handler(handleSort, 'handleSort')(context, target);
      }
      if (
        target.classList.contains('boost-sd__pagination-number') ||
        target.classList.contains('boost-sd__pagination-button')
      ) {
        context.handler(pagination, 'pagination')(context, target);
      }

      if (target.classList.contains('boost-sd__show-limit-list-button')) {
        context.handler(handleToggleShowLimitList, 'handleToggleShowLimitList')(context, target);
      }

      if (target.classList.contains('boost-sd__show-limit-list-option')) {
        context.handler(handleLimitListOptionClick, 'handleLimitListOptionClick')(context, target);
      }

      if (
        target.classList.contains('boost-sd__view-as-icon') ||
        target.closest('.boost-sd__view-as-icon')
      ) {
        context.handler(handleViewAsClick, 'handleViewAsClick')(context, target);
      }

      const filterTreeVerticalStyleOffCanvas =
        filterLayout === 'vertical' && filterTreeVerticalStyle === 'style-off-canvas';
      const styleOffCanvasSelector =
        '#boost-sd__filter-tree-wrapper #boost-sd__filter-tree-vertical--style-off-canvas';
      const styleOffCanvas = context.$(styleOffCanvasSelector, filterTreeParent);

      // click button show result, close , overlay in style off-canvas -> close
      if (
        filterTreeVerticalStyleOffCanvas &&
        !_isMobile &&
        (target.closest('.boost-sd__button.boost-sd__button--result') ||
          target.closest('.boost-sd__filter-tree-vertical-close-filter--left') ||
          target.classList.contains('boost-sd__filter-tree-vertical-sticky-overlay'))
      ) {
        styleOffCanvas?.classList?.add('boost-sd__filter-tree-vertical--hidden');
        setFilterTreeDesktopOpening(false);
        toggleFilterTreeIcon(context);
        document.body.classList.remove('boost-sd__g-no-scroll');
      }

      // Click filter Tree toggle button in desktop
      if (target.closest(FILTER_TREE_TOGGLE_BUTTON_SELECTOR)) {
        // click mobile
        if (target.closest(FILTER_MOBILE_ICON_SELECTOR)) {
          return context.handler(showMobileFilterTree, 'showMobileFilterTree')(context);
        }

        // click desktop
        context.handler(toggleFilterTreeButtonDesktop, 'toggleFilterTreeButtonDesktop')(
          context,
          filterTreeParent
        );
      }

      if (
        target.closest('.boost-sd__filter-tree-vertical-close-filter') ||
        target.closest('.boost-sd__button--close') ||
        target.classList.contains('boost-sd__filter-tree-vertical-sticky-overlay') ||
        target.closest('.boost-sd__filter-tree-vertical-show-result')
      ) {
        _isMobile && context.handler(closeMobileFilterTree, 'closeMobileFilterTree')(context);
      }

      if (target.closest('.boost-sd__button--back')) {
        backFilterTreeMobile(context);
      }

      if (target.closest('.boost-sd__popup-select-option-close-btn')) {
        closeQuickAddToCart(target);
      }

      if (target.closest('.boost-sd__scroll-to-top')) {
        scrollToTop();
      }

      if (target?.closest('.boost-sd__product-item--embedded-bundle')) {
        handleClickEmbeddedBundle(context, target);
      }

      // style mobile has collapsed
      if (_isMobile && ['style1', 'style3', 'style3-fullwidth'].includes(filterTreeMobileStyle)) {
        const filterOptionLabel = target.closest('.boost-sd__filter-option-label');
        if (filterOptionLabel) {
          // toggle collapsed label
          filterOptionLabel.classList.toggle('boost-sd__filter-option-label--collapsed');

          const targetWrapper = filterOptionLabel
            ?.closest('.boost-sd__filter-option')
            ?.querySelector('.boost-sd__filter-option-itemwrapper');
          // toggle collapsed target
          targetWrapper?.classList?.toggle('boost-sd__filter-option-itemwrapper--collapsed');
        }
      }
    });

    // General context.document keydown listener
    context.document?.addEventListener('keydown', function (event) {
      const [, setFilterKeyCode] = context.useContextState(FILTER_TREE_STATE_KEY.KEY_CODE, '');
      const target = event.target;
      const currentButton = target.closest('button');
      const charCode = event?.which || event?.keyCode;
      if (currentButton && currentButton.id) {
        setFilterKeyCode(charCode);
      }

      if (event.key === 'Enter' || event.key === ' ') {
        // handle keydown on sortby
        if (
          target.classList &&
          [...target.classList].some((className) => className.startsWith('boost-sd__sorting-'))
        ) {
          context.handler(handleSort, 'handleSort')(context, target);
        }

        // handle keydown on viewas
        if (
          target.classList.contains('boost-sd__view-as-icon') ||
          target.closest('.boost-sd__view-as-icon')
        ) {
          context.handler(handleViewAsClick, 'handleViewAsClick')(context, target);
        }

        // handle keydown on limit-list
        if (target.classList.contains('boost-sd__show-limit-list-button')) {
          context.handler(handleToggleShowLimitList, 'handleToggleShowLimitList')(context, target);
        }
        if (target.classList.contains('boost-sd__show-limit-list-option')) {
          context.handler(handleLimitListOptionClick, 'handleLimitListOptionClick')(
            context,
            target
          );
        }
      }
    });

    handleHistoryParams(context);

    const page = Number(context?.defaultParams?.page) || 1;
    const productPerPage = getThemeSettings(context)?.productList?.productsPerPage || 16;
    setPaginationSession(page);

    if (productPerPage !== getLocalStorage(LIMIT_SETTING)) {
      setLocalStorage(LIMIT, productPerPage);
    }

    setLocalStorage(LIMIT_SETTING, productPerPage);

    initDynamicBundle(context);
  }

  if (context.blockType === 'instantSearch') {
    let init = false;
    if (context.disableLazyInitBlock) {
      context.handler(handleInstantSearchWidget, 'handleInstantSearchWidget')(context);
      init = true;
    } else {
      const initISWBlock = () => {
        if (!init) {
          context.handler(handleInstantSearchWidget, 'handleInstantSearchWidget')(context);
          init = true;
          document.removeEventListener('mousemove', initISWBlock);
          document.removeEventListener('touchmove', initISWBlock);
        }
      };

      document.addEventListener('mousemove', initISWBlock);
      document.addEventListener('touchmove', initISWBlock);
    }

    // register event re-init instant search for some case input Instant search render after some element is clicked
    window.addEventListener('bind-search-input', function (event) {
      console.log('Trigger bind search input', context);
      context.handler(handleInstantSearchWidget, 'handleInstantSearchWidget')(context);
    });

    context.document?.addEventListener('click', function (event) {
      const target = event.target;
      handleClickWatermark(target);

      handleRecentSearchForSuggestionTerm(event);

      if (target?.closest('.boost-sd__suggestion-queries-item--embedded-bundle')) {
        handleClickEmbeddedBundle(context, target);
      }

      // handle productItem, Collections, SearchTerm ... in ISW build locale link
      const classTagALink =
        'a.boost-sd__suggestion-queries-link, a.boost-sd__suggestion-queries-item-link';
      // NOTE: always place latest for function handleClickTagAItemBuildUrlWithLocale. Because it have setWindowLocation lose event = null
      handleClickTagAItemBuildUrlWithLocale(event, classTagALink);
    });

    initDynamicBundle(context);
  }

  if (context.blockType === 'recommendation') {
    context.handler(handleRecommendation, 'handleRecommendation')(context);

    // register event re-init RCM
    window.addEventListener('boost-sd-init-recommendation', function (event) {
      console.log('Trigger init RCM', context);
      context.handler(handleRecommendation, 'handleRecommendation')(context);
    });

    context.document?.addEventListener('click', function (event) {
      const target = event.target;
      const metadataValue = target.dataset.metadata || target.getAttribute('metadata');
      const metadataObject = safeParseJSON(metadataValue);

      const handlers = {
        quickView: context.handler(quickView, 'quickView'),
        addToCart: context.handler(addToCart, 'addToCart'),
        quickAddToCart: context.handler(quickAddToCart, 'quickAddToCart'),
        goToProductDetail: context.handler(handleClickProductItem, 'handleClickProductItem'),
      };

      if (
        target.closest('.boost-sd__product-item, .boost-sd__product-item-list-view-layout') &&
        !target.closest('.boost-sd__popup-select-option') &&
        (!target.closest('.boost-sd__product-swatch') ||
          target.closest('.boost-sd__product-swatch-more')) &&
        !metadataObject &&
        !metadataObject?.action
      ) {
        context.handler(handleClickProductItem, 'handleClickProductItem')(context, null, target);
      }

      // action.optionCollection -> collection multi level - prevent default
      if (!target.getAttribute('href') || metadataObject.action?.optionCollection) {
        event.preventDefault();
      }

      if (metadataObject && metadataObject.action) {
        // event.preventDefault();
        Object.keys(metadataObject.action).forEach((key) => {
          handlers[key](context, metadataObject.action[key], target);
        });
      }
      const actionId = target.dataset.action || target.getAttribute('data-action');
      if (actionId) {
        const [getActionMapping] = context.useContextState('actionMapping', {});
        const actionMapping = getActionMapping();
        const [key, id] = actionId.split('.');

        if (actionMapping[key] && actionMapping[key][id]) {
          const actionHandler = Object.keys(actionMapping[key][id])[0];
          const action = actionMapping[key][id][actionHandler];
          handlers[actionHandler](context, action, target);
        }
      }

      if (target.closest('.boost-sd__popup-select-option-close-btn')) {
        closeQuickAddToCart(target);
      }

      handleClickWatermark(target);
    });
  }

  if (context.blockType === 'cart') {
    handleCart(context);
  }

  if (context.blockType === 'predictiveBundle' && isProductPage()) {
    const productId = boostWidgetIntegration?.generalSettings?.product_id;
    renderPredictiveBlocksForProductPage(context, productId);
  }
}

const handleHistoryParams = (context) => {
  window.addEventListener('popstate', async () => {
    // back or forward
    parseHistoryParams(context);
    parseCollectionPath(context);
    await callFilterRequest(context);
    fillFilterTree(context);
    scrollToTop({ behavior: 'auto' });
    renderCollectionHeader(context);
  });

  // first load
  parseHistoryParams(context);
  fillFilterTree(context);
};

const handleLazyloadImage = (context) => {
  const { lazyLoadImages } = window.boostSDTaeUtils;
  if (lazyLoadImages) {
    context.render(() => {
      lazyLoadImages(context.document);
    });
  }
};

const handleClickWatermark = (target) => {
  if (target?.closest('.boost-sd__watermark-powered-by-boost')) {
    window.open(WATERMARK_POWERED_BY_BOOST_URL, '_blank').focus();
  }
};
