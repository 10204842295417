export const filterOptionMultiLevelTagTemplate = `
<li class='boost-sd__filter-option-item boost-sd__filter-option-list-option-item'>
  <button
    id='{{ actionId }}'
    data-metadata='{{ metaData | json }}'
    aria-label='Find product with {{ label | escape }} is {{ value.displayName | escape | strip_html }}'
    role='checkbox'
    aria-checked='false'
    class='{{ className }}'
  >
    <span
      data-metadata='{{ metaData | json}}'
      class='boost-sd__filter-option-item-label'
    >
      {% if option.displayAllValuesInUppercaseForm %}
        {{ value.displayName | upcase }}
      {% else %}
        {{ value.displayName }}
      {% endif %}
    </span>
  </button>

  {% assign existedTags = value.subTags | size %}
  {% if existedTags > 0 %}
    <button
      id='{{ value.actionIdArrow }}'
      data-metadata='{{ value.metaDataArrow | json }}'
      type='button'
      aria-label='Expand/Collapse {{ value.displayName }}'
      class='boost-sd__button boost-sd__button--transparent boost-sd__filter-option-list-option-item-collapse-btn'
    >
      <svg
        id='{{ value.targetIdArrow }}'
        data-metadata='{{ value.metaDataArrow | json }}'
        class='boost-sd__arrow-icon boost-sd__arrow-icon--right'
        xmlns='http://www.w3.org/2000/svg'
        width='7'
        height='12'
        role='img'
        aria-label='Expand/Collapse {{ value.displayName }}'
        viewBox='0 0 7 12'
      >
        <path
            data-metadata='{{ value.metaDataArrow | json }}'
            class="boost-sd__arrow-icon-path"
            d="M6.58942 0.610713C6.26398 0.285274 5.73635 0.285274 5.41091 0.610713L0.611415 5.41021C0.455135 5.56649 0.366829 5.77895 0.366829 5.99996C0.366829 6.22098 0.454629 6.43294 0.610909 6.58922L5.41091 11.3892C5.73635 11.7147 6.26398 11.7147 6.58942 11.3892C6.91486 11.0638 6.91486 10.5362 6.58942 10.2107L2.37867 5.99996L6.58942 1.78922C6.91486 1.46379 6.91486 0.936153 6.58942 0.610713Z"
        ></path>
      </svg>
    </button>
    {% comment %} style="display: none" {% endcomment %}
    <ul
      id='{{ value.targetIdList }}'
      class='boost-sd__filter-option-list-option-item-sublist boost-sd__g-hide'
    >
      {% for tag in value.subTags %}
        <li class='boost-sd__filter-option-item boost-sd__filter-option-list-option-item'>
          <button
            id='{{ tag.actionId }}'
            data-metadata='{{ tag.metaData | json }}'
            aria-label='Find product with {{ label | escape }} is {{ tag.displayName | escape | strip_html }}'
            role='checkbox'
            aria-checked='false'
            class='{{ tag.className }}'
          >
            <span
              data-metadata='{{ tag.metaData | json }}'
              class='boost-sd__filter-option-item-label'
            >
              {% if option.displayAllValuesInUppercaseForm %}
                {{ tag.displayName | upcase }}
              {% else %}
                {{ tag.displayName }}
              {% endif %}
            </span>
            {% if showProductCount %}
              <span
                data-metadata='{{ tag.metaData | json }}'
                aria-label='Number of products: ({{ tag.doc_count }})'
                class='boost-sd__filter-option-item-amount'
                >({{ tag.doc_count }})</span
              >
            {% endif %}
          </button>

          {% assign existedSubTags = tag.subTags | size %}
          {% if existedSubTags > 0 %}
            <button
              id='{{ tag.actionIdArrow }}'
              data-metadata='{{ tag.metaDataArrow | json }}'
              type='button'
              aria-label='Expand/Collapse {{ tag.displayName }}'
              class='boost-sd__button boost-sd__button--transparent boost-sd__filter-option-list-option-item-collapse-btn'
            >
              <svg
                id='{{ tag.targetIdArrow }}'
                data-metadata='{{ tag.metaDataArrow | json }}'
                class='boost-sd__arrow-icon boost-sd__arrow-icon--right'
                xmlns='http://www.w3.org/2000/svg'
                width='7'
                height='12'
                role='img'
                aria-label='Expand/Collapse {{ tag.displayName }}'
                viewBox='0 0 7 12'
              >
                <path
                    data-metadata='{{ tag.metaDataArrow | json }}'
                    class="boost-sd__arrow-icon-path"
                    d="M6.58942 0.610713C6.26398 0.285274 5.73635 0.285274 5.41091 0.610713L0.611415 5.41021C0.455135 5.56649 0.366829 5.77895 0.366829 5.99996C0.366829 6.22098 0.454629 6.43294 0.610909 6.58922L5.41091 11.3892C5.73635 11.7147 6.26398 11.7147 6.58942 11.3892C6.91486 11.0638 6.91486 10.5362 6.58942 10.2107L2.37867 5.99996L6.58942 1.78922C6.91486 1.46379 6.91486 0.936153 6.58942 0.610713Z"
                ></path>
              </svg>
            </button>
            {% comment %} style="display: none" {% endcomment %}
            <ul
              id='{{ tag.targetIdList }}'
              class='boost-sd__filter-option-list-option-item-sublist boost-sd__g-hide'
            >
              {% for subTag in tag.subTags %}
                <li class='boost-sd__filter-option-item boost-sd__filter-option-list-option-item'>
                  <button
                    id='{{ subTag.actionId }}'
                    data-metadata='{{ subTag.metaData | json }}'
                    aria-label='Find product with {{ label | escape }} is {{ subTag.displayName | escape | strip_html }}'
                    role='checkbox'
                    aria-checked='false'
                    class='{{ subTag.className }}'
                  >
                    <span
                      data-metadata='{{ subTag.metaData | json }}'
                      class='boost-sd__filter-option-item-label'
                    >
                      {% if option.displayAllValuesInUppercaseForm %}
                        {{ subTag.displayName | upcase }}
                      {% else %}
                        {{ subTag.displayName }}
                      {% endif %}
                    </span>
                    {% if showProductCount %}
                      <span
                        data-metadata='{{ subTag.metaData | json }}'
                        aria-label='Number of products: ({{ subTag.doc_count }})'
                        class='boost-sd__filter-option-item-amount'
                        >({{ subTag.doc_count }})</span
                      >
                    {% endif %}
                  </button>
                </li>
              {% endfor %}
            </ul>
          {% endif %}
        </li>
      {% endfor %}
    </ul>
  {% endif %}
</li>
`;
