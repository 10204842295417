import { buildUrlWithLocalization } from '../utils';

const PATH = 'cart';

export const CartAPI = {
  getAll: async () => {
    const response = await fetch(buildUrlWithLocalization('cart.js'), {
      method: 'GET',
    });

    return await response.json();
  },
  add: async (id, quantity = 1) => {
    const payload = {
      items: [
        {
          id,
          quantity,
        },
      ],
    };

    return await fetch(buildUrlWithLocalization(`${PATH}/add.js`), {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  },
  addMultiProductToCart: async (data) => {
    const payload = {
      items: data,
    };

    return await fetch(buildUrlWithLocalization(`${PATH}/add.js`), {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
    });
  },
  change: async (id, quantity = 0) => {
    const payload = {
      id,
      quantity,
    };

    const response = await fetch(buildUrlWithLocalization(`${PATH}/change.js`), {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    return await response.json();
  },
  clearCart: async () => {
    const response = await fetch(buildUrlWithLocalization(`${PATH}/clear.js`), { method: 'POST' });
    return await response.json();
  },
};
