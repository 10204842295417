import { prettyPrintJson } from 'pretty-print-json';
import { formatCurrency, sortByKey, stripHtml, translateByKey } from '.';
import { appIntegration } from '../integration';
import { FILTER_OPTION_DISPLAY_TYPE, FILTER_OPTION_TYPE } from '../constants/filter-tree';

export function watchFirstLoad(context) {
  if (context.blockType === 'filter') {
    if (context.filterTree) {
      context.state.filterTree = context.filterTree;
      appIntegration();
      if (typeof context.app?.customization?.filter?.afterRender === 'function') {
        context.app.customization.filter.afterRender();
      }
    } else {
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (context.filterTree) {
            context.state.filterTree = context.filterTree;
            observer.disconnect();
            appIntegration();
            if (typeof context.app?.customization?.filter?.afterRender === 'function') {
              context.app.customization.filter.afterRender();
            }
          }
        });
      });
      var config = { attributes: true, childList: true, subtree: true };
      observer.observe(context.document, config);
    }
  }
}

export function debounce(func, delay) {
  let timeoutId;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export function debug(context) {
  const debugDiv = document.getElementById('widgetIntegrationDebugDiv');
  if (debugDiv) {
    const debugContent = document.getElementById('widgetDebugContent');
    const widgetDebugCol1 = document.getElementById('widgetDebugCol1');
    const widgetDebugCol2 = document.getElementById('widgetDebugCol2');
    const widgetDebugCol3 = document.getElementById('widgetDebugCol3');
    const filterTreeOptions = context.state?.filterTree?.options?.map((op) => {
      return {
        filterType: op.filterType,
        filterOptionId: op.filterOptionId,
        position: op.position,
        displayType: op.displayType,
        count: op?.values?.length || 0,
      };
    });
    widgetDebugCol1.innerHTML = `
    <h3>filterTree: </h3>
    <pre id=account class=json-container>${prettyPrintJson.toHtml(filterTreeOptions)}</pre>
    `;

    //   '<h3>filterTree: </h3>' +
    //   context.state?.filterTree?.options
    //     ?.map((option) => {
    //       return `<p> ${option.filterOptionId} - ${
    //         option.values && option.values.length ? `total ${option.values.length}` : ''
    //       } ${
    //         option.values && !option.values.length && typeof option.values === 'object'
    //           ? JSON.stringify(option.values, null, 2)
    //           : ''
    //       }
    //       ${option.sliderStep ? `sliderStep: ${option.sliderStep}` : ''}
    //       ${option.sliderRange ? `sliderRange: ${option.sliderRange}` : ''}
    //       </p>`;
    //     })
    //     .join('');
    // const filterTreeViewPort =
    //   context.filterTreeViewPort &&
    //   typeof context.filterTreeViewPort === 'object' &&
    //   Object.keys(context.filterTreeViewPort)
    //     .map((option) => {
    //       if (option !== 'key')
    //         return `<p>${option}: displayType
    //         ${context.filterTreeViewPort[option].displayType} loaded: ${context.filterTreeViewPort[option].loaded} - total: ${context.filterTreeViewPort[option].total}
    //         </p>`;
    //     })
    //     .join('');
    widgetDebugCol2.innerHTML = `
    <h3>filterTreeViewPort: </h3>
    <pre id=account class=json-container>${prettyPrintJson.toHtml(context.filterTreeViewPort)}</pre>
    `;

    // '<h3>filterTreeViewPort: </h3> ' + filterTreeViewPort;

    widgetDebugCol3.innerHTML = `
    <h3>Filter: </h3>
    <pre id=account class=json-container>${prettyPrintJson.toHtml(context.state.filter)}</pre>
    <pre id=account class=json-container>${prettyPrintJson.toHtml(context.updatedParams)}</pre>

    `;

    // '<h3>Filter: </h3> ' + filter;
  }
}

export const formatPercentSaleLabel = (context, from = 0, to) => {
  let label = '';
  if (!from) {
    label = context.translate('under', 'Under') + ` ${to}%`;
  } else if (!to) {
    label = context.translate('above', 'Above') + ` ${from}%`;
  } else {
    label = `${from}% - ${to}%`;
  }

  return label;
};

export const roundAmount = (amount, precision = 0, isMinPrice = false) => {
  if (isNaN(amount)) return amount;
  amount = Number(amount);

  if (amount < 0.04) return amount;

  if (isMinPrice) return Math.floor(amount * 100) / 100;

  return +amount.toFixed(precision);
};

export const moneyFormatPriceList = (context, number, precisionFormatLabelPriceList = 2) => {
  const amount = roundAmount(number, precisionFormatLabelPriceList);
  return formatCurrency({ context, value: amount });
};

export const formatPriceLabel = (context, to, from = 0, precisionFormatLabelPriceList = 2) => {
  let label = '';
  if (!from || from === '0') {
    label =
      context.translate('under', 'Under') +
      ` ${moneyFormatPriceList(context, to, precisionFormatLabelPriceList)}`;
  } else if (!to) {
    label =
      context.translate('above', 'Above') +
      ` ${moneyFormatPriceList(context, from, precisionFormatLabelPriceList)}`;
  } else {
    label = `${moneyFormatPriceList(
      context,
      from,
      precisionFormatLabelPriceList
    )} - ${moneyFormatPriceList(context, to, precisionFormatLabelPriceList)}`;
  }

  return label;
};

export const formatFromToKey = (from = 0, to = 0) => {
  let key = '';
  if (!from) {
    key = `0:${to}`;
  } else if (!to) {
    key = `${from}:`;
  } else {
    key = `${from}:${to}`;
  }

  return key;
};

export const formatRatingValue = (context, from, showExactRating) => {
  let label = from.toFixed();
  if (from === 1) {
    label += ` ${translateByKey(context, 'ratingStar', 'star')}`;
  } else {
    label += ` ${translateByKey(context, 'ratingStars', 'stars')}`;
  }

  if (!showExactRating) label += ` ${translateByKey(context, 'ratingUp', '& Up')}`;

  return label;
};

const isDefaultSort = (option) => {
  const alwaySortFilterType = [FILTER_OPTION_TYPE.REVIEW_RATINGS, FILTER_OPTION_TYPE.PERCENT_SALE];

  if (alwaySortFilterType.includes(option.filterType)) return true;

  const excludeFilterType = [FILTER_OPTION_TYPE.STOCK];
  const excludeDisplayType = [
    FILTER_OPTION_DISPLAY_TYPE.RANGE,
    FILTER_OPTION_DISPLAY_TYPE.MULTI_LEVEL_COLLECTIONS,
  ];

  const isSortAll = option.valueType === 'all';
  const isSortManual = option.valueType !== 'all' && option.sortManualValues;
  const isTextRangeSlider =
    option.displayType === FILTER_OPTION_DISPLAY_TYPE.RANGE && option.isNumberRangeSlider === false;

  return (
    !excludeFilterType.includes(option.filterType) &&
    (!excludeDisplayType.includes(option.displayType) || isTextRangeSlider) &&
    (isSortAll || isSortManual)
  );
};

export const updateValuesOptions = (options, settings = {}, context) => {
  if (!Array.isArray(options)) return options;
  const { hideSingleOption = false, showOutOfStockOption = false } = settings;

  return options.map((option) => {
    // Option Values is array
    if (Array.isArray(option.values)) {
      // filter doc_count valid when showOutOfStockOption = false and option.keepValuesStatic = false
      if (!showOutOfStockOption && !option.keepValuesStatic) {
        option.values = option.values.filter((value) => value.doc_count && value.doc_count > 0);
      }

      //Create labels in values if not exists
      const labelAvailable = option.values.some((value) => value.hasOwnProperty('label'));
      if (!labelAvailable) {
        option.values.forEach((value) => {
          if (value.key) value.label = value.key;
        });
      }

      //process options with prefix
      if (option.prefix) {
        const prefix = option.prefix.replace(/\\/g, '');
        option.values.forEach((value) => {
          if (value.label) value.label = value.label.replace(prefix, '').trim();
        });
      }

      /** Hide filter option when filter settings hideSingleOption = true and length values = 1 but
       * not apply for multi level collection or multi level tag.
       */
      const isMultiLevel =
        option.displayType === 'multi_level_collections' || option.filterType === 'multi_level_tag';
      if (hideSingleOption && option.values.length === 1 && !isMultiLevel) {
        option.values = [];
      }
    }

    if (option?.values?.length == 0) {
      const filterOption = document.getElementById(
        `${option.filterOptionId.replace(/::/g, '__')}-toggle`
      );

      filterOption && filterOption?.closest('.boost-sd__filter-option')?.remove(); // remove filter option from server render first.
    }

    // if API return not value sortType then check and assign default value sortType if this Option valid
    if (!option.sortType) {
      option.sortType = isDefaultSort(option) ? 'key-asc' : '';
    }

    const isManualValues = option.manualValues?.length > 0;
    /* filter option settings - advanced Sort type
     * sort <=> isManualValues & sortManualValues or allValues(!isManualValues)
     */
    if (
      ((option.sortManualValues && isManualValues) || !isManualValues) &&
      Array.isArray(option.values) &&
      option.sortType
    ) {
      const [key, order] = option.sortType?.split('-');
      if (key && order) {
        option.values = sortByKey(option.values, key, order);
      }
    }

    if (option.filterType === 'review_ratings' && context) {
      // only apply for rating
      option.values = option.values?.map((item) => {
        item.key = Math.round(item.from);
        item.label = formatRatingValue(context, item.from, option.showExactRating);
        return item;
      });
    }

    // some filterType not have selectType but default multiple selectType
    if (
      option.displayType === 'list' &&
      ['stock', 'percent_sale', 'price', 'variants_price'].includes(option.filterType)
    ) {
      option.selectType = 'multiple';
    }

    return option;
  });
};

/**
 * Checks if the given term is a bad search term containing potential XSS vulnerabilities.
 * @param {string} term - The term to check.
 * @returns {boolean} Returns true if the term contains potential XSS vulnerabilities, otherwise false.
 */
export const isBadSearchTerm = (term) => {
  if (typeof term == 'string') {
    term = term.toLowerCase();
    // List of known DOM events and other potential XSS indicators
    var domEvents = [
      'script',
      'alert',
      'onabort',
      'popstate',
      'afterprint',
      'beforeprint',
      'beforeunload',
      'blur',
      'canplay',
      'canplaythrough',
      'change',
      'click',
      'contextmenu',
      'copy',
      'cut',
      'dblclick',
      'drag',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
      'dragstart',
      'drop',
      'durationchange',
      'ended',
      'error',
      'focus',
      'focusin',
      'focusout',
      'fullscreenchange',
      'fullscreenerror',
      'hashchange',
      'input',
      'invalid',
      'keydown',
      'keypress',
      'keyup',
      'load',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseover',
      'mouseout',
      'mouseout',
      'mouseup',
      'offline',
      'online',
      'pagehide',
      'pageshow',
      'paste',
      'pause',
      'play',
      'playing',
      'progress',
      'ratechange',
      'resize',
      'reset',
      'scroll',
      'search',
      'seeked',
      'seeking',
      'select',
      'show',
      'stalled',
      'submit',
      'suspend',
      'timeupdate',
      'toggle',
      'touchcancel',
      'touchend',
      'touchmove',
      'touchstart',
      'unload',
      'volumechange',
      'waiting',
      'wheel',
      'load',
    ];
    const potentialEventRegex = new RegExp(domEvents.join('=|on'));
    const countOpenTag = (term.match(/</g) || []).length;
    const countCloseTag = (term.match(/>/g) || []).length;
    const isAlert = (term.match(/alert\(/g) || []).length;
    const isConsoleLog = (term.match(/console\.log\(/g) || []).length;
    const isExecCommand = (term.match(/execCommand/g) || []).length;
    const isCookie = (term.match(/document\.cookie/g) || []).length;
    const isJavascript = (term.match(/j.*a.*v.*a.*s.*c.*r.*i.*p.*t/g) || []).length;
    const isPotentialEvent = potentialEventRegex.test(term);
    const isMetaTag = (term.match(/<meta/g) || []).length;
    const xssRegex =
      />|\x3Cscript|\s*<img|<iframe[\s\S]*?>|<\/iframe[\s\S]*?>|<img[\s\S]*?>|<\/img[\s\S]*?>|<object[\s\S]*?>|<\/object[\s\S]*?>|<embed[\s\S]*?>|<\/embed[\s\S]*?>|<applet[\s\S]*?>|<\/applet[\s\S]*?>|<meta[\s\S]*?>|<\/meta[\s\S]*?>|<link[\s\S]*?>|<\/link[\s\S]*?>|on\w+="[^"]*"|javascript:|&#[xX][0-9a-fA-F]+;/g;
    const isXssTerm = (term.match(xssRegex) || []).length;

    if (
      (countOpenTag > 0 && countCloseTag > 0) ||
      countOpenTag > 1 ||
      countCloseTag > 1 ||
      isAlert ||
      isConsoleLog ||
      isExecCommand ||
      isCookie ||
      isJavascript ||
      isPotentialEvent ||
      isMetaTag ||
      isXssTerm > 0
    ) {
      return true;
    }
  }
  return false;
};

/**
 * Filter XSS from options in the filter tree.
 * @param {Object} option - The filter option.
 * @param {number} size - size of filter option values
 * @returns {Array} filter option values with XSS-filtered.
 */
//  TODO: need review and improve XXS rendering core function
export function xssFilterOption(option, size) {
  const ops = { ...option };
  const values = [...ops.values];
  const newValues = [];
  for (const value of values) {
    if (
      !isBadSearchTerm(value?.key) &&
      !isBadSearchTerm(value?.label) &&
      !isBadSearchTerm(value?.displayName)
    )
      newValues.push(value);
    if (newValues.length >= size) break;
  }
  return newValues;
}
