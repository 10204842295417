const DEFAULT_LANGUAGE = 'en';

/**
 * Get locale selected from weglot
 */
const getLocale = () => {
  if (window.Weglot?.initialized && typeof window.Weglot?.getCurrentLang === 'function') {
    return window.Weglot.getCurrentLang() || DEFAULT_LANGUAGE;
  }

  return DEFAULT_LANGUAGE;
};

/**
 * Translate search quote to english
 */
export const getSearchTerm = (searchTerm) =>
  new Promise((resolve) => {
    if (getLocale() === DEFAULT_LANGUAGE) resolve(searchTerm);

    window.Weglot?.search(searchTerm, function (englishTerm) {
      resolve(englishTerm);
    });
  });
