import { debounce, simpleMd5, debug, xssFilterOption } from '../utils';
import { templateRender } from '../template/templateRender';
import { getTemplate } from '../template';
import { FILTER_OPTION_SELECTOR } from '../constants/filter-tree';
import { getSelectedKeysFilter } from '.';

export function renderOptionRating(context, dom, option, limit) {
  if (!dom) return;
  const selectedKeys = getSelectedKeysFilter(context);

  const classNameUnselected = `boost-sd__filter-option-item-button boost-sd__filter-option-item-button--as-button ${
    option.selectType == 'multiple' ? 'boost-sd__filter-option-item-button--with-checkbox' : ''
  }`;
  const classNameSelected = `${classNameUnselected} boost-sd__filter-option-item-button--selected`;

  const [getActionMapping, setActionMapping] = context.useContextState('actionMapping', {});
  const actionMapping = getActionMapping() || {};

  let html = '';
  const size = limit && option.values.length > limit ? limit : option.values.length;
  const values = xssFilterOption(option, size);
  for (const value of values) {
    const actionId = simpleMd5(`${option.filterOptionId}-${value.key}`);
    let className = classNameUnselected;
    if (selectedKeys[`${option.filterOptionId}-${value.key}`]) {
      className = classNameSelected;
    }

    const dataAction = `${option.filterOptionId}.${actionId}`;
    const action = {
      optionList: {
        key: option.filterOptionId,
        value: value.key,
        label: option.label,
        actionId,
        filterType: option.filterType,
        selectType: option.selectType,
        valueDisplay: value.label,
      },
    };

    // assign mapping event
    actionMapping[option.filterOptionId] = actionMapping[option.filterOptionId] || {};
    actionMapping[option.filterOptionId][actionId] = action;

    html += context.templateRender(getTemplate(context).filterOptionRatingTemplate, {
      starColor: option.starColor,
      showExactRating: option.showExactRating,
      actionId,
      dataAction,
      value,
      className,
    });
  }
  setActionMapping(actionMapping);
  dom.innerHTML = html;

  // create custom events - after render
  const renderFiltered = new CustomEvent('afterRenderHtmlForFilter');
  window.dispatchEvent(renderFiltered);
}

export function lazyLoadOptionRating(context, dom, id, option) {
  const domMouseMove = dom?.closest(FILTER_OPTION_SELECTOR);

  const mousemoveRender = debounce(() => {
    debug(context);
    renderFullOptionRating(context, dom, id, option);

    domMouseMove.removeEventListener('mousemove', mousemoveRender);
  }, 200);

  domMouseMove && domMouseMove.addEventListener('mousemove', mousemoveRender);
}

/**
 * render full option Rating
 * @param context
 * @param dom
 * @param id
 * @param option
 */
export const renderFullOptionRating = (context, dom, id, option) => {
  if (
    context.filterTreeViewPort &&
    context.filterTreeViewPort[id] &&
    context.filterTreeViewPort[id].loaded < context.filterTreeViewPort[id].total &&
    context.filterTreeViewPort[id].displayType === 'list'
  ) {
    renderOptionRating(context, dom, option, context.filterTreeViewPort[id].total);
    context.filterTreeViewPort[id] = {
      loaded: context.filterTreeViewPort[id].total,
      total: option.values.length,
      displayType: 'list',
    };
  }
};
