export const filterOptionSwatchTemplate = `
<li
  data-action='{{ dataAction }}'
  class='boost-sd__filter-option-item boost-sd__filter-option-swatch-item'
>
  <button
    id='{{ actionId }}'
    data-action='{{ dataAction }}'
    title='Find product with {{ label | escape }} is {{ value.label | escape | strip_html }}'
    role='checkbox'
    aria-checked='false'
    class='{{classButton}}'
  >
    <span
      data-action='{{ dataAction }}'
      class='{{ className }}'
      style='background-color: {{ swatchValue.backgroundColor | strip_html }}; background-image: {{ swatchValue.backgroundImage | strip_html }}'
    ></span>
    
    {% assign swatchListStyle = option.swatchStyle == 'circle-list' or option.swatchStyle == 'square-list' %}
    {% if swatchListStyle %}
      <span data-action='{{ dataAction }}' class='boost-sd__filter-option-item-label'>
        {% if option.displayAllValuesInUppercaseForm %}
          {{ value.label | escape | upcase }}
        {% else %}
          {{ value.label | escape }}
        {% endif %}
      </span>
      {% if showDocCount %}
        <span
          data-action='{{ dataAction }}'
          aria-label='Number of products: ({{ value.doc_count }})'
          class='boost-sd__filter-option-item-amount'
          >({{ value.doc_count }})</span
        >
      {% endif %}
    {% endif %}
  </button>
</li>`;
