import { addB2bAPIParams } from '../b2b/index.js';
import { addParamsLocale, buildQueryParams } from '../utils/index.js';

export const getProductDetail = async (context, params) => {
  params = addParamsLocale(params);
  if (context.app.b2b?.enabled) {
    addB2bAPIParams(params, context);
  }

  const response = await fetch(`${context.app.productUrl}?${buildQueryParams(params)}`, {
    method: 'GET',
  });
  //Generate newId for customize
  const newId = window.boostWidgetIntegration.generateUuid();
  context.state.latestQuickViewRequest = newId;
  return response.text();
};
